import React from "react";
import cn from "classnames";
import { Arrow } from "@icons/arrow";

enum Tab {
	Tab1 = 1,
	Tab2 = 2,
	Tab3 = 3,
	Tab4 = 4,
}

enum Table {
	Table1 = 1,
	Table2 = 2,
}

const tabs = [
	{ id: Tab.Tab1, header: "Даты проведения" },
	{ id: Tab.Tab2, header: "Предварительная публикация работ" },
	{ id: Tab.Tab3, header: "Показ работ и аппеляция" },
	{
		id: Tab.Tab4,
		header: (
			<>
				{"Публикация окончательных"}&nbsp;{"результатов"}
			</>
		),
	},
];

function Table1() {
	return (
		<div>
			<div className="table-header">График регионального этапа ВсОШ</div>
			<table className="points-table1">
				<tbody>
					<tr>
						<th>Предмет</th>
						<th colSpan={2}>Даты проведения</th>
						<th>Примечание</th>
					</tr>
					<tr>
						<td>Французский язык</td>
						<td>
							11 января <br />
							12 января
						</td>
						<td>
							Письменный тур
							<br />
							Устный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Русский язык</td>
						<td colSpan={2}>14 января</td>
						<td></td>
					</tr>
					<tr>
						<td>Химия</td>
						<td>
							15 января
							<br />
							16 января
						</td>
						<td>
							Теоретический тур
							<br />
							Экспериментальный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>История</td>
						<td>17, 18 января</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Экономика</td>
						<td>19 января</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Физика</td>
						<td>
							21 января
							<br />
							23 января
						</td>
						<td>
							Теоретический тур
							<br />
							Экспериментальный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Биология</td>
						<td>
							22 января
							<br />
							24 января
						</td>
						<td>
							Теоретический тур
							<br />
							Практический тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Астрономия</td>
						<td>25 января</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Информатика и ИКТ</td>
						<td>
							26 января
							<br />
							28 января
						</td>
						<td>
							I тур
							<br />
							II тур
						</td>
						<td>
							Регистрация участников: до 20 января.
							<br />
							Тренировочные туры: 8-12 января
							<br />
							Ознакомление с системой: до 25 января
						</td>
					</tr>
					<tr>
						<td>Литература</td>
						<td>29 января</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Технология: техника и техническое творчество</td>
						<td>
							30 января
							<br />
							<br />
							31 января
						</td>
						<td>
							9-11 класс: теоретический тур
							<br />
							10-11 класс: практический тур
							<br />9 класс: практический тур
						</td>
						<td>Регистрация участников: до 23 января</td>
					</tr>

					<tr>
						<td>Технология: робототехника</td>
						<td>
							30 января
							<br />
							<br />
							31 января
						</td>
						<td>
							9-11 класс: теоретический тур
							<br />
							9 класс: практический тур
							<br />
							10-11 класс: практический тур
						</td>
						<td>Регистрация участников: до 23 января</td>
					</tr>
					<tr>
						<td>Технология: культура дома</td>
						<td>
							30 января
							<br />
							<br />
							<br />
							31 января
							<br />
							<br />
						</td>
						<td>
							9-11 класс: теоретический тур
							<br />
							9 класс: защита проектов
							<br />
							10-11 класс: практический тур
							<br />
							9 класс: практический тур
							<br />
							10-11: защита проектов
						</td>
						<td>Регистрация участников: до 23 января</td>
					</tr>
					<tr>
						<td>Математика</td>
						<td>1, 2 февраля</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Обществознание</td>
						<td>4, 5 февраля</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Экология</td>
						<td>6 февраля</td>
						<td>Теоретический тур</td>
						<td></td>
					</tr>
					<tr>
						<td>Защита проектов</td>
						<td>7 февраля</td>
						<td></td>
						<td>Прием проектов: до 20 января</td>
					</tr>
					<tr>
						<td>ОБЖ</td>
						<td>
							8 февраля
							<br />9 февраля
						</td>
						<td>
							Теоретический тур
							<br />
							Практический тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Английский язык</td>
						<td>
							11 февраля
							<br />
							12 февраля
						</td>
						<td>
							Письменный тур
							<br />
							Устный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Искусство (МХК)</td>
						<td>14 февраля</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Физическая культура</td>
						<td>
							15 февраля
							<br />
							<br />
							16 февраля
							<br />
							<br />
						</td>
						<td>
							Девушки: гимнастика
							<br />
							Юноши: легкая атлетика
							<br />
							Девушки: легкая атлетика
							<br />
							Юноши: гимнастика
						</td>
						<td>
							Для участия необходима карточка участника <br />
							регионального этапа олимпиады по физической культуре
						</td>
					</tr>
					<tr>
						<td>География</td>
						<td>18 февраля</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Немецкий язык</td>
						<td>
							19 февраля
							<br />
							20 февраля
						</td>
						<td>
							Письменный тур
							<br />
							Устный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Испанский язык</td>
						<td>
							21 февраля
							<br />
							22 февраля
						</td>
						<td>
							Письменный тур
							<br />
							Устный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Итальянский язык</td>
						<td>
							21 февраля
							<br />
							22 февраля
						</td>
						<td>
							Письменный тур
							<br />
							Устный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Китайский язык</td>
						<td>
							21 февраля
							<br />
							22 февраля
						</td>
						<td>
							Письменный тур
							<br />
							Устный тур
						</td>
						<td></td>
					</tr>
					<tr>
						<td>Экономика</td>
						<td>24 февраля</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Право</td>
						<td>25 февраля</td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function Table2() {
	return (
		<div>
			<div className="table-header">График регионального этапа ВсОШ</div>
			<table className="points-table1">
				<tbody>
					<tr>
						<th>Предмет</th>
						<th>Дата открытия</th>
						<th>Дата закрытия</th>
					</tr>
					<tr>
						<td>Обществознание</td>
						<td>14 сентября</td>
						<td>21 сентября</td>
					</tr>
					<tr>
						<td>Английский язык</td>
						<td>17 сентября</td>
						<td>24 сентября</td>
					</tr>
					<tr>
						<td>Литература</td>
						<td>17 сентября</td>
						<td>24 сентября</td>
					</tr>
					<tr>
						<td>Физика</td>
						<td>21 сентября</td>
						<td>28 сентября</td>
					</tr>
					<tr>
						<td>Французский язык</td>
						<td>21 сентября</td>
						<td>28 сентября</td>
					</tr>
					<tr>
						<td>ОБЖ</td>
						<td>21 сентября</td>
						<td>28 сентября</td>
					</tr>
					<tr>
						<td>Биология</td>
						<td>21 сентября</td>
						<td>28 сентября</td>
					</tr>
					<tr>
						<td>Право</td>
						<td>24 сентября</td>
						<td>1 октября</td>
					</tr>
					<tr>
						<td>Экология</td>
						<td>24 сентября</td>
						<td>1 октября</td>
					</tr>
					<tr>
						<td>Итальянский язык</td>
						<td>24 сентября</td>
						<td>1 октября</td>
					</tr>
					<tr>
						<td>Русский язык</td>
						<td>28 сентября</td>
						<td>5 октября</td>
					</tr>
					<tr>
						<td>Астрономия</td>
						<td>28 сентября</td>
						<td>5 октября</td>
					</tr>
					<tr>
						<td>География</td>
						<td>28 сентября</td>
						<td>5 октября</td>
					</tr>
					<tr>
						<td>Искусств (МХК)</td>
						<td>1 октября</td>
						<td>8 октября</td>
					</tr>
					<tr>
						<td>История</td>
						<td>1 октября</td>
						<td>8 октября</td>
					</tr>
					<tr>
						<td>Математика</td>
						<td>19 октября</td>
						<td>26 октября</td>
					</tr>
					<tr>
						<td>Испанский язык</td>
						<td>19 октября</td>
						<td>26 октября</td>
					</tr>
					<tr>
						<td>Физическая культура</td>
						<td>19 октября</td>
						<td>26 октября</td>
					</tr>
					<tr>
						<td>Немецкий язык</td>
						<td>19 октября</td>
						<td>26 октября</td>
					</tr>
					<tr>
						<td>Технология</td>
						<td>19 октября</td>
						<td>26 октября</td>
					</tr>
					<tr>
						<td>Экономика</td>
						<td>22 октября</td>
						<td>29 октября</td>
					</tr>
					<tr>
						<td>Химия</td>
						<td>22 октября</td>
						<td>29 октября</td>
					</tr>
					<tr>
						<td>Китайский язык</td>
						<td>24 октября</td>
						<td>1 ноября</td>
					</tr>
					<tr>
						<td>Информатика</td>
						<td>24 октября</td>
						<td>1 ноября</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

function Tables() {
	const [selectedTable, setTab] = React.useState(Table.Table1);

	function handleNavLeft() {
		let next = selectedTable - 1;
		if (next < 1) {
			next = 2;
		}
		setTab(next);
	}

	function handleNavRight() {
		let next = selectedTable + 1;
		if (next > 2) {
			next = 1;
		}
		setTab(next);
	}

	return (
		<div className="table-slider">
			<div className="table-slider__navigation table-slider__navigation--left" onClick={handleNavLeft}>
				<Arrow className="table-slider__nav-icon table-slider__nav-icon--left" />
			</div>
			{selectedTable === Table.Table1 && <Table1 />}
			{selectedTable === Table.Table2 && <Table2 />}
			<div className="table-slider__navigation" onClick={handleNavRight}>
				<Arrow className="table-slider__nav-icon table-slider__nav-icon" />
			</div>
		</div>
	);
}

export function Schedule() {
	const [selectedTab, setTab] = React.useState(Tab.Tab1);

	function handleSelectTab(tabId: Tab) {
		return function() {
			setTab(tabId);
		};
	}

	return (
		<>
			<div className="button-tabs">
				{tabs.map(x => (
					<div
						className={cn("button-tab", { "button-tab--active": x.id === selectedTab })}
						key={x.id}
						onClick={handleSelectTab(x.id)}
					>
						{x.header}
					</div>
				))}
			</div>

			{selectedTab === Tab.Tab1 && <Tables />}
		</>
	);
}

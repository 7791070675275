import React from "react";

const data_table2 = {
	headers: ["Предмет", "7 класс", "8 класс", "9 класс", "10 класс", "11 класс"],
	rows: [
		["Английский язык", [96, 55], [96, 60], [60, 40], [60, 40], [60, 40]],
		["Астрономия", [40, 18], [48, 20], [48, 20], [48, 24], [48, 26]],
		["Биология", [50, 35], [50, 35], [70, 49], [100, 72], [100, 72]],
		["География", [200, 80], [200, 80], [200, 80], [200, 80], [200, 80]],
		["Искусство (МХК)", [60, 25], [60, 25], [60, 30], [60, 30], [60, 30]],
		["История", [75, 30], [90, 35], [115, 40], [120, 40], [130, 50]],
		["Итальянский язык", [55, 27], [55, 27], [55, 27], [55, 27], [55, 27]],
		["Литература", [75, 30], [75, 30], [75, 35], [75, 35], [75, 35]],
		["Обществознание", [75, 20], [80, 25], [110, 35], [110, 40], [110, 40]],
		["ОБЖ", [200, 100], [200, 100], [200, 100], [200, 100], [200, 100]],
		["Право", [78, 30], [78, 34], [85, 38], [105, 45], [105, 52]],
		["Русский язык", [54, 23], [50, 22], [56, 25], [64, 27], [62, 25]],
		["Физика", [30, 10], [40, 15], [50, 20], [50, 20], [50, 20]],
		["Французский язык", [108, 58], [108, 58], [110, 52], [110, 52], [110, 52]],
		["Экология", [34, 20], [34, 20], [41, 24], [48, 27], [48, 27]],
	],
};

export function PassScore() {
	return (
		<table className="points-table1">
			<tbody>
				<tr>
					{data_table2.headers.map((x, index) =>
						index === 0 ? (
							<th rowSpan={2} key={`a${index}`}>
								{x}
							</th>
						) : (
							<th key={`a${index}`} colSpan={2}>
								{x}
							</th>
						),
					)}
				</tr>
				<tr>
					{data_table2.headers.map((_x, index) =>
						index === 0 ? null : (
							<React.Fragment key={`b${index}`}>
								<th>Макс.</th>
								<th>Пригл</th>
							</React.Fragment>
						),
					)}
				</tr>
				{data_table2.rows.map((row, index) => (
					<tr key={`c${index}`}>
						{row.map((x, index) =>
							index === 0 ? (
								<td key={`c${index}`}>{x}</td>
							) : (
								(x as number[]).map((y, index) => <td key={`d${index}`}>{y}</td>)
							),
						)}
					</tr>
				))}
			</tbody>
		</table>
	);
}

import React from "react";
import $ from "jquery";
import { federalDistricts, federalSubjects, steps, years } from "../../../data";
import "select2";
import "jquery-mapael";
import "./russia";
import { Select2Dropdown } from "@components/select2-dropdown";
import { InteractiveMap } from "./interactive-map";
import { StatInfoRegions } from "./stat-info-region";
import { StatInfoAllRegions } from "./stat-info-all-regions";

const federalCityCodes = ["msk", "spb", "svs"];
const federalCities = federalSubjects.filter(s => federalCityCodes.includes(s.code));

export class SlideMap extends React.Component<any, any> {
	public state = {
		subject: 0,
		year: 2018,
		district: 0,
		step: 1,
		subjects: [],
	};

	private mapContainerRef = React.createRef<HTMLDivElement>();

	private updateMap = (districtId: number, subjectId: number) => {
		$(this.mapContainerRef.current!).trigger("update", [
			{
				mapOptions: {
					areas: Object.assign(
						{},
						...federalSubjects.map(x => ({
							[x.code]: {
								attrs: {
									fill:
										x.id === subjectId
											? "#bca93f"
											: x.districtId === districtId
											? "#bdc6cb"
											: "#dbe3e8",
								},
							},
						})),
					),
					plots: Object.assign(
						{},
						...federalCities.map(x => ({
							[x.code]: {
								attrs: {
									fill:
										x.id === subjectId
											? "#bca93f"
											: x.districtId === districtId
											? "#bdc6cb"
											: "#dbe3e8",
								},
							},
						})),
					),
				},
				animDuration: 500,
			},
		]);

		let zoomProps: { level: number; plot?: string; area?: string } = { level: 0 };

		if (subjectId > 0) {
			const subj = federalSubjects.find(x => x.id === subjectId)!;

			zoomProps = federalCityCodes.includes(subj.code)
				? { level: 10, plot: subj.code }
				: { level: 10, area: subj.code };
		}

		$(this.mapContainerRef.current!).trigger("zoom", zoomProps);
	};

	private handleDistrictChange = (districtId: number) => {
		const subjects = federalSubjects.filter(subject => subject.districtId === districtId);
		const subjectId = subjects.length === 0 ? 0 : subjects[0].id;

		this.setState({
			district: districtId,
			subjects,
			subject: subjectId,
		});

		this.updateMap(districtId, subjectId);
	};

	private handleSubjectChange = (subjectId: number) => {
		this.setState({
			subject: subjectId,
		});

		this.updateMap(this.state.district, subjectId);
	};

	private handleStepChange = (stepId: number) => {
		this.setState({
			step: stepId,
		});
	};

	private handleYearChange = (year: number) => {
		this.setState({
			year,
		});
	};

	public render() {
		const { subject, year, district, step, subjects } = this.state;

		return (
			<div className="slide slide-map">
				<div className="slide__top">02</div>
				<div className="slide-header">Карта участников и победителей</div>
				<div className="slide-filter filter-toolbar">
					<div className="filter-toolbar__label">Фильтр</div>
					<div className="filter-toolbar__filters">
						<Select2Dropdown
							onChange={this.handleYearChange}
							selection={year}
							items={years}
							className="slide-filter__year"
						/>

						<Select2Dropdown
							onChange={this.handleStepChange}
							selection={step}
							items={steps}
							className="slide-filter__step"
						/>

						<Select2Dropdown
							onChange={this.handleDistrictChange}
							selection={district}
							items={[{ id: 0, name: "Все" }].concat(federalDistricts)}
							className="slide-filter__district"
						/>

						{subjects.length > 0 && (
							<Select2Dropdown
								onChange={this.handleSubjectChange}
								selection={subject}
								items={subjects}
								className="slide-filter__subject"
							/>
						)}
					</div>
				</div>

				<div className="slide-map-container">
					<InteractiveMap ref={this.mapContainerRef} />
					{subject > 0 && (
						<div className="slide-map-tooltip">
							<div className="slide-map-tooltip__header">
								{federalSubjects.find(f => f.id === subject)!.name}
							</div>
							<span className="slide-map-stats-strong">3500 (9%)</span> участников
							<br />
							<span className="slide-map-stats-strong">25 (3%)</span> призеров
							<br />
							<span className="slide-map-stats-strong">4 (2%)</span> победителей
						</div>
					)}
				</div>

				{subject > 0 ? <StatInfoRegions /> : <StatInfoAllRegions year={years.find(y => y.id === year)!.name} />}

				<div className="slide__action">
					{/*<button className="button button--primary">Узнать больше</button>*/}
				</div>
			</div>
		);
	}
}

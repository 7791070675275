import React from "react";
import { BubbleChart } from "@components/figures/bubble-chart";
import { years, schoolSubjects, federalSubjects } from "../../../../data";
import cn from "classnames";

const localFederalSubjects = [
	{ id: 10, name: "г. Москва" },
	{ id: 9, name: "г. Санкт-Петербург" },
	{ id: 8, name: "Республика Татарстан" },
	{ id: 7, name: "Московская область" },
	{ id: 6, name: "Челябинская область" },
	{ id: 5, name: "Новосибирская область" },
	{ id: 4, name: "Удмуртская республика" },
	{ id: 3, name: "Нижегородская область" },
	{ id: 2, name: "Кировская область" },
	{ id: 1, name: "Республика Мордовия" },
];

enum DataSource {
	Region = 1,
	SchoolSubject = 2,
}

const localSchoolSubjects = [
	{ id: 1, name: "Экономика" },
	{ id: 2, name: "Экология" },
	{ id: 3, name: "Физическая культура" },
	{ id: 4, name: "Технология" },
	{ id: 5, name: "ОБЖ" },
	{ id: 6, name: "Искусство (МХК)" },
	{ id: 7, name: "Астрономия" },
];

function generateFederalSubjects() {
	return localFederalSubjects.map(x => ({
		id: x.id,
		name: x.name,
		values: years.map(y => ({
			id: y.id,
			name: y.name,
			value: Math.random() * 1000,
			subj: x.name,
		})),
	}));
}

function generateSchoolSubjects() {
	return localSchoolSubjects.map(x => ({
		id: x.id,
		name: x.name,
		values: years.map(y => ({
			id: y.id,
			name: y.name,
			value: Math.random() * 1000,
			subj: x.name,
		})),
	}));
}

function getTooltipFederalSubjects(item: any) {
	return `<div>
			<div class="chart-tooltip__header">${item.subj} ${item.name}</div>
			${[1, 2, 3, 4].map(() => `<div>${getSchoolSubjName(Math.floor(Math.random() * 24))}</div>`).join("")}
		</div>`;
}

function getFederalSubjName(id: number) {
	const res = federalSubjects.find(x => x.id === id);

	return res ? res.name : "";
}

function getSchoolSubjName(id: number) {
	const res = schoolSubjects.find(x => x.id === id);

	return res ? res.name : "";
}

function getTooltipSchoolSubjects(item: any) {
	return `<div>
			<div class="chart-tooltip__header">${item.subj} ${item.name}</div>
			
			${[1, 2, 3, 4].map(() => `<div>${getFederalSubjName(Math.floor(Math.random() * 85))}</div>`).join("")}
		</div>`;
}

export function Slide6() {
	const [dataSource, setDataSource] = React.useState(DataSource.Region);

	function handleDataSourceChange(ds: DataSource) {
		return function() {
			setDataSource(ds);
		};
	}

	const data = dataSource === DataSource.Region ? generateFederalSubjects() : generateSchoolSubjects();
	const getTooltip = dataSource === DataSource.Region ? getTooltipFederalSubjects : getTooltipSchoolSubjects;

	return (
		<>
			<div className="slide-6-toolbar">
				<div
					className={cn("slide-6-button", { "slide-6-button--active": dataSource === DataSource.Region })}
					onClick={handleDataSourceChange(DataSource.Region)}
				>
					По регионам
				</div>
				<div
					className={cn("slide-6-button", {
						"slide-6-button--active": dataSource === DataSource.SchoolSubject,
					})}
					onClick={handleDataSourceChange(DataSource.SchoolSubject)}
				>
					По предметам
				</div>
			</div>
			<BubbleChart className="chart--bubble-light" data={data} getTooltip={getTooltip} />
			<div style={{ padding: 35 }} />
		</>
	);
}

import React from "react";
import cn from "classnames";
import { AlphabetFilter } from "../../olympiads/shared/alphabet-filter";
import { SearchPanel } from "@components/search-panel";
import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../types";
import { PagerSize } from "@components/pager-size";
import { federalDistricts, federalSubjects, schoolClasses, schoolSubjects, steps, years } from "../../../data";
import { CloseIcon } from "@icons/close-icon";
import { FilterToolbar } from "@components/filter-toolbar";
import { generateData } from "./generate-participants-data";
import { DisabledIcon } from "@icons/disabled";
import { EducationIcon } from "@icons/education";

const filters = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
	{
		id: "step",
		name: "Этап олимпиады",
		items: [{ id: 0, name: "Этап олимпиады" }].concat(steps),
		value: 0,
	},
	{
		id: "schoolClass",
		name: "Класс",
		items: [{ id: 0, name: "Класс" }].concat(schoolClasses.map(s => ({ id: s, name: s.toString() }))),
		value: 0,
	},
	{
		id: "schoolSubject",
		name: "Предмет",
		items: [{ id: 0, name: "Предмет" }].concat(schoolSubjects),
		value: 0,
	},
	{
		id: "result",
		name: "Результат",
		items: [
			{ id: 0, name: "Результат" },
			{ id: 1, name: "Победитель" },
			{ id: 2, name: "Призер" },
			{ id: 3, name: "Участник" },
		],
		value: 0,
	},
];

function getRowClassName(dataItem: any) {
	if (dataItem.result === "Призёр") {
		return cn("analytics-participants-table__row", "analytics-participants-table__row--prizer");
	}

	if (dataItem.result === "Победитель") {
		return cn("analytics-participants-table__row", "analytics-participants-table__row--winner");
	}

	return cn("analytics-participants-table__row", "analytics-participants-table__row--participant");
}

function Table({ onRowClick, data }: { onRowClick(item: any): void; data: any }) {
	function handleRowClick(item: any) {
		return function() {
			onRowClick(item);
		};
	}

	function handleCellClick(e: React.FormEvent<HTMLTableCellElement>) {
		e.stopPropagation();
	}

	function formatAppelation(appelation: number) {
		if (appelation > 0) {
			return `+ ${appelation.toFixed(0)}`;
		}

		if (appelation < 0) {
			return `- ${Math.abs(appelation).toFixed(0)}`;
		}

		return "";
	}

	return (
		<table className="analytics-participants-table">
			<tbody>
				<tr>
					<th>№</th>
					<th></th>
					<th>ФИО</th>
					<th>
						Класс
						<br />
						обучения
					</th>
					<th>
						Класс
						<br />
						участия
					</th>
					<th>
						Организация,
						<br />
						осуществляющая
						<br />
						обучение
					</th>
					<th>Начиленный балл</th>
					<th>Аппеляция</th>
					<th>Итоговый балл</th>
					<th>Результат</th>
					<th>
						Номер и скан
						<br /> работы
					</th>
				</tr>
				{data.map((d: any) => (
					<tr key={d.id} className={getRowClassName(d)} onClick={handleRowClick(d)}>
						<td>{d.id}</td>
						<td>
							<img src={d.country} />
							<div className="analytics-participants-table__symbol-icon">{d.male ? "М" : "Ж"}</div>
						</td>
						<td>
							<div style={{ display: "flex" }}>
								<div>
									{d.surname}
									<br />
									{d.name}&nbsp;{d.fatherName}
								</div>
								<div className="analytics-participants-table__icons">
									{d.disabled && (
										<DisabledIcon className="analytics-participants-table__icon analytics-participants-table__icon--disabled" />
									)}
									{d.hasCoach && (
										<EducationIcon className="analytics-participants-table__icon analytics-participants-table__icon--education" />
									)}
								</div>
							</div>
						</td>
						<td>{d.class}</td>
						<td>{d.classRange}</td>
						<td>{d.school}</td>
						<td>{d.score}</td>
						<td
							className={cn({
								"analytics-participants-table__appelation--success": d.appelation > 0,
								"analytics-participants-table__appelation--fail": d.appelation < 0,
							})}
						>
							{formatAppelation(d.appelation)}
						</td>
						<td>{d.resultScore}</td>
						<td>{d.result}</td>
						<td onClick={handleCellClick}>
							<FileDownloadLink type={FileType.Pdf} text={d.reference} />
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

function ParticipantInfo({ participant, onClose }: any) {
	return (
		<div className="slide slide--dark">
			<div className="participant-info">
				<div className="participant-info__header">
					Карточка участника
					<span onClick={onClose}>
						<CloseIcon className="close-icon" />
					</span>
				</div>
				<div className="participant-info__person-card">
					<div className="participant-info-card">
						<div className="participant-info-card__surname">{participant.surname}</div>
						<div className="participant-info-card__name">
							{participant.name} {participant.fatherName}
						</div>

						<div>
							<strong>Пол:</strong> {participant.male ? "мужской" : "женский"}
						</div>
						<div>
							<strong>Гражданство:</strong> РФ
						</div>
						<div>
							<strong>ОВЗ:</strong>{" "}
							{participant.disabled ? "нарушение опорно-двигательного аппарата" : "нет"}
						</div>
					</div>
				</div>

				<div className="participant-info-data">
					<div className="participant-info-data__year">2017/18</div>
					<div className="participant-info-data__place">
						г. Санкт-Петербург, Гимназия №7
						<br />
						<strong>Личный тренер:</strong> Смирнов Петр Петрович
					</div>

					<table className="analytics-participants-table">
						<tbody>
							<tr>
								<th>Предмет</th>
								<th>Этап</th>
								<th>
									Класс
									<br />
									обучения
								</th>
								<th>
									Класс
									<br />
									участия
								</th>
								<th>Балл</th>
								<th>Результат</th>
								<th>
									Номер и скан
									<br /> работы
								</th>
							</tr>
							<tr>
								<td>Математика</td>
								<td>Заключительный</td>
								<td>9</td>
								<td>9</td>
								<td>104</td>
								<td>Призер</td>
								<td>
									<FileDownloadLink
										type={FileType.Pdf}
										text="456786"
										className="participant-info-file"
									/>
								</td>
							</tr>
						</tbody>
					</table>

					<div className="participant-info-data__annotation participant-info-annotation">
						<div className="participant-info-annotation__header">Апелляция</div>
						<strong>Дата проведения:</strong> 01.04.2019
						<br />
						<strong>№ протокола:</strong> №1234567
						<br />
						<strong>Решение:</strong> удовлетворено (добавлено +5 баллов)
					</div>
				</div>

				<div className="participant-info-data">
					<div className="participant-info-data__year">2017/18</div>
					<div className="participant-info-data__place">Московская область, г. Королев, Лицей № 34</div>

					<table className="analytics-participants-table">
						<tbody>
							<tr>
								<th>Предмет</th>
								<th>Этап</th>
								<th>
									Класс
									<br />
									обучения
								</th>
								<th>
									Класс
									<br />
									участия
								</th>
								<th>Балл</th>
								<th>Результат</th>
								<th>
									Номер и скан
									<br /> работы
								</th>
							</tr>
							<tr>
								<td>Математика</td>
								<td>Школьный</td>
								<td>7</td>
								<td>7</td>
								<td>95</td>
								<td>Призер</td>
								<td>
									<FileDownloadLink
										type={FileType.Pdf}
										text="353467"
										className="participant-info-file"
									/>
								</td>
							</tr>
							<tr>
								<td>Физика</td>
								<td>Школьный</td>
								<td>7</td>
								<td>7</td>
								<td>80</td>
								<td>Призер</td>
								<td>
									<FileDownloadLink
										type={FileType.Pdf}
										text="142312"
										className="participant-info-file"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<div className="participant-info-data">
					<div className="participant-info-data__year">2016/17</div>
					<div className="participant-info-data__place">Московская область, г. Подольск, Школа № 1154</div>

					<table className="analytics-participants-table">
						<tbody>
							<tr>
								<th>Предмет</th>
								<th>Этап</th>
								<th>
									Класс
									<br />
									обучения
								</th>
								<th>
									Класс
									<br />
									участия
								</th>
								<th>Балл</th>
								<th>Результат</th>
								<th>
									Номер и скан
									<br /> работы
								</th>
							</tr>
							<tr>
								<td>Математика</td>
								<td>Школьный</td>
								<td>7</td>
								<td>7</td>
								<td>95</td>
								<td>Призер</td>
								<td>
									<FileDownloadLink
										type={FileType.Pdf}
										text="353467"
										className="participant-info-file"
									/>
								</td>
							</tr>
							<tr>
								<td>Физика</td>
								<td>Школьный</td>
								<td>7</td>
								<td>7</td>
								<td>80</td>
								<td>Призер</td>
								<td>
									<FileDownloadLink
										type={FileType.Pdf}
										text="142312"
										className="participant-info-file"
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}

function AdditionalFilters() {
	const [expanded, setExpanded] = React.useState(false);

	function toggleExpanded() {
		setExpanded(!expanded);
	}

	return (
		<div className="additional-filters">
			<div className="additional-filters__header" onClick={toggleExpanded}>
				Дополнительные фильтры <span>{expanded ? "-" : "+"}</span>
			</div>
			{expanded && (
				<div className="additional-filters__items">
					<div className="additional-filters__item">Гражданство</div>
					<div className="additional-filters__item">Пол</div>
					<div className="additional-filters__item">ОВЗ</div>
					<div className="additional-filters__item">Нарушения</div>
					<div className="additional-filters__item">Личный тренер</div>
					<div className="additional-filters__item">Тип школы</div>
					<div className="additional-filters__item">Аппеляции</div>
				</div>
			)}
		</div>
	);
}

export function RussianCompetitions() {
	const [selected, setSelected] = React.useState<any>(null);
	const [data, setData] = React.useState(generateData(0, 0));

	function handleItemSelect(item: any) {
		setSelected(item);
	}

	function handleDeselect() {
		setSelected(null);
	}

	function handleFilter(_filterId: any, _value: any, filters: any) {
		setData(generateData(filters.schoolClass, filters.result));
	}

	return (
		<>
			<div className="filter">
				<FilterToolbar filters={filters} onChange={handleFilter} />
			</div>
			<AdditionalFilters />

			{selected === null ? (
				<div className="slide slide--indigo">
					<div className="analytics-participants-search">
						<AlphabetFilter />
						<SearchPanel />
					</div>
					<div className="analytics-participants-data">
						<div className="analytics-participants-data__annotation">
							<div className="analytics-participants-data__annotation-file">
								<FileDownloadLink type={FileType.Xls} text="Скачать таблицу" />
							</div>
							<p>
								Список <strong>участников</strong>, выполнявших задания для <strong>10 класса</strong>
								на <strong>муниципальном</strong> этапе Всероссийской олимпиады школьников{" "}
								<strong>2018/19 гг.</strong> по <strong>английскому языку</strong> в{" "}
								<strong>Алейском муниципальном районе Алтайского края</strong>.
							</p>
						</div>
						<PagerSize />
						<Table onRowClick={handleItemSelect} data={data} />
						{/*<Pager />*/}
					</div>
				</div>
			) : (
				<ParticipantInfo participant={selected} onClose={handleDeselect} />
			)}
		</>
	);
}

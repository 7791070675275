import React from "react";
import { DonutPercentage } from "@components/figures/donut-percentage";
import { federalDistricts, federalSubjects, steps } from "../../../../data";
import { FilterToolbar } from "@components/filter-toolbar";

function genDataItem() {
	return {
		avg: 5 * Math.random(),
		actual: 10000000 + 10000000 * Math.random(),
		pupils: 3000000 + 3000000 * Math.random(),
		perc: 20 + 40 * Math.random(),
	};
}

function generateData() {
	return [
		{
			id: 1,
			name: "2016/17",
			color: "#fbbb31",
			...genDataItem(),
		},
		{
			id: 2,
			name: "2017/18",
			color: "#bca93f",
			...genDataItem(),
		},
		{
			id: 3,
			name: "2018/19",
			color: "#f27f19",
			...genDataItem(),
		},
	];
}

const filters = [
	{
		id: "step",
		name: "Этап олимпиады",
		items: [{ id: 0, name: "Этап олимпиады" }].concat(steps),
		value: 0,
	},
	{
		id: "federal-district",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federal-subject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
];

export function Slide2() {
	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	return (
		<div className="analytics-period__slide2">
			<div className="filter">
				<FilterToolbar filters={filters} onChange={handleFilterChange} />
			</div>
			<div className="donut-rings__items">
				{data.map(item => (
					<div className="donut-rings__item" key={item.id}>
						<DonutPercentage
							key={item.id}
							percentage={item.perc}
							className="donut-percentage-analytics-period-slide2"
							color={item.color}
						>
							<div className="donut-percentage__content">
								<div className="analytics-period-slide2-donut-value">{item.avg.toFixed(2)}</div>
								<div className="analytics-period-slide2-donut-description">
									среднее число
									<br /> предметов на <br />
									одного участника
								</div>
							</div>
						</DonutPercentage>
						<div className="donut-ring-name">{item.name}</div>
					</div>
				))}
			</div>

			<div className="slide2-legend">
				<div>
					<div className="slide2-legend__item1">
						<div className="slide2-legend__line1" />
						<div>Фактическое количество участников</div>
					</div>
					<div className="slide2-legend__item2">
						<div className="slide2-legend__line2" />
						<div>Доля участвовавших школьников</div>
					</div>
				</div>
			</div>
		</div>
	);
}

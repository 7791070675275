import React from "react";
import { LoginService } from "../services/login-service";
import { LoginForm } from "../pages/login/login-form";

function Logout() {
	function handleLogout() {
		LoginService.logout();
		// @ts-ignore
		document.location = "/";
	}

	return (
		<div className="login-from-menu">
			<div className="login-from-menu__button" onClick={handleLogout}>Выйти</div>
		</div>
	);
}

function Login() {
	const [showPopup, togglePopup] = React.useState(false);

	function togglePopupShow() {
		togglePopup(!showPopup);
	}

	// function handleLogin() {
	// 	if (LoginService.login("1", "1")) {
	// 		if (document.location.pathname === "/login") {
	// 			// @ts-ignore
	// 			document.location = "/";
	// 		}
	// 	}
	// 	onAction();
	// }

	return (
		<div className="login-from-menu">
			<div className="login-from-menu__button" onClick={togglePopupShow}>
				Логин
			</div>
			{showPopup && (
				<div className="login-popup">
					<LoginForm submitLoginText=">" />
				</div>
			)}
		</div>
	);
}

export function LoginPanel() {
	// const [, setUpdate] = React.useState(Date.now());
	//
	// function handleOnAction() {
	// 	setUpdate(Date.now());
	// }

	return <div>{LoginService.isLoggedIn() ? <Logout /> : <Login />}</div>;
}

import React from "react";

export function EducationIcon({ className }: { className?: string }) {
	return (
		<svg version="1.1" className={className} x="0px" y="0px" viewBox="0 0 337.5 337.5">
			<g>
				<path
					d="M29.2,337.5h279.1c16.1,0,29.2-13.1,29.2-29.2V29.2c0-16.1-13.1-29.2-29.2-29.2H29.2C13.1,0,0,13.1,0,29.2
		v279.1C0,324.4,13.1,337.5,29.2,337.5z"
				/>
				<g>
					<path d="M243.1,227.5c10.1-6.8,17.2-16,17.2-29.4l-0.1-43.3l-17.1,7V227.5z" />
					<path
						d="M172.7,176.6l51.1-20.5L172,130.9c-1.1,0.2-2.2,0.3-3.4,0.3c-8.1,0-14.6-3.9-14.6-8.6c0-4.8,6.5-8.6,14.6-8.6
			c8,0,14.6,3.9,14.6,8.6c0,1-0.4,2-0.9,2.9l53.3,25.8l64.8-26.1c2.4-1.2,1.6-4.5-0.1-5L172.5,68.6c-1.9-0.8-5.3-1.1-7.9,0
			L37.3,120.2c-2.4,1-2.4,4.2-0.2,5.3l127.3,51.2C167.7,178,170.5,177.5,172.7,176.6z"
					/>
					<path
						d="M221.4,269.6c5.1,0,9.6-3.1,11.5-7.5c-6.3,0.5-11.6-4.8-11.6-10.8v-81l-46.9,18.9c-4,1.6-7.1,2.2-11.1,0.3
			l-86.5-34.7v43.3c0,22.3,19.6,32.5,40.5,40.5c24.7,9.4,63.9,9.6,91.3,3.2v15.2C208.7,264,214.4,269.6,221.4,269.6z"
					/>
				</g>
			</g>
		</svg>
	);
}

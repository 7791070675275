import React from "react";
import * as d3 from "d3";

interface DataItem {
	value: number;
	name: string;
}

interface Datum {
	data: DataItem;
}

interface Props {
	data: DataItem[];
	className?: string;
}

export class DistributionDonutChart extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();

	public componentDidMount() {
		this.createGraph(this.props.data);
	}

	public componentDidUpdate() {
		this.updateGraphData(this.props.data);
	}

	private createGraph(data: DataItem[]) {
		const node = this.svgRef.current;

		const width = 500;
		const height = Math.min(width, 500);

		const pie = d3
			.pie<{ name: string; value: number }>()
			.padAngle(0.005)
			.sort(null)
			.value(d => d.value);

		const arcs = pie(data);

		const radius = Math.min(width, height) / 2;

		const arc = d3
			.arc()
			.innerRadius(radius * 0.2)
			.outerRadius(radius - 1);

		const color = d3
			.scaleLinear()
			//@ts-ignore
			.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
			//@ts-ignore
			.range(["#e8f6fe", "#0b324f"]);

		var div = d3
			.select("body")
			.append("div")
			.attr("class", "distribution-chart-tooltip")
			.style("opacity", 0);

		d3.select(node)
			.selectAll("path")
			.data(arcs)
			.join("path")
			.attr("fill", d => color(d.data.value))
			//@ts-ignore
			.attr("d", arc)

			.on("mouseover", (e: any) => {
				div.transition()
					.duration(200)
					.style("opacity", 1);
				div.html(
					`<div class="distribution-chart-tooltip__header">${e.data.name}</div>
							<div class="distribution-chart-tooltip__person-stats">
								<span class="slide-map-stats-strong">270000</span> участников<br/>
								<span class="slide-map-stats-strong">78</span> призеров<br/>
								<span class="slide-map-stats-strong">28</span> победителей
							</div>
							<div>
								<span class="slide-map-stats-strong">78</span> районов<br/>
								<span class="slide-map-stats-strong">50000</span> школ
							</div>
						`,
				)
					.style("left", d3.event.pageX + "px")
					.style("top", d3.event.pageY - 28 + "px");
			})
			.on("mouseout", () => {
				div.transition()
					.duration(500)
					.style("opacity", 0);
			});
	}

	private updateGraphData(data: DataItem[]) {
		const node = this.svgRef.current;

		const pie = d3
			.pie<{ name: string; value: number }>()
			.padAngle(0.005)
			.sort(null)
			.value(d => d.value);

		const arcs = pie(data);

		const color = d3
			.scaleLinear()
			//@ts-ignore
			.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
			//@ts-ignore
			.range(["#e8f6fe", "#0b324f"]);

		const radius = 250;

		const arc = d3
			.arc()
			.innerRadius(radius * 0.2)
			.outerRadius(radius - 1);

		var rects = d3
			.select(node)
			.selectAll("path")
			.data(arcs)
			//@ts-ignore
			.attr("d", arc);

		// enter selection
		rects.enter().append("path");

		// update selection
		rects
			.transition()
			.duration(300)
			.attr("fill", (d: Datum) => color(d.data.value));

		rects.exit().remove();
	}

	public render() {
		const width = 500;
		const height = 500;
		const viewBox = `${-width / 2}, ${-height / 2}, ${width}, ${height}`;

		return (
			<svg ref={this.svgRef} width={500} height={500} viewBox={viewBox} className={this.props.className}>
				<g className="slices" />
			</svg>
		);
	}
}

import React from "react";
import $ from "jquery";

export class Select2Dropdown extends React.Component<{
	items: any[];
	selection: number;
	onChange: (id: number) => void;
	className?: string;
}> {
	private selectRef = React.createRef<HTMLSelectElement>();

	public componentDidMount() {
		const { onChange } = this.props;

		$(this.selectRef.current!)
			//@ts-ignore
			.select2({
				minimumResultsForSearch: Infinity,
			})
			.on("select2:select", (e: React.FormEvent<HTMLSelectElement>) => {
				const selection = parseInt(e.currentTarget.value, 10);

				onChange(selection);
			});
	}

	public componentWillUnmount() {
		//@ts-ignore
		$(this.selectRef.current!).select2("destroy");
		$(this.selectRef.current!).off("select2:select");
	}

	public render() {
		const { items, selection, className } = this.props;

		return (
			<select ref={this.selectRef} className={className} defaultValue={selection}>
				{items.map(x => (
					<option value={x.id} key={x.id}>
						{x.name}
					</option>
				))}
			</select>
		);
	}
}

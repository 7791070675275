import React from "react";
import { Winners } from "./winners";
import { Schedule } from "./schedule";
import { Results } from "./results";

export function InternationalContent() {
	return (
		<>
			<div className="slide slide--dark">
				<div className="slide__top">01</div>
				<div className="slide-header">Расписание международных олимпиад</div>
				<Schedule />
			</div>
			<div className="slide">
				<div className="slide__top">02</div>
				<div className="slide-header">Результаты</div>
				<Results />
			</div>
			<div className="slide slide--dark">
				<div className="slide__top">03</div>
				<div className="slide-header">Динамика количества победителей международных победителей</div>
				<Winners />
			</div>
		</>
	);
}

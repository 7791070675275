export const federalSubjects = [
	{
		id: 1,
		name: "Алтайский край",
		districtId: 7,
		code: "al",
	},
	{
		id: 2,
		name: "Амурская область",
		districtId: 8,
		code: "am",
	},
	{
		id: 3,
		name: "Архангельская область",
		districtId: 2,
		code: "ar",
	},
	{
		id: 4,
		name: "Астраханская область",
		districtId: 3,
		code: "as",
	},
	{
		id: 5,
		name: "Белгородская область",
		districtId: 1,
		code: "bl",
	},
	{
		id: 6,
		name: "Брянская область",
		districtId: 1,
		code: "bn",
	},
	{
		id: 7,
		name: "Владимирская область",
		districtId: 1,
		code: "vm",
	},
	{
		id: 8,
		name: "Волгоградская область",
		districtId: 3,
		code: "vl",
	},
	{
		id: 9,
		name: "Вологодская область",
		districtId: 2,
		code: "vo",
	},
	{
		id: 10,
		name: "Воронежская область",
		districtId: 1,
		code: "vn",
	},
	{
		id: 11,
		name: "г. Москва",
		districtId: 1,
		code: "msk",
	},
	{
		id: 12,
		name: "г. Санкт-Петербург",
		districtId: 2,
		code: "spb",
	},
	{
		id: 13,
		name: "г. Севастополь",
		districtId: 3,
		code: "svs",
	},
	{
		id: 14,
		name: "Еврейская автономная область",
		districtId: 8,
		code: "eu",
	},
	{
		id: 15,
		name: "Забайкальский край",
		districtId: 8,
		code: "zb",
	},
	{
		id: 16,
		name: "Ивановская область",
		districtId: 1,
		code: "iv",
	},
	{
		id: 17,
		name: "Иркутская область",
		districtId: 7,
		code: "ir",
	},
	{
		id: 18,
		name: "Кабардино-Балкарская Республика",
		districtId: 4,
		code: "kb",
	},
	{
		id: 19,
		name: "Калининградская область",
		districtId: 2,
		code: "kn",
	},
	{
		id: 20,
		name: "Калужская область",
		districtId: 1,
		code: "kj",
	},
	{
		id: 21,
		name: "Камчатский край",
		districtId: 8,
		code: "ka",
	},
	{
		id: 22,
		name: "Карачаево-Черкесская Республика",
		districtId: 4,
		code: "kc",
	},
	{
		id: 23,
		name: "Кемеровская область",
		districtId: 7,
		code: "km",
	},
	{
		id: 24,
		name: "Кировская область",
		districtId: 5,
		code: "ki",
	},
	{
		id: 25,
		name: "Костромская область",
		districtId: 1,
		code: "kt",
	},
	{
		id: 26,
		name: "Краснодарский край",
		districtId: 3,
		code: "ks",
	},
	{
		id: 27,
		name: "Красноярский край",
		districtId: 7,
		code: "kr",
	},
	{
		id: 28,
		name: "Курганская область",
		districtId: 6,
		code: "ku",
	},
	{
		id: 29,
		name: "Курская область",
		districtId: 1,
		code: "ky",
	},
	{
		id: 30,
		name: "Ленинградская область",
		districtId: 2,
		code: "le",
	},
	{
		id: 31,
		name: "Липецкая область",
		districtId: 1,
		code: "lp",
	},
	{
		id: 32,
		name: "Магаданская область",
		districtId: 8,
		code: "ma",
	},
	{
		id: 33,
		name: "Московская область",
		districtId: 1,
		code: "mc",
	},
	{
		id: 34,
		name: "Мурманская область",
		districtId: 2,
		code: "mu",
	},
	{
		id: 35,
		name: "Ненецкий автономный округ",
		districtId: 2,
		code: "ne",
	},
	{
		id: 36,
		name: "Нижегородская область",
		districtId: 5,
		code: "nn",
	},
	{
		id: 37,
		name: "Новгородская область",
		districtId: 2,
		code: "no",
	},
	{
		id: 38,
		name: "Новосибирская область",
		districtId: 7,
		code: "nv",
	},
	{
		id: 39,
		name: "Омская область",
		districtId: 7,
		code: "om",
	},
	{
		id: 40,
		name: "Оренбургская область",
		districtId: 5,
		code: "ob",
	},
	{
		id: 41,
		name: "Орловская область",
		districtId: 1,
		code: "or",
	},
	{
		id: 42,
		name: "Пензенская область",
		districtId: 5,
		code: "pz",
	},
	{
		id: 43,
		name: "Пермский край",
		districtId: 5,
		code: "pe",
	},
	{
		id: 44,
		name: "Приморский край",
		districtId: 8,
		code: "pr",
	},
	{
		id: 45,
		name: "Псковская область",
		districtId: 2,
		code: "ps",
	},
	{
		id: 46,
		name: "Республика Адыгея (Адыгея)",
		districtId: 3,
		code: "ad",
	},
	{
		id: 47,
		name: "Республика Алтай",
		districtId: 7,
		code: "lt",
	},
	{
		id: 48,
		name: "Республика Башкортостан",
		districtId: 5,
		code: "bs",
	},
	{
		id: 49,
		name: "Республика Бурятия",
		districtId: 8,
		code: "br",
	},
	{
		id: 50,
		name: "Республика Дагестан",
		districtId: 4,
		code: "da",
	},
	{
		id: 51,
		name: "Республика Ингушетия",
		districtId: 4,
		code: "in",
	},
	{
		id: 52,
		name: "Республика Калмыкия",
		districtId: 3,
		code: "kk",
	},
	{
		id: 53,
		name: "Республика Карелия",
		districtId: 2,
		code: "kl",
	},
	{
		id: 54,
		name: "Республика Коми",
		districtId: 2,
		code: "ko",
	},
	{
		id: 55,
		name: "Республика Крым",
		districtId: 3,
		code: "cr",
	},
	{
		id: 56,
		name: "Республика Марий Эл",
		districtId: 5,
		code: "ml",
	},
	{
		id: 57,
		name: "Республика Мордовия",
		districtId: 5,
		code: "mr",
	},
	{
		id: 58,
		name: "Республика Саха (Якутия)",
		districtId: 8,
		code: "sa",
	},
	{
		id: 59,
		name: "Республика Северная Осетия - Алания",
		districtId: 4,
		code: "so",
	},
	{
		id: 60,
		name: "Республика Татарстан (Татарстан)",
		districtId: 5,
		code: "ta",
	},
	{
		id: 61,
		name: "Республика Тыва",
		districtId: 7,
		code: "tv",
	},
	{
		id: 62,
		name: "Республика Хакасия",
		districtId: 7,
		code: "hk",
	},
	{
		id: 63,
		name: "Ростовская область",
		districtId: 3,
		code: "ro",
	},
	{
		id: 64,
		name: "Рязанская область",
		districtId: 1,
		code: "rz",
	},
	{
		id: 65,
		name: "Самарская область",
		districtId: 5,
		code: "ss",
	},
	{
		id: 66,
		name: "Саратовская область",
		districtId: 5,
		code: "sr",
	},
	{
		id: 67,
		name: "Сахалинская область",
		districtId: 8,
		code: "sh",
	},
	{
		id: 68,
		name: "Свердловская область",
		districtId: 6,
		code: "sv",
	},
	{
		id: 69,
		name: "Смоленская область",
		districtId: 1,
		code: "sm",
	},
	{
		id: 70,
		name: "Ставропольский край",
		districtId: 4,
		code: "st",
	},
	{
		id: 71,
		name: "Тамбовская область",
		districtId: 1,
		code: "tb",
	},
	{
		id: 72,
		name: "Тверская область",
		districtId: 1,
		code: "tr",
	},
	{
		id: 73,
		name: "Томская область",
		districtId: 7,
		code: "tm",
	},
	{
		id: 74,
		name: "Тульская область",
		districtId: 1,
		code: "tl",
	},
	{
		id: 75,
		name: "Тюменская область",
		districtId: 6,
		code: "tu",
	},
	{
		id: 76,
		name: "Удмуртская Республика",
		districtId: 5,
		code: "ud",
	},
	{
		id: 77,
		name: "Ульяновская область",
		districtId: 5,
		code: "ul",
	},
	{
		id: 78,
		name: "Хабаровский край",
		districtId: 8,
		code: "ha",
	},
	{
		id: 79,
		name: "Ханты-Мансийский автономный округ - Югра",
		districtId: 6,
		code: "ht",
	},
	{
		id: 80,
		name: "Челябинская область",
		districtId: 6,
		code: "cl",
	},
	{
		id: 81,
		name: "Чеченская Республика",
		districtId: 4,
		code: "cc",
	},
	{
		id: 82,
		name: "Чувашская Республика - Чувашия",
		districtId: 5,
		code: "cu",
	},
	{
		id: 83,
		name: "Чукотский автономный округ",
		districtId: 8,
		code: "ch",
	},
	{
		id: 84,
		name: "Ямало-Ненецкий автономный округ",
		districtId: 6,
		code: "ya",
	},
	{
		id: 85,
		name: "Ярославская область",
		districtId: 1,
		code: "yr",
	},
];

export const federalDistricts = [
	{
		id: 1,
		name: "Центральный",
	},
	{
		id: 2,
		name: "Северо-Западный",
	},
	{
		id: 3,
		name: "Южный",
	},
	{
		id: 4,
		name: "Северо-Кавказский",
	},
	{
		id: 5,
		name: "Приволжский",
	},
	{
		id: 6,
		name: "Уральский",
	},
	{
		id: 7,
		name: "Сибирский",
	},
	{
		id: 8,
		name: "Дальневосточный",
	},
];

export const schoolSubjects = [
	{
		id: 1,
		name: "Английский язык",
	},
	{
		id: 2,
		name: "Астрономия",
	},
	{
		id: 3,
		name: "Биология",
	},
	{
		id: 4,
		name: "География",
	},
	{
		id: 5,
		name: "Информатика",
	},
	{
		id: 6,
		name: "Искусство (МХК)",
	},
	{
		id: 7,
		name: "Испанский язык",
	},
	{
		id: 8,
		name: "История",
	},
	{
		id: 9,
		name: "Итальянский язык",
	},
	{
		id: 10,
		name: "Китайский язык",
	},
	{
		id: 11,
		name: "Литература",
	},
	{
		id: 12,
		name: "Математика",
	},
	{
		id: 13,
		name: "Немецкий язык",
	},
	{
		id: 14,
		name: "ОБЖ",
	},
	{
		id: 15,
		name: "Обществознание",
	},
	{
		id: 16,
		name: "Право",
	},
	{
		id: 17,
		name: "Русский язык",
	},
	{
		id: 18,
		name: "Технология",
	},
	{
		id: 19,
		name: "Физика",
	},
	{
		id: 20,
		name: "Физическая культура",
	},
	{
		id: 21,
		name: "Французский язык",
	},
	{
		id: 22,
		name: "Химия",
	},
	{
		id: 23,
		name: "Экология",
	},
	{
		id: 24,
		name: "Экономика",
	},
];

export const years = [
	{
		id: 2019,
		name: "2019/20",
	},
	{
		id: 2018,
		name: "2018/19",
	},
	{
		id: 2017,
		name: "2017/18",
	},
	{
		id: 2016,
		name: "2016/17",
	},
	{
		id: 2015,
		name: "2015/16",
	},
];

export const steps = [
	{
		id: 4,
		name: "Заключительный этап",
	},
	{
		id: 3,
		name: "Региональный этап",
	},
	{
		id: 2,
		name: "Муниципальный этап",
	},
	{
		id: 1,
		name: "Школьный этап",
	},
];

export const participants = [
	{
		id: 1,
		name: "Участники",
	},
	{
		id: 2,
		name: "Призеры",
	},
	{
		id: 3,
		name: "Победители",
	},
];

export const schoolClasses = [4, 5, 6, 7, 8, 9, 10, 11];

export const internationalCompetitions = [
	{
		name: "Международная физическая олимпиада школьников",
		id: "IPhO",
	},
	{
		name: "Международная математическая олимпиада",
		id: "IMO",
	},
	{
		name: "Международная биологическая олимпиада",
		id: "IBO",
	},
	{
		name: "Международная химическая олимпиада",
		id: "IChO",
	},
	{
		name: "Международная географическая олимпиада",
		id: "iGeo",
	},
	{
		name: "Международная олимпиада по астрономии и астрофизике",
		id: "IOAA",
	},
	{
		name: "Международная олимпиада по информатике",
		id: "IOI",
	},
	{
		name: "Международная естественно-научная олимпиада",
		id: "IJSO",
	},
];

export const Awards = [
	{
		name: "Золото",
		id: 1,
	},
	{
		name: "Серебро",
		id: 2,
	},
	{
		name: "Бронза",
		id: 3,
	},
]

export const news = [
	{
		id: 1,
		url: "/news?id=1",
		name: "Прием заявок на включение в Перечень олимпиад школьников на 2019/20 учебный год",
		date: "25 сентября 2019",
		teaser: "В соответствии с пунктами 8 и 9 Порядка проведения олимпиад школьников, утвержденного приказом Министерства образования и науки Российской Федерации от 4 апреля 2014 г. № 267 (в редакции приказа Минобрнауки России № 1563 от 10 декабря 2014 г.), секретариат Российского совета олимпиад школьников объявляет о начале приема заявок от организаций и учреждений, занимающихся проведением интеллектуальных соревнований школьников, на включение в Перечень олимпиад школьников на 2019/20 учебный год.",
		html:
			"<p>В соответствии с пунктами 8 и 9 <a href=\"https://rg.ru/2014/07/04/provedenie-dok.html\">Порядка проведения олимпиад школьников</a>, утвержденного приказом Министерства образования и науки Российской Федерации от 4 апреля 2014 г. № 267 (в редакции <a href=\"http://publication.pravo.gov.ru/Document/View/0001201501200035\">приказа Минобрнауки России № 1563</a> от 10 декабря 2014 г.), секретариат Российского совета олимпиад школьников объявляет о начале приема заявок от организаций и учреждений, занимающихся проведением интеллектуальных соревнований школьников, на включение в Перечень олимпиад школьников на 2019/20 учебный год.</p><br>\n" +
			"<p><strong>Электронные формы заявок</strong> подаются через личный кабинет организатора на портале РСОШ <a href=\"http://rsr-olymp.ru/\">rsr-olymp.ru</a> <strong>до 23:59 15 мая 2019 года.</strong></p><br>\n" +
			"<p><strong>Печатные формы заявок</strong>, распечатанные с портала РСОШ <a href=\"http://rsr-olymp.ru/\">rsr-olymp.ru</a>, направляются через операторов почтовой связи по адресу: 119991, г. Москва, ул. Ленинские горы, д. 1, стр. 51 (первый учебный корпус), 4 этаж, комн. 445, секретариат РСОШ.</p><br>\n" +
			"<p>К рассмотрению не принимаются заявки, отправленные после <strong>15 мая 2019 года</strong> (по почтовому штемпелю), а также оформленные с нарушением установленного порядка.</p><br>\n" +
			"<p>Подробнее о подаче заявок и ответы на <a href=\"http://rsr-olymp.ru/org#faq-apply\">часто задаваемые вопросы</a> - в разделе <a href=\"http://rsr-olymp.ru/org\">\"Организаторам\"</a>. Справки по вопросам оформления и загрузки заявок на включение в Перечень олимпиад школьников можно получить по e-mail: <a href=\"mailto:\n"
	},
	{
		id: 2,
		url: "/news?id=2",
		name: "О внесении сведений о победителях и призерах олимпиад школьников в ФИС ГИА и приема в 2018 году",
		date: "1 сентября 2019",
		teaser: "Процедура внесения сведений о лицах, являющихся победителями и призерами олимпиад школьников, в ФИС ГИА и приема не изменилась по сравнению с 2017 годом. ",
		html:
			"<p>Процедура внесения сведений о лицах, являющихся победителями и призерами олимпиад школьников, в ФИС ГИА и приема не изменилась по сравнению с 2017 годом. С нею можно ознакомиться в <a href=\"http://rsr-olymp.ru/upload/files/15-16/reglament-FCT-RSOSH-3.03.16.pdf\">Регламенте</a>.<br>\n" +
			"Для получения доступа к системе для внесения сведений о победителях и призерах руководители организаций, являющихся Организаторами (в том числе Организаторами олимпиад прошлых лет):<br>\n" +
			"- назначают лиц со стороны Организатора и/или Соорганизатора, ответственных за внесение сведений;<br>\n" +
			" обеспечивают направление в службу технической и консультационной поддержки ФИС ГИА и Приема по электронной почте \n"
	},
];

import React from "react";

export function Pdf({ className }: { className: string }) {
	return (
		<svg className={className} x="0px" y="0px" viewBox="0 0 420.2 462.6">
			<rect x="27.7" y="201.9" fill="#FFFFFF" width="310" height="156" />
			<g>
				<path
					fill="#BCA93F"
					d="M415.2,90.2l-0.4-0.4c0,0,0,0,0,0L330.3,5.3h0l-0.4-0.4C326.7,1.7,322.5,0,318,0H72.6
		C58.7,0,47.4,11.3,47.4,25.2v136.7h16.8V25.2c0-4.6,3.8-8.4,8.4-8.4h240.5v65c0,13.9,11.3,25.2,25.2,25.2h65v330.4
		c0,4.6-3.8,8.4-8.4,8.4H72.6c-4.6,0-8.4-3.8-8.4-8.4V399H47.4v38.5c0,13.9,11.3,25.2,25.2,25.2H395c13.9,0,25.2-11.3,25.2-25.2
		V102.1C420.2,97.6,418.4,93.4,415.2,90.2z"
				/>
				<g>
					<path
						fill="#C6161D"
						d="M102,249.1c-2.8-2.3-6.7-3.5-11.7-3.5H70.4v31.1h19.9c5,0,8.9-1.3,11.7-3.8c2.8-2.5,4.2-6.5,4.2-12
			C106.2,255.3,104.8,251.4,102,249.1z"
					/>
					<path
						fill="#C6161D"
						d="M184,245.6h-20.4v68.9H184c10.4,0,17.7-5.1,21.8-15.4c2.2-5.6,3.4-12.3,3.4-20.1c0-10.7-1.7-19-5.1-24.7
			C200.7,248.5,194,245.6,184,245.6z"
					/>
					<path
						fill="#C6161D"
						d="M349.7,183.8H11.9c-6.6,0-11.9,5.6-11.9,12.6v171.2c0,7,5.3,12.6,11.9,12.6h337.8c6.6,0,11.9-5.6,11.9-12.6
			V196.4C361.7,189.5,356.3,183.8,349.7,183.8z M118.9,287.1c-6.2,5.2-15,7.7-26.5,7.7h-22v38H48.5V227.2h45.3
			c10.5,0,18.8,2.7,25,8.2c6.2,5.4,9.3,13.9,9.3,25.3C128.2,273.1,125.1,282,118.9,287.1z M223.1,313.1
			c-7.6,13.1-19.4,19.7-35.4,19.7h-45.5V227.2h45.5c6.5,0.1,12,0.9,16.3,2.3c7.4,2.4,13.4,6.9,18,13.4c3.7,5.3,6.2,10.9,7.5,17
			c1.3,6.1,2,11.9,2,17.5C231.6,291.5,228.7,303.4,223.1,313.1z M319.7,245.9h-53.4v24.3h46.8v18.3h-46.8v44.3h-21.9V227.4h75.3
			V245.9z"
					/>
				</g>
			</g>
		</svg>
	);
}

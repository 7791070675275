import React from "react";
import * as d3 from "d3";
import cn from "classnames";
import { schoolSubjects } from "../../data";

interface DataItem {
	value: number;
	name: string;
}

interface Props {
	data: DataItem[];
	className?: string;
	tooltipClassName?: string;
}

const labelsMap = new Map(schoolSubjects.map(x => [x.id, x.name]));

export class DispersionChart extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		this.tooltip = d3
			.select("body")
			.append("div")
			.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
			.style("opacity", 0);

		const data = this.props.data;
		this.createChart(data);
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createChart(this.props.data);
	}

	private createChart(data: DataItem[]) {
		const avg = data.reduce((aggregate, current) => aggregate + current.value, 0) / schoolSubjects.length;

		const node = this.svgRef.current;
		const svg = d3.select(node);

		const tooltip = this.tooltip;
		const width = 1000;
		const height = 500;

		const x = d3
			.scaleLinear()
			// @ts-ignore
			.domain([0, d3.max(schoolSubjects, y => y.id)])
			.range([0, width]);

		svg.append("g")
			.attr("transform", "translate(0," + height + ")")
			.attr("class", "x-axis")
			.call(
				d3
					.axisBottom(x)
					.ticks(labelsMap.size)
					.tickSize(-height)
					// @ts-ignore
					.tickFormat((d: number) => labelsMap.get(d) || ""),
			)
			.selectAll("text")
			.attr("y", 0)
			.attr("x", 9)
			.attr("dy", "-10")
			.attr("dx", "-30")
			.attr("transform", "rotate(-65)")
			.style("text-anchor", "end");

		svg.selectAll(".tick")
			.append("line")
			.attr("class", "underline")
			.attr("y2", 120)

			.attr("transform", "rotate(25)")
			.attr("stroke", "currentColor");

		const maxY = d3.max(data, y => y.value);
		const y = d3
			.scaleLinear()
			// @ts-ignore
			.domain([0, maxY])
			.range([height, 0]);

		svg.append("g")
			.attr("class", "y-axis")
			.call(
				d3
					.axisLeft(y)
					.ticks(7)
					.tickSize(-width),
			);

		const g = svg.append("g");

		const dots = g
			.selectAll("dot")
			.data(data)
			.enter()
			.append("circle")
			.attr("class", "dot")
			.attr("cx", function(d) {
				// @ts-ignore
				return x(d.id);
			})
			.attr("cy", function(d) {
				return y(d.value);
			})
			.attr("r", 6)
			.style("fill", d => (d.value < avg ? "#ed4d25" : "#6ecacd"));

		if (tooltip) {
			dots.on("mouseover", (e: any) => {
				tooltip
					.transition()
					.duration(200)
					.style("opacity", 1);
				tooltip
					.html(
						`<div class="chart-tooltip__header">${e.name}</div>
							<strong>${e.value.toFixed(2)} %</strong> - Относительно средней доли предметов
						`,
					)
					.style("left", d3.event.pageX + "px")
					.style("top", d3.event.pageY - 28 + "px");
			}).on("mouseout", () => {
				tooltip
					.transition()
					.duration(500)
					.style("opacity", 0);
			});
		}

		g.append("line")
			.attr("x1", 0)
			.attr("x2", x(d3.max(schoolSubjects, y => y.id)))
			.attr("y1", y(avg))
			.attr("y2", y(avg))
			.attr("stroke", "#ac9c42")
			.attr("stroke-width", 2);

		svg.selectAll(".y-axis .tick text").attr("dx", `-10`);

		svg.append("text")
			.attr("class", "dimension")
			.attr("x", 0)
			.attr("y", -20)
			.text(() => "%");
	}

	public render() {
		return (
			<svg
				style={{ overflow: "visible" }}
				ref={this.svgRef}
				width={1000}
				height={500}
				className={cn("chart chart--dispersion", this.props.className)}
			/>
		);
	}
}

import React from "react";
import { LoginService } from "../../services/login-service";

export function LoginForm({ submitLoginText = "Войти" }: { submitLoginText?: string }) {
	const [login, setLogin] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [error, setError] = React.useState("");

	function handleChangeLogin(e: React.FormEvent<HTMLInputElement>) {
		setError("");
		setLogin(e.currentTarget.value);
	}

	function handleChangePassword(e: React.FormEvent<HTMLInputElement>) {
		setError("");
		setPassword(e.currentTarget.value);
	}

	function handleLogin() {
		if (LoginService.login(login, password)) {
			// @ts-ignore
			document.location = "/";
		} else {
			setError("Неправильный логин или пароль");
		}
	}

	return (
		<div className="login-form">
			{error && <div className="login-error">{error}</div>}

			<div>
				<input type="text" onChange={handleChangeLogin} value={login} placeholder="Логин" />
			</div>
			<div>
				<input type="password" onChange={handleChangePassword} value={password} placeholder="Пароль" />
			</div>

			<div className="login-nav">
				<button type="button" onClick={handleLogin}>
					{submitLoginText}
				</button>
			</div>
		</div>
	);
}

import React from "react";

export function Doc({ className }: { className: string }) {
	return (
		<svg version="1.1" className={className} x="0px" y="0px" viewBox="0 0 420.2 462.6">
			<rect x="15.1" y="207.8" fill="#FFFFFF" width="330" height="150" />
			<g>
				<path
					fill="#BCA93F"
					d="M415.2,90.2l-0.4-0.4c0,0,0,0,0,0L330.3,5.3h0l-0.4-0.4C326.7,1.7,322.5,0,318,0H72.6
		C58.7,0,47.4,11.3,47.4,25.2v136.7h16.8V25.2c0-4.6,3.8-8.4,8.4-8.4h240.5v65c0,13.9,11.3,25.2,25.2,25.2h65v330.4
		c0,4.6-3.8,8.4-8.4,8.4H72.6c-4.6,0-8.4-3.8-8.4-8.4V399H47.4v38.5c0,13.9,11.3,25.2,25.2,25.2H395c13.9,0,25.2-11.3,25.2-25.2
		V102.1C420.2,97.6,418.4,93.4,415.2,90.2z"
				/>
				<g>
					<path
						fill="#0088C7"
						d="M70.7,245.6H50.3v68.9h20.4c10.4,0,17.7-5.1,21.8-15.4c2.2-5.6,3.4-12.3,3.4-20.1c0-10.7-1.7-19-5.1-24.7
			C87.4,248.5,80.7,245.6,70.7,245.6z"
					/>
					<path
						fill="#0088C7"
						d="M177.9,242.7c-8.7,0-15.7,3.2-20.9,9.7c-5.2,6.4-7.8,15.6-7.8,27.5c0,11.9,2.6,21.1,7.8,27.5
			c5.2,6.4,12.2,9.7,20.9,9.7s15.7-3.2,20.8-9.7s7.7-15.6,7.7-27.5c0-11.8-2.6-21-7.7-27.5C193.5,245.9,186.6,242.7,177.9,242.7z"
					/>
					<path
						fill="#0088C7"
						d="M349.7,183.8H11.9c-6.6,0-11.9,5.6-11.9,12.6v171.2c0,7,5.3,12.6,11.9,12.6h337.8c6.6,0,11.9-5.6,11.9-12.6
			V196.4C361.7,189.5,356.3,183.8,349.7,183.8z M109.8,313.1c-7.6,13.1-19.4,19.7-35.4,19.7H28.9V227.2h45.5
			c6.5,0.1,12,0.9,16.3,2.3c7.4,2.4,13.4,6.9,18,13.4c3.7,5.3,6.2,10.9,7.5,17c1.3,6.1,2,11.9,2,17.5
			C118.3,291.5,115.5,303.4,109.8,313.1z M212.4,323.4c-8,8.2-19.5,12.3-34.6,12.3c-15.1,0-26.6-4.1-34.6-12.3
			c-10.7-10.1-16-24.6-16-43.5c0-19.3,5.3-33.9,16-43.5c8-8.2,19.5-12.3,34.6-12.3c15.1,0,26.6,4.1,34.6,12.3
			c10.6,9.7,16,24.2,16,43.5C228.4,298.8,223.1,313.4,212.4,323.4z M266.7,307.9c4.8,5.9,10.9,8.8,18.3,8.8c7.6,0,13.3-2.5,17.3-7.6
			c2.2-2.7,4-6.8,5.5-12.2h21.9c-1.9,11.5-6.7,20.9-14.5,28.1c-7.8,7.2-17.7,10.8-29.9,10.8c-15,0-26.8-4.9-35.4-14.6
			c-8.6-9.8-12.9-23.2-12.9-40.3c0-18.4,4.9-32.6,14.7-42.6c8.5-8.7,19.3-13,32.5-13c17.6,0,30.4,5.8,38.5,17.5
			c4.5,6.5,6.9,13.1,7.2,19.7h-22.1c-1.4-5.1-3.2-8.9-5.4-11.5c-3.9-4.6-9.8-6.9-17.5-6.9c-7.9,0-14.1,3.2-18.6,9.7
			c-4.5,6.5-6.8,15.6-6.8,27.5C259.6,293.1,262,302,266.7,307.9z"
					/>
				</g>
			</g>
		</svg>
	);
}

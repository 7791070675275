import React from "react";
import { TotalActualParticipants } from "./slides/total-actual-participants";
import { ForecastParticipants } from "./slides/forecast-participants";
import { ActualParticipants } from "./slides/actual-participants";
import { ParticipantsRatio } from "./slides/participants-ratio";

export class CurrentAnalyticsInformation extends React.Component {
	public render() {
		return (
			<>
				<div className="slide slide--dark">
					<div className="slide__top">01</div>
					<div className="slide-header">Общее фактическое число участий по этапам ВсОШ</div>
					<TotalActualParticipants />
				</div>

				<div className="slide slide--indigo">
					<div className="slide__top">02</div>
					<div className="slide-header">Прогноз числа участников заключительного этапа ВсОШ 2019/20</div>
					<ForecastParticipants />
				</div>

				<div className="slide slide--dark">
					<div className="slide__top">03</div>
					<div className="slide-header">Фактическое число участий по этапам ВсОШ</div>
					<ActualParticipants />
				</div>

				<div className="slide">
					<div className="slide__top">04</div>
					<div className="slide-header">Соотношение участников, призеров и победителей по предметам</div>
					<ParticipantsRatio />
				</div>
			</>
		);
	}
}

import React from "react";
import cn from "classnames";

const items = [
	"А",
	"Б",
	"В",
	"Г",
	"Д",
	"Е",
	"Ж",
	"З",
	"И",
	"Й",
	"К",
	"Л",
	"М",
	"Н",
	"О",
	"П",
	"Р",
	"С",
	"Т",
	"У",
	"Ф",
	"Х",
	"Ц",
	"Ч",
	"Ш",
	"Щ",
	"Ы",
	"Э",
	"Ю",
	"Я",
];

export function AlphabetFilter() {
	const [active, setActive] = React.useState("");

	function handleClick(s: string) {
		return function() {
			if (active === s) {
				setActive("");
			} else {
				setActive(s);
			}
		};
	}

	return (
		<div className="alphabet-filter">
			{items.map(x => (
				<button
					key={x}
					onClick={handleClick(x)}
					className={cn("alphabet-filter__item", { "alphabet-filter__item--selected": active === x })}
					type="button"
				>
					{x}
				</button>
			))}
		</div>
	);
}

import React from "react";

export function Xls({ className }: { className: string }) {
	return (
		<svg className={className} x="0px" y="0px" viewBox="0 0 420.2 462.6">
			<rect x="15.1" y="201.9" fill="#FFFFFF" width="314.8" height="156" />
			<g>
				<path
					fill="#BCA93F"
					d="M415.2,90.2l-0.4-0.4c0,0,0,0,0,0L330.3,5.3h0l-0.4-0.4C326.7,1.7,322.5,0,318,0H72.6
		C58.7,0,47.4,11.3,47.4,25.2v136.7h16.8V25.2c0-4.6,3.8-8.4,8.4-8.4h240.5v65c0,13.9,11.3,25.2,25.2,25.2h65v330.4
		c0,4.6-3.8,8.4-8.4,8.4H72.6c-4.6,0-8.4-3.8-8.4-8.4V399H47.4v38.5c0,13.9,11.3,25.2,25.2,25.2H395c13.9,0,25.2-11.3,25.2-25.2
		V102.1C420.2,97.6,418.4,93.4,415.2,90.2z"
				/>
				<path
					fill="#008B51"
					d="M349.7,183.8H11.9c-6.6,0-11.9,5.6-11.9,12.6v171.2c0,7,5.3,12.6,11.9,12.6h337.8c6.6,0,11.9-5.6,11.9-12.6
		V196.4C361.7,189.5,356.3,183.8,349.7,183.8z M108.6,332.8l-20.1-36l-21.2,36H41.7l34-53.8l-32.3-51.8h26.4l18.8,34.4l19.3-34.4
		h25.5l-32.3,50.9l34.3,54.6H108.6z M223.1,332.8h-74.8V227.2h22.1v86.6h52.7V332.8z M309.1,326c-7.5,6.4-18,9.6-31.6,9.6
		c-13.9,0-24.8-3.1-32.8-9.4c-8-6.3-11.9-14.9-11.9-25.9h21.1c0.7,4.8,2,8.4,4,10.8c3.7,4.3,9.9,6.5,18.8,6.5
		c5.3,0,9.6-0.6,12.9-1.7c6.3-2.2,9.4-6.3,9.4-12.2c0-3.5-1.5-6.2-4.6-8.1c-3.1-1.9-8-3.5-14.7-4.9l-11.4-2.5
		c-11.2-2.5-19-5.2-23.2-8.1c-7.1-4.9-10.7-12.5-10.7-22.8c0-9.5,3.5-17.3,10.4-23.6c7-6.3,17.2-9.4,30.7-9.4
		c11.3,0,20.9,2.9,28.8,8.8c8,5.9,12.1,14.5,12.5,25.7h-21.2c-0.4-6.3-3.2-10.9-8.5-13.5c-3.5-1.8-7.9-2.7-13.1-2.7
		c-5.8,0-10.5,1.1-14,3.4c-3.5,2.3-5.2,5.5-5.2,9.6c0,3.8,1.7,6.6,5.1,8.5c2.2,1.2,6.9,2.7,14,4.4l18.5,4.4
		c8.1,1.9,14.2,4.5,18.3,7.7c6.3,5,9.4,12.2,9.4,21.6C320.3,311.6,316.6,319.6,309.1,326z"
				/>
			</g>
		</svg>
	);
}

import React from "react";
import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../../types";

const currentYear = 2019;

export function AnalyticsAnnotation({ years }: { years: number[] }) {
	if (years.length === 1 && years[0] === currentYear) {
		return (
			<div className="analytics-annotation">
				<div>
					Идет <strong>региональный</strong> этап олимпиады.
				</div>
			</div>
		);
	}

	return (
		<div className="analytics-annotation">
			<div className="analytics-annotation__files">
				{years.map((x, i) => (
					<div key={`${x}_${i}`} className="analytics-annotation__file">
						<FileDownloadLink type={FileType.Pdf} text={`Статистический сборник ${x}-${x + 1}`} />
					</div>
				))}
			</div>
			<div className="analytics-annotation__summary">
				{years.map((x, i) => (
					<div key={`${x}_${i}`} className="analytics-annotation__summary-item">
						В <strong>{`${x}-${x + 1}`}</strong> учебном году во Всероссийской олимпиаде школьников приняли
						участие <strong>7 560 995</strong> человек. С учетом количества предметов, по которым каждый
						обучающийся принимал участие, фактически было зарегистрировано <strong>19 029 588</strong>{" "}
						участников.
					</div>
				))}
			</div>
		</div>
	);
}

import React from "react";
import { CategoriesPane } from "@components/categories-pane";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";
import { SearchPanel } from "@components/search-panel";
import { FileType } from "../../../types";
import { FileDownloadLink } from "@components/file-link";
import { CloseIcon } from "@icons/close-icon";

const categoryItems = [
	{ id: 1, name: "Основные документы" },
	{ id: 2, name: "Муниципальный этап" },
	{ id: 3, name: "Региональный этап" },
	{ id: 4, name: "Заключительный этап" },
	{ id: 5, name: "Международные олимпиады" },
];

const tagSet1 = [
	{
		id: 1,
		name: "Минпросвет",
	},
	{
		id: 2,
		name: "О проведении",
	},
	{
		id: 3,
		name: "Проходные баллы",
	},
	{
		id: 4,
		name: "Граничные баллы",
	},
	{
		id: 5,
		name: "Тренировочные сборы",
	},
	{
		id: 6,
		name: "Итоги",
	},
	{
		id: 7,
		name: "Приказы",
	},
	{
		id: 8,
		name: "Письма",
	},
];

const tagSet2 = [
	{
		id: 1,
		name: "ЦПМК",
	},
	{
		id: 2,
		name: "Инструкции",
	},
	{
		id: 3,
		name: "Методические рекомендации",
	},
	{
		id: 4,
		name: "Требования",
	},
	{
		id: 5,
		name: "Протоколы",
	},
	{
		id: 6,
		name: "Формы",
	},
];

const fileSet = [
	{
		id: 1,
		name: "Порядок проведения ВсОШ (приказ МОН РФ №1252 от 18.11.2013)",
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
		type: FileType.Zip,
	},
	{
		id: 2,
		name: "Изменения в Порядке проведения ВсОШ (приказ МОН РФ №1488 от 17.12.2015)",
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
		type: FileType.Rar,
	},
	{
		id: 3,
		name: "Изменения в Порядке проведения ВсОШ (приказ МОН РФ №249 от 17.03.2015)",
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
		type: FileType.Pdf,
	},
	{
		id: 4,
		name: "Положение об этапах ВсОШ в городе Москве (приказ ДОгМ №741 от 29.08.2014)",
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
		type: FileType.Xls,
	},
	{
		id: 5,
		name:
			"Количество баллов для участников муниципального этапа по первой группе предметов (приказ №380 от 16.10.2019)",
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
		type: FileType.Doc,
	},
	{
		id: 6,
		name:
			"Порядок проведения школьного, муниципального и регионального этапов всероссийской олимпиады в Москве в 2019/20 учебном году (приказ ДОгМ №310 от 28.08.2019)",
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
	{
		id: 7,
		name:
			'Приказ Минобрнауки России №1679 "О проведении заключительного этапа всероссийской олимпиады школьников в 2016/17 учебном году" от 29 декабря 2016 года.',
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
	{
		id: 8,
		name: "Приказ Минобрнауки России №1435 О внесении изменений в Порядок проведения ВсОШ от 17 ноября 2016 года.",
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
	{
		id: 9,
		name:
			"О направлении Временных регламентов проведения туров регионального этапа всероссийского олимпиады школьников в 2016-17 учебном году N08-2660 07 декабря 2016 года.",
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
	{
		id: 10,
		name:
			"О внесении сведений о результатах регионального этапа всероссийской олимпиады школьников 2016/17 учебного года в электронную систему от 01.12.2016 года",
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
	{
		id: 11,
		name:
			" Приказ Минобрнауки России №1440 Об установлении сроков проведения регионального этапа всероссийской олимпиады школьников по общеобразовательным предметам в 2016/17 учебном году от 17.11.2016 года",
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
	{
		id: 12,
		name: " Результаты заключительного этапа ВсОШ 2016 года",
		type: FileType.Pdf,
		href:
			"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
	},
];

function generateFileSet(tagSet: any) {
	const files: any[] = [];

	for (let i = 0; i < 5; i++) {
		const fileIndex = Math.floor(11 * Math.random());

		if (!files.find(x => x.id === fileIndex + 1)) {
			files.push({
				...fileSet[fileIndex],
				tags: [Math.floor(tagSet.length * Math.random()) + 1, Math.floor(tagSet.length * Math.random()) + 1],
			});
		}
	}

	return files;
}

export function Documents() {
	const [categoryId, setCategory] = React.useState(1);
	const [tag1Id, setTag1] = React.useState(0);
	const [tag2Id, setTag2] = React.useState(0);
	const [fileSet1, setFileSet1] = React.useState(generateFileSet(tagSet1));
	const [fileSet2, setFileSet2] = React.useState(generateFileSet(tagSet2));

	function handleSelect(categoryId: number) {
		setCategory(categoryId);

		setFileSet1(generateFileSet(tagSet1));
		setFileSet2(generateFileSet(tagSet2));
	}

	function handleTag1Select(tagId: number) {
		return function() {
			setTag1(tag1Id === tagId ? 0 : tagId);
		};
	}

	function handleTag2Select(tagId: number) {
		return function() {
			setTag2(tag2Id === tagId ? 0 : tagId);
		};
	}

	function handleFilterChange() {
		setFileSet1(generateFileSet(tagSet1));
		setFileSet2(generateFileSet(tagSet2));
	}

	return (
		<div className="documents-content">
			<CategoriesPane items={categoryItems} onSelect={handleSelect} selected={categoryId} />

			<div className="filter">
				<FilterToolbarSet filterSet={FilterSet.Set7} onChange={handleFilterChange} />
				<SearchPanel />
			</div>

			<div className="document-list">
				<div className="document-list__content">
					<div className="document-list__header">Нормативно-правовые акты</div>
					<div className="document-list__tags">
						{tagSet1.map(tag => (
							<span key={tag.id} className="document-list__tag" onClick={handleTag1Select(tag.id)}>
								{tag.name}
								{tag.id === tag1Id && <CloseIcon className="document-list__tag-close-icon" />}
							</span>
						))}
					</div>
					<div className="document-list__items">
						{fileSet1
							.filter(f => tag1Id === 0 || f.tags.includes(tag1Id))
							.map((f, index) => (
								<FileDownloadLink
									key={`${f.id}_${index}`}
									type={f.type}
									text={f.name}
									url={f.href}
									className="document-list__item document-list-item"
								/>
							))}
					</div>
				</div>
			</div>

			<div className="document-list document-list--dark">
				<div className="document-list__content">
					<div className="document-list__header">Иные документы</div>
					<div className="document-list__tags">
						{tagSet2.map(tag => (
							<span key={tag.id} className="document-list__tag" onClick={handleTag2Select(tag.id)}>
								{tag.name}
								{tag.id === tag2Id && <CloseIcon className="document-list__tag-close-icon" />}
							</span>
						))}
					</div>
					<div className="document-list__items">
						{fileSet2
							.filter(f => tag2Id === 0 || f.tags.includes(tag2Id))
							.map((f, index) => (
								<FileDownloadLink
									key={`${f.id}_${index}`}
									type={f.type}
									text={f.name}
									url={f.href}
									className="document-list__item document-list-item"
								/>
							))}
					</div>
				</div>
			</div>
		</div>
	);
}

import React from "react";

export function Results() {
	return (
		<table className="table-light international-results">
			<tbody>
				<tr>
					<th>Название олимпиады</th>
					<th>Фамилия</th>
					<th>Регион</th>
					<th>Результат</th>
				</tr>
				<tr>
					<td rowSpan={5} className="international-results__title">
						Международная физическая олимпиада школьников (The International Physics Olympiad (IPhO)
					</td>
					<td>Бобков Григорий Александрович</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Малиновский Владимир Валерьевич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Панферов Андрей Денисович</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Судаков Елисей</td>
					<td>Вологодская область</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Шишкин Алексей Юрьевич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td rowSpan={6}>
						Международная математическая олимпиада (The International Mathematical Olympiad (IMO)
					</td>
					<td>Гайдай-Турлов Иван Павлович</td>
					<td>Москва</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Ковалев Тимофей Сергеевич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Кулишов Валерий Сергеевич</td>
					<td>Москва</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Львов Алексей</td>
					<td>Новосибирская область</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Петров Владимир</td>
					<td>Санкт-Петербург</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Смирнов Олег Сергеевич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td rowSpan={4}>Международная биологическая олимпиада (The International Biology Olympiad (IBO)</td>
					<td>Девяткин Дмитрий</td>
					<td>Кемеровская область</td>
					<td>бронза</td>
				</tr>
				<tr>
					<td>Колюпанова Наталия Михайловна</td>
					<td>Москва</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Лисицкий Денис</td>
					<td>Республика Татарстан</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Пигиданов Артемий</td>
					<td>Республика Удмуртия</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td rowSpan={4}>Международная химическая олимпиада (The International Chemistry Olympiad (IChO)</td>
					<td>Бардонов Даниил Аркадьевич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Матвеев Михаил</td>
					<td>Вологодская область</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Чернов Никита Олегович</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Шишкин Алексей Юрьевич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td rowSpan={4}>
						Международная географическая олимпиада (The International Geography Olympiad (iGeo)
					</td>
					<td>Антонова Лада Сергеевна</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Балабан Михаил</td>
					<td>Саратовская область</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Мечникова Катарина Томасовна</td>
					<td>Москва</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Яковлев Андрей Евгеньевич</td>
					<td>Москва</td>
					<td>-</td>
				</tr>
				<tr>
					<td rowSpan={5}>
						Международная олимпиада по астрономии и астрофизике (The International Olympiad on Astronomy and
						Astrophysics (IOAA) (основная команда)
					</td>
					<td>Архипов Павел</td>
					<td>Санкт-Петербург</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Бойцов Евгений</td>
					<td>Московская область</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Булыгин Игорь</td>
					<td>Санкт-Петербург</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Волкова Александра Андреевна</td>
					<td>Москва</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Царёв Дмитрий Вячеславович</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td rowSpan={5}>
						Международная олимпиада по астрономии и астрофизике (The International Olympiad on Astronomy and
						Astrophysics (IOAA) (гостевая команда)
					</td>
					<td>Гордеев Артём &nbsp;</td>
					<td>Санкт-Петербург</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Кадыков Алексей</td>
					<td>Республика Мордовия</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Казимов Сулейман</td>
					<td>Республика Мордовия</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Сапаев Руслан</td>
					<td>Москва</td>
					<td>серебро</td>
				</tr>
				<tr>
					<td>Харичкин Иван</td>
					<td>Санкт-Петербург</td>
					<td>золото</td>
				</tr>
				<tr>
					<td rowSpan={4}>
						Международная олимпиада по информатике (The International Olympiad in Informatics (IOI)
					</td>
					<td>Лифарь Егор Игоревич</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Романов Владимир Олегович</td>
					<td>Москва</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Гайнуллин Ильдар</td>
					<td>Республика Татарстан</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>Морозов Александр</td>
					<td>Санкт-Петербург</td>
					<td>золото</td>
				</tr>
				<tr>
					<td>
						Международная естественно-научная олимпиада (The International Junior Science Olympiad (IJSO)
					</td>
					<td colSpan={3}>&nbsp;</td>
				</tr>
			</tbody>
		</table>
	);
}

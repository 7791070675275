import React from "react";

export function Play({ className }: { className: string }) {
	return (
		<svg x="0px" y="0px" viewBox="0 0 32.5 32.5" className={className}>
			<g>
				<polygon points="12.8,8.8 12.8,23.8 23.1,16.3 " />
				<g>
					<path
						d="M16.3,32.5C7.3,32.5,0,25.2,0,16.3C0,7.3,7.3,0,16.3,0c9,0,16.3,7.3,16.3,16.3C32.5,25.2,25.2,32.5,16.3,32.5
    			z M16.3,1C7.8,1,1,7.8,1,16.3c0,8.4,6.8,15.3,15.3,15.3c8.4,0,15.3-6.8,15.3-15.3C31.5,7.8,24.7,1,16.3,1z"
					/>
				</g>
			</g>
		</svg>
	);
}

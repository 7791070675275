import React from "react";
import cn from "classnames";
import { federalDistricts, federalSubjects, schoolSubjects, steps, years } from "../../../../data";
import { FilterToolbar } from "@components/filter-toolbar";
import { AreaChart1 } from "@components/figures/area-chart1";

const dd = [{ id: 1, name: "Победители" }, { id: 2, name: "Призеры" }, { id: 3, name: "Участники" }];

function generateData() {
	return dd.map(x => ({
		id: x.id,
		values: years.map(year => ({
			valueId: year.id,
			valueTitle: year.name,
			value: x.id * 1000 + 500 * Math.random(),
		})),
	}));
}

const colorsMap: Map<number, string> = new Map([[3, "#0a71b7"], [2, "#6ccacd"], [1, "#bcaf45"]]);

const opacity = "33";

const filters = [
	{
		id: "federal-district",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federal-subject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
	{
		id: "step",
		name: "Этап олимпиады",
		items: [{ id: 0, name: "Этап олимпиады" }].concat(steps),
		value: 0,
	},
	{
		id: "school-subject",
		name: "Предмет",
		items: [{ id: 0, name: "Предмет" }].concat(schoolSubjects),
		value: 0,
	},
];

function getTooltip(item: { valueId: number; valueTitle: string; value: number }) {
	return `
		<div class="chart-tooltip__header">${item.valueTitle}</div>
		<strong>${item.value.toFixed(0)} чел.</strong> количество участников
	`;
}

export function Slide3() {
	const [selection, setSelected] = React.useState(new Set([1, 2, 3]));

	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	function toggleSelection(id: number) {
		return function() {
			if (selection.has(id)) {
				if (selection.size > 1) {
					setSelected(new Set(Array.from(selection).filter(x => x !== id)));
				}
			} else {
				setSelected(new Set(Array.from(selection).concat([id])));
			}
		};
	}

	const passData = data.filter(d => selection.has(d.id));

	return (
		<div className="analytics-period__slide-3">
			<FilterToolbar filters={filters} onChange={handleFilterChange} />
			<div className="slide-3-chart">
				<div className="slide-3-chart__bar">
					{dd.map(x => (
						<div
							key={x.id}
							onClick={toggleSelection(x.id)}
							className={cn("slide-3-chart__bar-button", `slide-3-chart__bar-button--${x.id}`, {
								[`slide-3-chart__bar-button--${x.id}-selected`]: selection.has(x.id),
							})}
						>
							{x.name}
						</div>
					))}
				</div>
				<AreaChart1
					data={passData}
					colors={passData.map(x => colorsMap.get(x.id)!)}
					opacity={opacity}
					getTooltip={getTooltip}
					tooltipClassName="participants-forecast-chart-tooltip"
				/>
			</div>
		</div>
	);
}

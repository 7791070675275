import { AreaChart1 } from "@components/figures/area-chart1";
import React from "react";
import { years, steps, federalDistricts, federalSubjects } from "../../../../data";
import cn from "classnames";
import { FilterToolbar } from "@components/filter-toolbar";

const dd = [{ id: 1, name: "Победители" }, { id: 2, name: "Призеры" }, { id: 3, name: "Участники" }];

function generateData() {
	const data = dd.map(x => ({
		id: x.id,
		values: years.map(year => ({
			valueId: year.id,
			valueTitle: year.name,
			value: x.id * 1000 + 500 * Math.random(),
		})),
	}));

	return data;
}

const colors = ["#f9a31a", "#6acacd", "#0a71b7"];
const opacity = "33";

const filters = [
	{
		id: "federal-district",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federal-subject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
];

export function Slide4() {
	const [selection, setSelected] = React.useState(1);
	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	function toggleSelection(id: number) {
		return function() {
			setSelected(id);
			setData(generateData());
		};
	}

	function getTooltip(item: { valueId: number; valueTitle: string; value: number }) {
		const res = data.map(x => ({
			...x.values.find(x => x.valueId === item.valueId)!,
			id: x.id,
		}));
		const winnersPerc =
			(res.find(x => x.id === 1)!.value / res.reduce((aggregate, current) => aggregate + current.value, 0)) * 100;

		return `
		<div class="chart-tooltip__header">${item.valueTitle}</div>
		<strong>${res.find(x => x.id === 3)!.value.toFixed(0)} чел.</strong> участников<br/>
		<strong>${res.find(x => x.id === 2)!.value.toFixed(0)} чел.</strong> призеров<br/>
		<strong>${res.find(x => x.id === 1)!.value.toFixed(0)} чел. (${winnersPerc.toFixed(2)} %)</strong> победителей<br/>
	`;
	}
	steps.sort((a, b) => a.id - b.id);

	return (
		<div className="analytics-period__slide-4">
			<FilterToolbar filters={filters} onChange={handleFilterChange} />

			<div className="slide-4-chart">
				<div className="slide-4-chart__bar">
					{steps.map(x => (
						<div
							key={x.id}
							onClick={toggleSelection(x.id)}
							className={cn("slide-4-chart__bar-button", {
								"slide-4-chart__bar-button--selected": selection === x.id,
							})}
						>
							{x.name}
						</div>
					))}
				</div>
				<div>
					<AreaChart1
						data={data}
						colors={colors}
						opacity={opacity}
						tooltipClassName="participants-forecast-chart-tooltip"
						getTooltip={getTooltip}
					/>
					<div className="horizontal-legends">
						<div className="horizontal-legends__label legend-label">
							<div className="legend-label__dot legend-label__dot--1" />
							<div>Победители</div>
						</div>
						<div className="horizontal-legends__label legend-label">
							<div className="legend-label__dot legend-label__dot--2" />
							<div>Призеры</div>
						</div>
						<div className="horizontal-legends__label legend-label">
							<div className="legend-label__dot legend-label__dot--3" />
							<div>Участники</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import * as d3 from "d3";
import cn from "classnames";

export class AreaChart extends React.Component<{
	className?: string;
	tooltipClassName?: string;
	data: any;
	getTooltip(e: any): string;
}> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		this.tooltip = d3
			.select("body")
			.append("div")
			.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
			.style("opacity", 0);

		this.createGraph(this.props.data);
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createGraph(this.props.data);
	}

	private createGraph(data: any) {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const margin = { top: 0, right: 0, bottom: 0, left: 0 };
		const width = +svg.attr("width") - margin.left - margin.right;
		const height = +svg.attr("height") - margin.top - margin.bottom;
		const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		var color = d3
			.scaleOrdinal()
			// @ts-ignore
			.domain([0, 1])
			.range(["rgba(239, 77, 35, 0.3)", "rgba(54, 174, 175, 0.3)"]);

		var x = d3.scaleLinear().range([0, width]),
			y = d3.scaleLinear().range([height, 0]),
			z = color;

		var area = d3
			.area()
			.curve(d3.curveLinear)
			// @ts-ignore
			.x(d => x(d.subjId))
			.y0(y(0))
			// @ts-ignore
			.y1(d => y(d.value));

		// @ts-ignore
		x.domain([0, d3.max(data[0].values, d => d.subjId)]);
		//x.domain(d3.extent(data, function(d) { return d.name; }));

		// @ts-ignore
		y.domain([0, d3.max(data, c => d3.max(c.values, d => d.value))]);

		z.domain(data.map((c: any) => c.id));

		const xAxis = g
			.append("g")
			.attr("class", "x-axis")
			.attr("transform", "translate(0," + height + ")")
			.call(
				d3
					.axisBottom(x)
					.ticks(data[0].values.length)
					.tickSize(-height)
					// @ts-ignore
					.tickFormat(x => {
						const res = data[0].values.find((v: any) => v.subjId === x);
						return res ? res.subjName : "";
					}),
			);

		const yAxis = g
			.append("g")
			.attr("class", "y-axis")
			.call(d3.axisLeft(y).tickSize(-width));

		xAxis
			.selectAll(".tick")
			.append("line")
			.attr("class", "underline")
			.attr("y2", 120)
			.attr("transform", "rotate(25)")
			.attr("stroke", "currentColor");

		xAxis
			.selectAll("text")
			.attr("y", -10)
			.attr("x", 0)
			.attr("dy", 5)
			.attr("dx", -20)
			.attr("transform", "rotate(-65)")
			.style("text-anchor", "end");

		yAxis.selectAll("text").attr("dx", -10);

		const source = g
			.selectAll(".area")
			.data(data)
			.enter()
			.append("g")
			// @ts-ignore
			.attr("class", d => `area ${d.id}`);

		source
			.append("path")
			// @ts-ignore
			.attr("d", d => area(d.values))
			// @ts-ignore
			.style("fill", d => z(d.id));

		const cc = ["#f9d057", "#36aeaf"];

		source
			.append("path")
			.data([data[0].values])
			.attr("class", "edge-line")
			.attr("fill", "none")
			.attr("stroke", "#ef4d23")
			.attr("stroke-width", 2)
			// @ts-ignore
			.attr(
				"d",
				// @ts-ignore
				d3
					.line()
					// @ts-ignore
					.x(d => x(d.subjId))
					// @ts-ignore
					.y(d => y(d.value)),
			);

		source
			.append("path")
			.data([data[1].values])
			.attr("class", "edge-line")
			.attr("fill", "none")
			.attr("stroke", "#6ccacd")
			.attr("stroke-width", 2)
			// @ts-ignore
			.attr(
				"d",
				// @ts-ignore
				d3
					.line()
					// @ts-ignore
					.x(d => x(d.subjId))
					// @ts-ignore
					.y(d => y(d.value)),
			);

		const dots = source
			.selectAll(".dot")
			.data(data[1].values)
			.enter()
			.append("circle")
			.attr("r", 3)
			// @ts-ignore
			.attr("cx", d => x(d.subjId))
			// @ts-ignore
			.attr("cy", d => y(d.value))
			.attr("fill", function() {
				// @ts-ignore
				return cc[d3.select(this.parentNode).datum().id];
			});

		if (this.tooltip) {
			dots.on("mouseover", (e: any) => {
				this.tooltip
					.transition()
					.duration(200)
					.style("opacity", 1);
				this.tooltip
					.html(this.props.getTooltip(e))
					.style("left", d3.event.pageX + "px")
					.style("top", d3.event.pageY - 28 + "px");
			}).on("mouseout", () => {
				this.tooltip
					.transition()
					.duration(500)
					.style("opacity", 0);
			});
		}

		// source.selectAll(".dot")
		// 	.data(d => d.values)
		// 	.enter()
		// 	.append("circle")
		// 	.attr("r", 3)
		// 	.attr("cx", (d, i) => x(i))
		// 	.attr("cy", d => y(d))
		// 	.attr("fill", d => color(d.value));

		// const line = d3
		// 	.line()
		// 	// @ts-ignore
		// 	.x(d => x(d.id))
		// 	// @ts-ignore
		// 	.y(d => y(d.value));
		//
		// g.append("g")
		// 	.append("path")
		// 	.datum(data[0])
		// 	.attr("class", "line")
		// 	// @ts-ignore
		// 	.attr("d", line);
	}

	public render() {
		return <svg ref={this.svgRef} width={1000} height={500} className={this.props.className}></svg>;
	}
}

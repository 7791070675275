import React from "react";
import cn from "classnames";
import { DocIcon, PdfIcon, RarIcon, XlsIcon, ZipIcon } from "@icons/docs";
import { FileType } from "../types";
import { Play } from "@icons/play";


interface Props {
	url?: string;
	type: FileType;
	text: string;
	className?: string;
	target?: string;
}

function getIconComponent(type: FileType) {
	switch (type) {
		case FileType.Pdf:
			return PdfIcon;
		case FileType.Xls:
			return XlsIcon;
		case FileType.Rar:
			return RarIcon;
		case FileType.Zip:
			return ZipIcon;
		case FileType.Media:
			return Play;
		default:
			return DocIcon;
	}
}

export function FileDownloadLink({ url = "#", type, text, className, target = "_self" }: Props) {
	const IconComponent = getIconComponent(type);

	return (
		<div className={cn("file-download", className)}>
			<IconComponent className="file-download__icon" />
			<a href={url} className="file-download__link file-download-link" target={target}>
				{text}
			</a>
		</div>
	);
}

import React from "react";
import * as d3 from "d3";
import cn from "classnames";

interface DataItem {
	value: number;
	name: string;
}
//
// interface Datum {
// 	data: DataItem;
// }

interface Props {
	data: DataItem[];
	className?: string;
	tooltipClassName?: string;
}

const width = 1000;
const height = 500;
const margin = { top: 0, right: 0, bottom: 0, left: 0 };

export class BarChart extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		this.tooltip = d3
			.select("body")
			.append("div")
			.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
			.style("opacity", 0);

		this.createChart(this.props.data);
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createChart(this.props.data);
	}

	private createChart(data: DataItem[]) {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const x = d3
			.scaleBand()
			.domain(data.map(d => d.name))
			.range([margin.left, width - margin.right])
			.padding(0.1);

		const y = d3
			.scaleLinear()
			//@ts-ignore
			.domain([0, d3.max<DataItem>(data, d => d.value)])
			.nice()
			.range([height - margin.bottom, margin.top]);

		// const color = d3
		// 	.scaleLinear()
		// 	//@ts-ignore
		// 	.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
		// 	//@ts-ignore
		// 	.range(["#0a71b7"]);

		svg.append("g")
			.attr("transform", `translate(0,${height - margin.bottom})`)
			.attr("class", "x-axis")
			.call(d3.axisBottom(x).tickSize(-height))
			// .select("path")
			// .attr("stroke", "#ffffff")
			.selectAll("text")
			.attr("y", 0)
			.attr("x", 9)
			.attr("dy", "5")
			.attr("dx", "-20")
			.attr("transform", "rotate(-65)")
			.style("text-anchor", "end");

		svg.selectAll(".tick")
			.append("line")
			.attr("class", "underline")
			.attr("y2", 120)

			.attr("transform", "rotate(25) translate(15, -5)")
			.attr("stroke", "currentColor");

		svg.append("g")
			.attr("transform", `translate(${margin.left},0)`)
			.attr("class", "y-axis")
			.call(
				d3
					.axisLeft(y)
					.tickSize(-width)
					//@ts-ignore
					.tickFormat(d => d),
			);

		svg.append("g")
			.attr("class", "rects")
			.selectAll("rect")
			.data(data)
			.join("rect")
			//			.attr("fill", d => color(d.value))
			.attr("fill", "#0a71b7")
			//@ts-ignore
			.attr("x", d => x(d.name) + 2)
			//@ts-ignore
			.attr("y", d => y(d.value))
			//@ts-ignore
			.attr("height", d => y(0) - y(d.value))
			.attr("width", x.bandwidth() - 8);

		svg.append("g")
			.attr("class", "rects-wrapper")
			.selectAll("rect-wrapper")
			.data(data)
			.join("rect")
			//@ts-ignore
			.attr("x", d => x(d.name) - 3)
			//@ts-ignore
			.attr("y", d => 0)
			//@ts-ignore
			.attr("height", height)
			.attr("width", x.bandwidth() + 3);

		if (this.tooltip) {
			svg.selectAll(".rects-wrapper rect")
				.on("mouseover", (e: any) => {
					this.tooltip
						.transition()
						.duration(200)
						.style("opacity", 1);
					this.tooltip
						.html(
							`<div class="chart-tooltip__header">${e.name}</div>
							<strong>${(e.value * 1000).toFixed(0)}</strong> участий
						`,
						)
						.style("left", d3.event.pageX + "px")
						.style("top", d3.event.pageY - 28 + "px");
				})
				.on("mouseout", () => {
					this.tooltip
						.transition()
						.duration(500)
						.style("opacity", 0);
				});
		}

		svg.selectAll(".x-axis .tick line:not(.underline)").attr("transform", `translate(${x.bandwidth() / 2}, 0)`);

		svg.selectAll(".y-axis .tick text").attr("dx", `-10`);

		svg.append("text")
			.attr("class", "legend")
			.attr("x", 0)
			.attr("y", -20)
			.text(() => "Число участий (тыс.)");
	}

	// private updateChartData(data: DataItem[]) {
	// 	const node = this.svgRef.current;
	// 	const x = d3
	// 		.scaleBand()
	// 		.domain(data.map(d => d.name))
	// 		.range([margin.left, width - margin.right])
	// 		.padding(0.1);
	//
	// 	const y = d3
	// 		.scaleLinear()
	// 		//@ts-ignore
	// 		.domain([0, d3.max<DataItem>(data, d => d.value)])
	// 		.nice()
	// 		.range([height - margin.bottom, margin.top]);
	//
	// 	//@ts-ignore
	// 	y.domain([0, d3.max(data, d => d.value)]);
	//
	// 	const color = d3
	// 		.scaleLinear()
	// 		//@ts-ignore
	// 		.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
	// 		//@ts-ignore
	// 		.range(["#1b537d", "#3b97db"]);
	//
	// 	const rects = d3
	// 		.select(node)
	// 		.selectAll("rect")
	// 		.data(data);
	//
	// 	// enter selection
	// 	rects.enter().append("rect");
	//
	// 	// update selection
	// 	rects
	// 		.transition()
	// 		.duration(300)
	// 		//@ts-ignore
	// 		.attr("x", d => x(d.name))
	// 		//@ts-ignore
	// 		.attr("y", d => y(d.value))
	// 		//@ts-ignore
	// 		.attr("fill", d => color(d.value))
	// 		//@ts-ignore
	// 		.attr("height", d => y(0) - y(d.value))
	// 		.attr("width", x.bandwidth());
	//
	// 	rects.exit().remove();
	//
	// 	const yAxisCall = d3
	// 		.axisLeft(y)
	// 		.tickSize(-width)
	// 		//@ts-ignore
	// 		.tickFormat(d => d);
	//
	// 	const t = d3.transition().duration(500);
	//
	// 	d3.select(node)
	// 		.select(".y")
	// 		//@ts-ignore
	// 		.transition(t)
	// 		.call(yAxisCall);
	//
	// 	//
	// 	// bar.data(data, d => d.name)
	// 	// 	.order()
	// 	// 	.transition(t)
	// 	// 	.delay((d, i) => i * 20)
	// 	// 	.attr("x", d => x(d.name));
	// 	//
	// 	// gx.transition(t)
	// 	// 	.call(xAxis)
	// 	// 	.selectAll(".tick")
	// 	// 	.delay((d, i) => i * 20);
	// }

	public render() {
		return (
			<svg
				style={{ overflow: "visible" }}
				ref={this.svgRef}
				width={1000}
				height={500}
				className={this.props.className}
			/>
		);
	}
}

import React from "react";

export function CloseIcon({ className }: { className?: string }) {
	return (
		<svg version="1.1" x="0px" y="0px" viewBox="0 0 25.5 25.5" className={className}>
			<g>
				<g>
					<rect
						x="12.3"
						y="-4.8"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.2595 12.7956)"
						width="1"
						height="35.1"
					/>
				</g>
				<g>
					<rect
						x="-4.8"
						y="12.3"
						transform="matrix(0.7071 -0.7071 0.7071 0.7071 -5.3266 12.7576)"
						width="35.1"
						height="1"
					/>
				</g>
			</g>
		</svg>
	);
}

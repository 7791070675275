import React from "react";
import $ from "jquery";

export const InteractiveMap = React.forwardRef((_props, ref: React.Ref<HTMLDivElement>) => {
	React.useEffect(() => {
		// @ts-ignore
		$(ref.current!).mapael({
			map: {
				name: "russia",
				zoom: {
					enabled: true,
					maxLevel: 10,
					mousewheel: false,
				},
				defaultArea: {
					attrs: {
						fill: "#dbe3e8",
						stroke: "#fff",
					},
					attrsHover: {
						fill: "#bca93f",
					},
					text: {
						attrs: {
							fill: "#505444",
						},
						attrsHover: {
							fill: "#000",
						},
					},
				},
			},
			plots: {
				// Image plot
				spb: {
					type: "circle",
					size: 10,
					latitude: 180.9343,
					longitude: 172.3351,
					attrs: {
						fill: "#dbe3e8",
					},
				},
				msk: {
					type: "circle",
					size: 10,
					latitude: 250.9343,
					longitude: 155.3351,
					attrs: {
						fill: "#dbe3e8",
					},
				},
				svs: {
					type: "circle",
					size: 10,
					latitude: 300.9343,
					longitude: 10.3351,
					attrs: {
						fill: "#dbe3e8",
					},
				},
			},
		});
	}, []);

	return (
		<div className="slide-map__mapael" ref={ref}>
			<div className="map">Alternative content</div>
		</div>
	);
});

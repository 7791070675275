import React from "react";

export function Search({ className }: { className?: string }) {
	return (
		<svg y="0px" viewBox="0 0 277.4 277.4" className={className}>
			<path
				d="M270.2,235.8l-75.9-75.9l0,0c9.9-16,15.6-34.8,15.6-54.9c0-57.9-47.1-105-105-105C47.1,0,0,47.1,0,105
	s47.1,105,105,105c20.1,0,39-5.7,54.9-15.6l75.9,75.9c4.8,4.8,11,7.1,17.2,7.1s12.5-2.4,17.2-7.1
	C279.8,260.7,279.8,245.3,270.2,235.8z M30.4,105c0-41.1,33.4-74.5,74.5-74.5s74.5,33.4,74.5,74.5s-33.4,74.5-74.5,74.5
	C63.9,179.5,30.4,146,30.4,105z"
			/>
		</svg>
	);
}

import React from "react";
import cn from "classnames";
import { RussianCompetitions } from "./russian-competitions";
import { InternationalCompetitions } from "./international-competitions";

const dataSources = [
	{
		id: 1,
		name: "ВСОШ",
	},
	{
		id: 2,
		name: "Международные олимпиады",
	},
];

function AnalyticsParticipantsTypes({ onChange, selected }: { onChange(id: number): void; selected: number }) {
	function handleChange(id: number) {
		return function() {
			onChange(id);
		};
	}

	return (
		<div className="analytics-participants-types">
			{dataSources.map(d => (
				<div
					key={d.id}
					onClick={handleChange(d.id)}
					className={cn("analytics-participants-types__item", {
						"analytics-participants-types__item--selected": d.id === selected,
					})}
				>
					{d.name}
				</div>
			))}
		</div>
	);
}

export function AnalyticsParticipants() {
	const [dataSource, setDataSource] = React.useState(1);

	function handleDataSourceChange(dataSourceId: number) {
		setDataSource(dataSourceId);
	}

	return (
		<>
			<AnalyticsParticipantsTypes onChange={handleDataSourceChange} selected={dataSource} />
			{dataSource === 1 ? <RussianCompetitions /> : <InternationalCompetitions />}
		</>
	);
}

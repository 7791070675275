import React from "react";
import { SlideMap } from "./slide-map";
import { SlideDistribution } from "./slide-distribution";
import { SlideDynamic } from "./slide-dynamic";
import { News } from "./news";

export class Home extends React.Component {
	public render() {
		return (
			<>
				<SlideMap />
				<SlideDynamic />
				<SlideDistribution />
				<div className="slide">
					<div className="slide__top">05</div>
					<div className="slide-header">Новости</div>
					<News />
				</div>
			</>
		);
	}
}

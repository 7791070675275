import React from "react";
import * as d3 from "d3";
import cn from "classnames";

interface DataItem {
	value: number;
	name: string;
}

interface Props {
	data: DataItem[];
	className?: string;
	tooltipClassName?: string;
	colors: string[];
	width?: number;
	height?: number;
}

const defaultWidth = 390;
const defaultHeight = 390;

export class DonutChart extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		this.tooltip = d3
			.select("body")
			.append("div")
			.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
			.style("opacity", 0);

		this.createGraph(this.props.data);
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createGraph(this.props.data);
	}


	private createGraph(data: DataItem[]) {
		const node = this.svgRef.current;
		const tooltip = this.tooltip;

		const width = this.props.width || defaultWidth;
		const height = Math.min(width, this.props.height || defaultHeight);

		const pie = d3
			.pie<{ name: string; value: number }>()
			.padAngle(0.005)
			.sort(null)
			.value(d => d.value);

		const arcs = pie(data);

		const radius = Math.min(width, height) / 2;

		const arc = d3
			.arc()
			.innerRadius(radius * 0.5)
			.outerRadius(radius - 1);

		const color = d3
			.scaleOrdinal()
			//@ts-ignore
			//.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
			//@ts-ignore
			.range(this.props.colors);

		const arcNodes = d3
			.select(node)
			.selectAll("path")
			.data(arcs)
			.join("path")
			// @ts-ignore
			.attr("fill", d => color(d.data.value))
			//@ts-ignore
			.attr("d", arc);

		if (tooltip) {
			arcNodes
				.on("mouseover", (e: any) => {
					tooltip
						.transition()
						.duration(200)
						.style("opacity", 1);
					tooltip
						.html(
							`<div class="chart-tooltip__header">${e.data.name}</div>
							<strong>${e.data.value.toFixed(0)}</strong> человек<br/>
							<strong>${e.data.ratio.toFixed(0)} %</strong>
						`,
						)
						.style("left", d3.event.pageX + "px")
						.style("top", d3.event.pageY - 28 + "px");
				})
				.on("mouseout", () => {
					tooltip
						.transition()
						.duration(500)
						.style("opacity", 0);
				});
		}
	}

	public render() {
		const { width = defaultWidth, height = defaultHeight } = this.props;

		const viewBox = `${-width / 2}, ${-height / 2}, ${width}, ${height}`;

		return (
			<svg ref={this.svgRef} width={width} height={height} viewBox={viewBox} className={this.props.className} />
		);
	}
}

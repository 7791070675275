import React from "react";
import { TotalActualParticipants } from "./slides/total-actual-participants";
import { ActualParticipants } from "./slides/actual-participants";
import { ParticipantsRatio } from "./slides/participants-ratio";
import { ParticipantsDispersion } from "./slides/participants-dispersion";

export function PastAnalyticsInformation() {
	return (
		<>
			<div className="slide slide--dark">
				<div className="slide__top">01</div>
				<div className="slide-header">Общее фактическое число участий по этапам ВсОШ</div>
				<TotalActualParticipants />
			</div>
			<div className="slide slide--indigo">
				<div className="slide__top">02</div>
				<div className="slide-header">Фактическое число участий по этапам ВсОШ</div>
				<ActualParticipants />
			</div>
			<div className="slide">
				<div className="slide__top">03</div>
				<div className="slide-header">Соотношение участников, призеров и победителей по предметам</div>
				<ParticipantsRatio />
			</div>
			<div className="slide slide--indigo">
				<div className="slide__top">04</div>
				<div className="slide-header">Распределение долей количества участников по предметам</div>
				<ParticipantsDispersion />
			</div>
			<div className="slide slide--dark">
				<div className="slide__top">05</div>
				<div className="slide-header">Предметы и регионы не представленные на региональном этапе ВсОШ</div>

				<div className="missed-items">
					<div className="missed-items__column">
						<div className="missed-items__header">По регионам</div>

						<div className="missed-items__rows">
							<div className="missed-items__row">
								<div className="missed-items__row-name">Владимирская область</div>
								<div className="missed-items__row-value">
									<span className="val-2">2</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Магаданская область</div>
								<div className="missed-items__row-value">
									<span className="val-3">3</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Пензенская область</div>
								<div className="missed-items__row-value">
									<span className="val-1">1</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Сахалинская область</div>
								<div className="missed-items__row-value">
									<span className="val-2">2</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Чеченская республика</div>
								<div className="missed-items__row-value">
									<span className="val-1">1</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Чукотский АО</div>
								<div className="missed-items__row-value">
									<span className="val-4">4</span>
								</div>
							</div>
						</div>
					</div>

					<div className="missed-items__column missed-items__column--subjects">
						<div className="missed-items__header">По предметам</div>

						<div className="missed-items__rows">
							<div className="missed-items__row">
								<div className="missed-items__row-name">Астрономия</div>
								<div className="missed-items__row-value">
									<span className="val-2">2</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Искусство</div>
								<div className="missed-items__row-value">
									<span className="val-1">1</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">ОБЖ</div>
								<div className="missed-items__row-value">
									<span className="val-2">2</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Технология</div>
								<div className="missed-items__row-value">
									<span className="val-4">4</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Физическая культура</div>
								<div className="missed-items__row-value">
									<span className="val-1">1</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Экология</div>
								<div className="missed-items__row-value">
									<span className="val-1">1</span>
								</div>
							</div>
							<div className="missed-items__row">
								<div className="missed-items__row-name">Экономика</div>
								<div className="missed-items__row-value">
									<span className="val-2">2</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

import React from "react";

export function Rar({ className }: { className: string }) {
	return (
		<svg className={className} x="0px" y="0px" viewBox="0 0 420.2 462.6">
			<rect x="23.1" y="201.9" fill="#FFFFFF" width="318" height="166" />
			<g>
				<path
					fill="#BCA93F"
					d="M415.2,90.2l-0.4-0.4c0,0,0,0,0,0L330.3,5.3h0l-0.4-0.4C326.7,1.7,322.5,0,318,0H72.6
		C58.7,0,47.4,11.3,47.4,25.2v136.7h16.8V25.2c0-4.6,3.8-8.4,8.4-8.4h240.5v65c0,13.9,11.3,25.2,25.2,25.2h65v330.4
		c0,4.6-3.8,8.4-8.4,8.4H72.6c-4.6,0-8.4-3.8-8.4-8.4V399H47.4v38.5c0,13.9,11.3,25.2,25.2,25.2H395c13.9,0,25.2-11.3,25.2-25.2
		V102.1C420.2,97.6,418.4,93.4,415.2,90.2z"
				/>
				<g>
					<polygon fill="#6A4198" points="168.5,292.9 195.5,292.9 182.2,251.4" />
					<path
						fill="#6A4198"
						d="M298.6,247.4c-2.3-1.2-5.7-1.9-10.2-1.9H264v28.4h23.7c4.7,0,8.3-0.6,10.6-1.7c4.2-2,6.3-6,6.3-11.9
			C304.7,253.9,302.6,249.6,298.6,247.4z"
					/>
					<path
						fill="#6A4198"
						d="M94.1,247.4c-2.3-1.2-5.7-1.9-10.2-1.9H59.5v28.4h23.7c4.7,0,8.3-0.6,10.6-1.7c4.2-2,6.3-6,6.3-11.9
			C100.1,253.9,98.1,249.6,94.1,247.4z"
					/>
					<path
						fill="#6A4198"
						d="M349.7,183.8H11.9c-6.6,0-11.9,5.6-11.9,12.6v171.2c0,7,5.3,12.6,11.9,12.6h337.8c6.6,0,11.9-5.6,11.9-12.6
			V196.4C361.7,189.5,356.3,183.8,349.7,183.8z M124,332.8H99.6c-0.7-2.3-1.1-4.2-1.4-5.7c-0.6-3-0.9-6-0.9-9.1l-0.1-9.8
			c-0.1-6.7-1.3-11.2-3.5-13.5c-2.2-2.2-6.4-3.4-12.6-3.4H59.5v41.4H38V227.2h50.5c7.2,0.1,12.8,1.1,16.7,2.7
			c3.9,1.7,7.2,4.1,9.9,7.4c2.2,2.7,4,5.6,5.3,8.9c1.3,3.2,2,6.9,2,11.1c0,5-1.3,9.9-3.8,14.8c-2.5,4.8-6.7,8.3-12.5,10.3
			c4.9,2,8.3,4.7,10.4,8.3c2,3.6,3,9.1,3,16.5v7.1c0,4.8,0.2,8.1,0.6,9.8c0.6,2.7,1.9,4.7,4.1,6V332.8z M208.3,332.8l-7-21.7h-38.9
			l-7.2,21.7h-23.1l37.7-105.6h25l37.4,105.6H208.3z M328.5,332.8h-24.4c-0.7-2.3-1.1-4.2-1.4-5.7c-0.6-3-0.9-6-0.9-9.1l-0.1-9.8
			c-0.1-6.7-1.3-11.2-3.5-13.5c-2.2-2.2-6.4-3.4-12.6-3.4H264v41.4h-21.6V227.2H293c7.2,0.1,12.8,1.1,16.7,2.7
			c3.9,1.7,7.2,4.1,9.9,7.4c2.2,2.7,4,5.6,5.3,8.9c1.3,3.2,2,6.9,2,11.1c0,5-1.3,9.9-3.8,14.8c-2.5,4.8-6.7,8.3-12.5,10.3
			c4.9,2,8.3,4.7,10.4,8.3c2,3.6,3,9.1,3,16.5v7.1c0,4.8,0.2,8.1,0.6,9.8c0.6,2.7,1.9,4.7,4.1,6V332.8z"
					/>
				</g>
			</g>
		</svg>
	);
}

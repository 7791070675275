import React from "react";
import { Slide1 } from "./period/slide1";
import { Slide3 } from "./period/slide3";
import { Slide4 } from "./period/slide4";
import { Slide4_1 } from "./period/slide-4-1";
import { Slide5 } from "./period/slide5";
import { Slide6 } from "./period/slide6";
import { Slide2 } from "./period/slide2";

export function PeriodAnalyticsInformation() {
	return (
		<div className="analytics-period">
			<div className="slide slide--dark">
				<div className="slide__top">01</div>
				<div className="slide-header">Динамика количества участников олимпиады</div>
				<Slide1 />
			</div>
			<div className="slide">
				<div className="slide__top">02</div>
				<div className="slide-header">Соотношение числа участников ВсОШ и фактического числа участий</div>
				<Slide2 />
			</div>
			<div className="slide slide--indigo">
				<div className="slide__top ">03</div>
				<div className="slide-header">Динамика числа участников, призеров и победителей по предметам</div>
				<Slide3 />
			</div>
			<div className="slide slide--dark">
				<div className="slide__top">04</div>
				<div className="slide-header">
					Динамика количества участников, призеров и победителей с ОВЗ по этапам ВсОШ
				</div>
				<Slide4 />
			</div>
			<div className="slide slide--indigo">
				<Slide4_1 />
			</div>
			<div className="slide slide--gray">
				<div className="slide__top">05</div>
				<div className="slide-header">
					Субъекты Российской Федерации, показавшие
					<br />
					наилучшие результаты на заключительном этапе ВСОШ
				</div>
				<Slide5 />
			</div>
			<div className="slide slide--indigo">
				<div className="slide__top ">06</div>
				<div className="slide-header">Предметы и регионы не представленные на региональном этапе ВсОШ</div>
				<Slide6 />
			</div>
		</div>
	);
}

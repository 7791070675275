import React from "react";
import { AlphabetFilter } from "./alphabet-filter";
import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../types";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

export function Results() {
	return (
		<>
			<div className="results-filter">
				<div className="results-filter__options">
					<FilterToolbarSet filterSet={FilterSet.Set6} />
				</div>
				<AlphabetFilter />
			</div>

			<table className="table-light">
				<tbody>
					<tr>
						<th>Фамилия</th>
						<th>Класс участия</th>
						<th>Класс обучения</th>
						<th>Результат</th>
					</tr>
					<tr>
						<td>Арсенина</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Бурлаков</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Дорыкина</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Зитуна</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Керимова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Лапшин</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Мерсер</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Соловьева</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Фирман</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Цицуашвили</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Шаталин</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Ягизарова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Победитель</td>
					</tr>
					<tr>
						<td>Агашкова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Антипенкова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Белоновская</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Борисов</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Брагинский</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Василянская</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Гончаров</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Григорьев</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Дёмина</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Джоджуа</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Донской</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Емельянова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Жарова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Закарян</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Зорина</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Казакова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Круглов</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Кучер</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Ланцова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Лыкова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Михеева</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Мурадов</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Назаров</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Насырова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Орехов</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Осинцева</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Панова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Плотников</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Семункина</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Спасская</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Холодняк</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Чекмазова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Шабаева</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Шлыкова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Экономова</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Юдин</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
					<tr>
						<td>Юдкина</td>
						<td>9-11</td>
						<td>10</td>
						<td>Призёр</td>
					</tr>
				</tbody>
			</table>

			<div className="results-docs">
				<div className="results-docs__content">
					<FileDownloadLink type={FileType.Pdf} text="Предварительный протокол" />
					<FileDownloadLink type={FileType.Pdf} text="Итоговый протокол" />
					<FileDownloadLink type={FileType.Pdf} text="Приказ, утверждающий итоги" />
				</div>
			</div>
		</>
	);
}

import React from "react";

import { CategoriesPane } from "@components/categories-pane";
import { Documents } from "../shared/documents";
import { PassScore } from "../shared/pass-score";
import { BoundaryScore } from "../shared/boundary-score";
import { CalendarSlide } from "../shared/calendar";
import { Schedule } from "../shared/schedule";
import { InternationalContent } from "../shared/international-content";

const categoryItems = [
	{
		id: 1,
		name: (
			<span>
				Школьный <br />
				этап
			</span>
		),
	},
	{ id: 2, name: "Муниципальный этап" },
	{ id: 3, name: "Региональный этап" },
	{ id: 4, name: "Заключительный этап" },
	{ id: 5, name: "Международные олимпиады" },
];

function StepContent({ categoryId }: { categoryId: number }) {
	return (
		<>
			<div className="slide">
				<div className="slide__top">01</div>
				<div className="slide-header">Календарь</div>
				<CalendarSlide categoryId={categoryId} />
			</div>

			<div className="slide slide--dark">
				<div className="slide__top">02</div>
				<div className="slide-header">Расписание</div>

				<Schedule />
			</div>

			<div className="slide slide">
				<div className="slide__top">03</div>
				<div className="slide-header">О проведении муниципального этапа</div>
				<Documents />
			</div>

			<div className="slide slide--dark">
				<div className="slide__top">04</div>
				<div className="slide-header">Проходные баллы на муниципальный этап</div>
				<PassScore />
			</div>

			<div className="slide slide--transition">
				<div className="slide__top">05</div>
				<div className="slide-header">
					Граничные баллы для определения призеров и победителей школьного этапа
				</div>
				<BoundaryScore />
			</div>
		</>
	);
}

export class Current extends React.Component<{}, { categoryId: number }> {
	public state = {
		categoryId: 1,
	};

	private handleSelect = (categoryId: number) => {
		this.setState({
			categoryId,
		});
	};

	public render() {
		const { categoryId } = this.state;

		return (
			<>
				<CategoriesPane items={categoryItems} onSelect={this.handleSelect} selected={categoryId} />
				{categoryId === 5 ? <InternationalContent /> : <StepContent categoryId={categoryId} />}
			</>
		);
	}
}

import React from "react";
import cn from "classnames";

export function PagerSize() {
	const [size, setSize] = React.useState(50);

	function handleChange(val: number) {
		return function() {
			setSize(val);
		};
	}

	return (
		<div className="pager-size">
			<div
				className={cn("pager-size__button", { "pager-size__button--active": size === 50 })}
				onClick={handleChange(50)}
			>
				Показать 50
			</div>
			<div
				className={cn("pager-size__button", { "pager-size__button--active": size === 100 })}
				onClick={handleChange(100)}
			>
				Показать 100
			</div>
			<div
				className={cn("pager-size__button", { "pager-size__button--active": size === 200 })}
				onClick={handleChange(200)}
			>
				Показать 200
			</div>
		</div>
	);
}

import React from "react";
import { SearchPanel } from "@components/search-panel";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function Card() {
	return (
		<div className="contact-card">
			<div className="contact-card__header">Министерство просвещения Российской Федерации</div>
			<div className="contact-card__person">Иванов Иван Иванович</div>
			<div className="contact-card__department">
				Департамент государственной политики в сфере оценки качества общего образования
			</div>
			<div className="contact-card__contact">
				Тел. <strong>+7 (495) 555-55-55</strong> доб. 55-55
			</div>
			<div className="contact-card__person">
				E-mail: <strong>Ivanov-ii@edu.gov.ru</strong>
			</div>
		</div>
	);
}

export function Contacts() {
	return (
		<>
			<div>
				<FilterToolbarSet filterSet={FilterSet.Set1} />
				<SearchPanel />
			</div>

			<div className="contact-list">
				<div className="contact-list__path">
					Федеральный уровень / Название региона /{" "}
					<span className="contact-list-path-active">Название муниципалитета</span>
				</div>

				<div className="contact-list__row">
					<Card />
					<Card />
				</div>

				<div className="contact-list__row">
					<Card />
					<Card />
				</div>
			</div>
		</>
	);
}

import React from "react";

export function Zip({ className }: { className: string }) {
	return (
		<svg className={className} x="0px" y="0px" viewBox="0 0 420.2 462.6">
			<rect x="23.1" y="207.8" fill="#FFFFFF" width="305.4" height="150" />
			<g>
				<path
					fill="#BCA93F"
					d="M415.2,90.2l-0.4-0.4c0,0,0,0,0,0L330.3,5.3h0l-0.4-0.4C326.7,1.7,322.5,0,318,0H72.6
		C58.7,0,47.4,11.3,47.4,25.2v136.7h16.8V25.2c0-4.6,3.8-8.4,8.4-8.4h240.5v65c0,13.9,11.3,25.2,25.2,25.2h65v330.4
		c0,4.6-3.8,8.4-8.4,8.4H72.6c-4.6,0-8.4-3.8-8.4-8.4V399H47.4v38.5c0,13.9,11.3,25.2,25.2,25.2H395c13.9,0,25.2-11.3,25.2-25.2
		V102.1C420.2,97.6,418.4,93.4,415.2,90.2z"
				/>
				<g>
					<path
						fill="#BCA93F"
						d="M268.7,249.1c-2.8-2.3-6.7-3.5-11.7-3.5h-19.9v31.1H257c5,0,8.9-1.3,11.7-3.8c2.8-2.5,4.2-6.5,4.2-12
			C272.9,255.3,271.5,251.4,268.7,249.1z"
					/>
					<path
						fill="#BCA93F"
						d="M349.7,183.8H11.9c-6.6,0-11.9,5.6-11.9,12.6v171.2c0,7,5.3,12.6,11.9,12.6h337.8c6.6,0,11.9-5.6,11.9-12.6
			V196.4C361.7,189.5,356.3,183.8,349.7,183.8z M158.4,332.8H76.9v-18.6l54-68.3H78.2v-18.7h80v17.7l-54.7,69.3h54.8V332.8z
			 M194,332.8h-21.9V227.2H194V332.8z M285.6,287.1c-6.2,5.2-15,7.7-26.5,7.7h-22v38h-21.9V227.2h45.3c10.5,0,18.8,2.7,25,8.2
			c6.2,5.4,9.3,13.9,9.3,25.3C294.8,273.1,291.7,282,285.6,287.1z"
					/>
				</g>
			</g>
		</svg>
	);
}

import React from "react";
import { SearchPanel } from "@components/search-panel";
import { news } from "../../data";

function FakeResults() {
	return (
		<div className="search-category">
			<div className="search-category__header">Новости</div>
			{news.map(item => (
				<div className="search-item">
					<div className="search-item__header">
						<a href={item.url}>{item.name}</a>
					</div>
					<div className="search-item__teaser">{item.teaser}</div>
					<div className="search-item__date">{item.date}</div>
				</div>
			))}
		</div>
	);
}

export function Search() {
	const [searchValue, setSearchValue] = React.useState("");

	function handleSearchChange(value: string) {
		setSearchValue(value);
	}

	return (
		<>
			<SearchPanel value={searchValue} onChange={handleSearchChange} />

			{searchValue && <FakeResults />}

			{/*<div className="search-category">*/}
			{/*	<div className="search-category__header">Задания и решения</div>*/}
			{/*	<div className="search-item">*/}
			{/*		<div className="search-item__header">Задания по литературе</div>*/}
			{/*		<div className="search-item__teaser">Задание 1</div>*/}
			{/*		<div className="search-item__date">19.11.2019</div>*/}
			{/*	</div>*/}
			{/*	<div className="search-item">*/}
			{/*		<div className="search-item__header">Расписание муниципального этапа по литературе</div>*/}
			{/*		<div className="search-item__teaser">Задание 1.</div>*/}
			{/*		<div className="search-item__date">15.10.2019</div>*/}
			{/*	</div>*/}
			{/*	<div className="search-item">*/}
			{/*		<div className="search-item__header">Решения по литературе</div>*/}
			{/*		<div className="search-item__teaser">*/}
			{/*			Решение 1<br />*/}
			{/*			Решение 2*/}
			{/*		</div>*/}
			{/*		<div className="search-item__date">15.10.2019</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*<div className="search-category">*/}
			{/*	<div className="search-category__header">Полезная информация</div>*/}
			{/*	<div className="search-item">*/}
			{/*		<div className="search-item__header">*/}
			{/*			По каким предметам проводится Всероссийская олимпиада школьников?*/}
			{/*		</div>*/}
			{/*		<div className="search-item__teaser">*/}
			{/*			Всероссийская олимпиада школьников проводится по 24 предметам: английский язык, астрономия,*/}
			{/*			биология, география, информатика и икт, искусство (мировая художественная культура), испанский*/}
			{/*			язык, история, итальянский язык, китайский язык, <span className="search-highlight">литература</span>, математика...*/}
			{/*		</div>*/}
			{/*		<div className="search-item__date">19.11.2019</div>*/}
			{/*	</div>*/}
			{/*</div>*/}

			{/*<Pager />*/}
		</>
	);
}

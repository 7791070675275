import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../types";
import React from "react";
import cn from "classnames";
import { DocListArrow } from "@icons/doc-list-arrow";
import { schoolSubjects } from "../../../data";

class DocumentCategory extends React.Component {
	public state = {
		expanded: false,
	};

	private handleClick = () => {
		this.setState({
			expanded: !this.state.expanded,
		});
	};

	public render() {
		const { expanded } = this.state;

		return (
			<>
				<div
					className={cn("document-category-link", { "document-category-link--expanded": expanded })}
					onClick={this.handleClick}
				>
					<DocListArrow className={cn("document-category-link__icon")} />
					Требования к проведению по предметам
				</div>
				<div className={cn("document-category", { "document-category--hidden": !expanded })}>
					{schoolSubjects.map(x => (
						<FileDownloadLink key={x.id} text={x.name} type={FileType.Pdf} className="document" />
					))}
				</div>
			</>
		);
	}
}

export function Documents() {
	return (
		<>
			<FileDownloadLink text="Приказ о проведении" type={FileType.Pdf} className="document" />
			<FileDownloadLink text="Методические рекомендации" type={FileType.Pdf} className="document" />
			<FileDownloadLink text="Общие требования к проведению" type={FileType.Pdf} className="document" />
			<DocumentCategory />
			<FileDownloadLink text="Оргкомитет" type={FileType.Pdf} className="document" />

			<div style={{ padding: 30 }}></div>
		</>
	);
}

import React from "react";
import * as d3 from "d3";

interface DataItem {
	value: number;
	name: string;
}

interface Props {
	data: DataItem[];
	className?: string;
}

const width = 500;
const height = 500;
const margin = { top: 50, right: 0, bottom: 30, left: 100 };

export class BarChart extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();

	public componentDidMount() {
		this.createChart(this.props.data);
	}

	public componentDidUpdate() {
		this.updateChartData(this.props.data);
	}

	private createChart(data: DataItem[]) {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const x = d3
			.scaleBand()
			.domain(data.map(d => d.name))
			.range([margin.left, width - margin.right])
			.padding(0.1);

		const y = d3
			.scaleLinear()
			//@ts-ignore
			.domain([0, d3.max<DataItem>(data, d => d.value)])
			.nice()
			.range([height - margin.bottom, margin.top]);

		const color = d3
			.scaleLinear()
			//@ts-ignore
			.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
			//@ts-ignore
			.range(["#1b537d", "#3b97db"]);

		svg.append("g")
			.attr("transform", `translate(0,${height - margin.bottom})`)
			.attr("class", "x axis")
			.call(d3.axisBottom(x).tickSizeOuter(0))
			.select("path")
			.attr("stroke", "#ffffff");

		svg.selectAll(".tick")
			.selectAll("text")
			.attr("fill", "#ffffff");

		svg.append("g")
			.attr("transform", `translate(${margin.left},0)`)
			.attr("class", "y axis")
			.call(
				d3
					.axisLeft(y)
					.tickSize(-width)
					//@ts-ignore
					.tickFormat(d => d),
			);

		svg.append("g")
			.attr("class", "rects")
			.selectAll("rect")
			.data(data)
			.join("rect")
			.attr("fill", d => color(d.value))
			//@ts-ignore
			.attr("x", d => x(d.name))
			//@ts-ignore
			.attr("y", d => y(d.value))
			//@ts-ignore
			.attr("height", d => y(0) - y(d.value))
			.attr("width", x.bandwidth());

		svg.selectAll("text.bar")
			.data(data)
			.enter()
			.append("text")
			.attr("class", "bar")
			.attr("text-anchor", "middle")
			//@ts-ignore
			.attr("x", d => x(d.name) + x.bandwidth() / 2)
			.attr("y", d => y(d.value) - 5)
			//@ts-ignore
			.text(d => parseInt(d.value, 10));
	}

	private updateChartData(data: DataItem[]) {
		const node = this.svgRef.current;
		const x = d3
			.scaleBand()
			.domain(data.map(d => d.name))
			.range([margin.left, width - margin.right])
			.padding(0.1);

		const y = d3
			.scaleLinear()
			//@ts-ignore
			.domain([0, d3.max<DataItem>(data, d => d.value)])
			.nice()
			.range([height - margin.bottom, margin.top]);

		//@ts-ignore
		y.domain([0, d3.max(data, d => d.value)]);

		const color = d3
			.scaleLinear()
			//@ts-ignore
			.domain([d3.min(data, x => x.value)!, d3.max<DataItem>(data, x => x.value)])
			//@ts-ignore
			.range(["#1b537d", "#3b97db"]);

		const rects = d3
			.select(node)
			.selectAll("rect")
			.data(data);

		// enter selection
		rects.enter().append("rect");

		// update selection
		rects
			.transition()
			.duration(300)
			//@ts-ignore
			.attr("x", d => x(d.name))
			//@ts-ignore
			.attr("y", d => y(d.value))
			//@ts-ignore
			.attr("fill", d => color(d.value))
			//@ts-ignore
			.attr("height", d => y(0) - y(d.value))
			.attr("width", x.bandwidth());

		rects.exit().remove();

		const texts = d3
			.select(node)
			.selectAll("text.bar")
			.data(data);

		texts.enter().append("text");

		texts
			.transition()
			.duration(300)
			.attr("class", "bar")
			.attr("text-anchor", "middle")
			//@ts-ignore
			.attr("x", d => x(d.name) + x.bandwidth() / 2)
			.attr("y", d => y(d.value) - 5)
			//@ts-ignore
			.text(d => parseInt(d.value, 10));

		texts.exit().remove();

		const yAxisCall = d3
			.axisLeft(y)
			.tickSize(-width)
			//@ts-ignore
			.tickFormat(d => d);

		const t = d3.transition().duration(500);

		d3.select(node)
			.select(".y")
			//@ts-ignore
			.transition(t)
			.call(yAxisCall);

		//
		// bar.data(data, d => d.name)
		// 	.order()
		// 	.transition(t)
		// 	.delay((d, i) => i * 20)
		// 	.attr("x", d => x(d.name));
		//
		// gx.transition(t)
		// 	.call(xAxis)
		// 	.selectAll(".tick")
		// 	.delay((d, i) => i * 20);
	}

	public render() {
		return <svg ref={this.svgRef} width={500} height={500} className="slide-dynamic-barchart" />;
	}
}

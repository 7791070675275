import React from "react";
import { BarChart } from "./bar-chart";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function generateData() {
	return [
		{ name: "16/17", value: 680078 * Math.random() },
		{ name: "17/18", value: 800906 * Math.random() },
		{ name: "18/19", value: 960089 * Math.random() },
	];
}

export class SlideDynamic extends React.Component {
	public state = {
		data: generateData(),
	};

	private updateData = () => {
		this.setState({
			data: generateData(),
		});
	};

	private handleFilterChange = () => {
		this.updateData();
	};
	public render() {
		const { data } = this.state;

		return (
			<div className="slide slide--dark">
				<div className="slide__top">03</div>
				<div className="slide-header">Динамика общего числа участников, призеров и победителей</div>

				<div className="slide-filter">
					<FilterToolbarSet filterSet={FilterSet.Set9} onChange={this.handleFilterChange} className="slide-dynamic-filter-set" />
				</div>

				<div className="slide-distribution-infographics">
					<div className="slide-distribution-infographics__chart">
						<BarChart data={data} />
					</div>
					<div className="slide-distribution-infographics__description">
						<div>
							Количество участников <strong>Заключительного этапа</strong> Всероссийской олимпиады
							школьников было максимальным в <strong>2018/2019</strong> гг. (<strong>960 089</strong>{" "}
							человек), минимальным в <strong>2016/2017</strong> гг. (<strong>680 078</strong> человек).
							Изменение количества <strong>участников</strong> по сравнению с прошлым годом составило{" "}
							<strong>115%</strong>
						</div>
					</div>
				</div>

				<div className="slide__action">
					{/*<button className="button button--primary">Узнать больше</button>*/}
				</div>
			</div>
		);
	}
}

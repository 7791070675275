import React from "react";
import cn from "classnames";
import { schoolSubjects } from "../../data";
import "select2";
import { ZipIcon } from "@icons/docs";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";
import { fileList } from "./file-list";
import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../types";

function AchiveButton({ link }: { link: string }) {
	return (
		<a href={link} className="exercise-item-archive">
			<ZipIcon className="exercise-item-archive__icon" />
			<span className="exercise-item-archive__link">Скачать архив</span>
		</a>
	);
}

function SchoolSubjectExercises({
	schoolSubject,
	even,
	stepId,
	schoolClass,
}: {
	schoolSubject: {
		schoolSubjectId: number;
		schoolClass: number;
		year: number;
		steps: Array<{
			id: number;
			schoolClasses: Array<{
				id: number;
				exercises: {
					files: Array<{
						id: number;
						name: string;
						link: string;
					}>;
					archiveLink: string;
				};
				solutions: {
					files: Array<{
						id: number;
						name: string;
						link: string;
					}>;
					archiveLink: string;
				};
			}>;
		}>;
	};
	even: boolean;
	stepId: number;
	schoolClass: number;
}) {
	const step = schoolSubject.steps.find(s => s.id === stepId);

	if (!step) {
		return null;
	}

	const item = step.schoolClasses.find(sc => sc.id === schoolClass);

	if (!item) {
		return null;
	}

	return (
		<div className={cn("exercise-list", { "exercise-list--even": even })}>
			<div className="exercise-list__content">
				<div className="exercise-list__header">
					{schoolSubjects.find(x => x.id === schoolSubject.schoolSubjectId)!.name}
				</div>
				<div className="exercise-list-item">
					<div className="exercise-list-item__column exercise-list-item-column">
						<div className="exercise-list-item-column__header">Задания</div>
						<div className="exercise-list-item-column__content">
							<div className="exercise-list-item-column__files">
								{item.exercises.files.map(exf => {
									let type = FileType.Pdf;
									const name = exf.name.toLocaleLowerCase().trim();

									if (name.endsWith("mp3")) {
										type = FileType.Media;
									} else if (name.endsWith("zip")) {
										type = FileType.Zip;
									}

									return (
										<FileDownloadLink
											key={exf.id}
											type={type}
											text={exf.name}
											url={exf.link}
											className="exercise-item"
											target="_blank"
										/>
									);
								})}
							</div>
							<div className="exercise-list-item-column__archive">
								<AchiveButton link={item.exercises.archiveLink} />
							</div>
						</div>
					</div>
					<div className="exercise-list-item__column exercise-list-item-column">
						<div className="exercise-list-item-column__header">Решения</div>
						<div className="exercise-list-item-column__content">
							<div className="exercise-list-item-column__files">
								{item.solutions.files.map(sof => {
									let type = FileType.Pdf;
									const name = sof.name.toLocaleLowerCase().trim();

									if (name.endsWith("mp3") || name.endsWith("mp4")) {
										type = FileType.Media;
									} else if (name.endsWith("zip")) {
										type = FileType.Zip;
									}

									return (
										<FileDownloadLink
											key={sof.id}
											type={type}
											text={sof.name}
											url={sof.link}
											className="exercise-item"
											target="_blank"
										/>
									);
								})}
							</div>
							<div className="exercise-list-item-column__archive">
								<AchiveButton link={item.solutions.archiveLink} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export class Exercises extends React.Component<any, any> {
	public state = {
		filters: {
			period: 2018,
			schoolSubject: 0,
			schoolClass: 11,
			step: 3,
		},
	};

	private handleFilterChange = (_filterId: string, _value: number, filters: any) => {
		this.setState({
			filters,
		});
	};

	public render() {
		const {
			filters: { schoolClass, step, schoolSubject },
		} = this.state;

		//const subjects = subject > 0 ? schoolSubjectsWithLinks.filter(x => x.id === subject) : schoolSubjectsWithLinks;

		return (
			<>
				<div className="exercises-filter">
					<FilterToolbarSet filterSet={FilterSet.Set10} onChange={this.handleFilterChange} />
				</div>

				{fileList
					.filter(f => schoolSubject === 0 || f.schoolSubjectId === schoolSubject)
					.map((x, index) => (
						<SchoolSubjectExercises
							key={x.schoolSubjectId}
							// @ts-ignore
							schoolSubject={x}
							even={index % 2 === 0}
							schoolClass={schoolClass}
							stepId={step}
						/>
					))}

				{/*{subjects.map((x, index) => (*/}
				{/*	<SchoolSubjectExercises key={x.id} schoolSubject={x} even={index % 2 === 0} />*/}
				{/*))}*/}
			</>
		);
	}
}

import React from "react";
import { HorizontalStackedChart } from "@components/figures/horizontal-stacked-chart";
import { schoolSubjects } from "../../../../data";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function generateData() {
	return schoolSubjects.map(x => {
		// @ts-ignore
		const gold = parseInt(20 * Math.random(), 10);
		// @ts-ignore
		const silver = parseInt(175 * Math.random(), 10);
		// @ts-ignore
		const bronze = parseInt(400 * Math.random(), 10);

		return {
			id: x.id,
			name: x.name,
			gold,
			silver,
			bronze,
			total: gold + silver + bronze,
		};
	});
}

export function ParticipantsRatio() {
	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	return (
		<>
			<FilterToolbarSet onChange={handleFilterChange} filterSet={FilterSet.Set2} className="participants-ratio-filter" />

			<div className="participants-ratio">
				<div className="participants-ratio__column">
					<HorizontalStackedChart
						data={data.slice(0, data.length / 2)}
						className="participants-ratio__chart"
					/>
				</div>
				<div className="participants-ratio__column">
					<HorizontalStackedChart
						data={data.slice(data.length / 2, data.length)}
						className="participants-ratio__chart"
					/>
				</div>
			</div>

			<div className="participants-ratio-legends">
				<div className="legend-label">
					<div className="legend-label__dot legend-label__dot--1" />
					<div>Победители</div>
				</div>
				<div className="legend-label">
					<div className="legend-label__dot legend-label__dot--2" />
					<div>Призеры</div>
				</div>
				<div className="legend-label">
					<div className="legend-label__dot legend-label__dot--3" />
					<div>Участники</div>
				</div>
			</div>
		</>
	);
}
import React from "react";
import cn from "classnames";
import { DocListArrow } from "@icons/doc-list-arrow";

const content = [
	{
		id: 1,
		name: "1. Что такое Всероссийская олимпиада школьников и зачем в ней участвовать?",
		content: (
			<>
				<p>
					Всероссийская олимпиада – главное интеллектуальное соревнование для школьников страны. Олимпиада
					проводится по 24 предметам в 4 этапа: школьный, муниципальный, региональный и заключительный.
				</p>
				<p>
					Победители и призёры заключительного этапа олимпиады принимаются без вступительных испытаний в
					государственные и муниципальные средние профессиональные и высшие учебные заведения в соответствии с
					профилем олимпиады, а также получают денежные премии.
				</p>
			</>
		),
	},
	{
		id: 2,
		name: "2. По каким предметам проводится Всероссийская олимпиада школьников?",
		content: (
			<>
				<p>
					Всероссийская олимпиада – главное интеллектуальное соревнование для школьников страны. Олимпиада
					проводится по 24 предметам в 4 этапа: школьный, муниципальный, региональный и заключительный.
				</p>
				<p>
					Победители и призёры заключительного этапа олимпиады принимаются без вступительных испытаний в
					государственные и муниципальные средние профессиональные и высшие учебные заведения в соответствии с
					профилем олимпиады, а также получают денежные премии.
				</p>
			</>
		),
	},
	{
		id: 3,
		name: "3. Когда проводится Всероссийская олимпиада школьников?",
		content: (
			<>
				<p>
					Всероссийская олимпиада – главное интеллектуальное соревнование для школьников страны. Олимпиада
					проводится по 24 предметам в 4 этапа: школьный, муниципальный, региональный и заключительный.
				</p>
				<p>
					Победители и призёры заключительного этапа олимпиады принимаются без вступительных испытаний в
					государственные и муниципальные средние профессиональные и высшие учебные заведения в соответствии с
					профилем олимпиады, а также получают денежные премии.
				</p>
			</>
		),
	},
	{
		id: 4,
		name: "4. Кто может участвовать во Всероссийской олимпиаде школьников?",
		content: (
			<>
				<p>
					Всероссийская олимпиада – главное интеллектуальное соревнование для школьников страны. Олимпиада
					проводится по 24 предметам в 4 этапа: школьный, муниципальный, региональный и заключительный.
				</p>
				<p>
					Победители и призёры заключительного этапа олимпиады принимаются без вступительных испытаний в
					государственные и муниципальные средние профессиональные и высшие учебные заведения в соответствии с
					профилем олимпиады, а также получают денежные премии.
				</p>
			</>
		),
	},
];

function FaqItem({ item }: { item: any }) {
	const [expanded, setExpanded] = React.useState(false);

	function toggleExpand() {
		setExpanded(!expanded);
	}

	return (
		<div className="faq-item">
			<div className="faq-item__header" onClick={toggleExpand}>
				<div>{item.name}</div>
				<DocListArrow
					className={cn("faq-item__header-icon", { "faq-item__header-icon--expanded": expanded })}
				/>
			</div>
			{expanded && <div className="faq-item__content">{item.content}</div>}
		</div>
	);
}

export function Faq() {
	return (
		<>
			{content.map(item => (
				<FaqItem key={item.id} item={item} />
			))}
		</>
	);
}

import React from "react";

export function DocListArrow({ className }: { className: string }) {
	return (
		<svg version="1.1" x="0px" y="0px" viewBox="0 0 23.3 38.7" className={className}>
			<polygon
				className="st0"
				points="23.3,19.3 3.5,0 0,3.6 16.2,19.3 0,35.1 3.5,38.7 19.8,22.8 19.8,22.8 20,22.6 "
			/>
		</svg>
	);
}

import React from "react";
import * as d3 from "d3";
import cn from "classnames";

interface Props {
	className?: string;
	tooltipClassName?: string;
	opacity?: string;
	colors: string[];
	data: Array<{
		id: number;
		values: Array<{
			valueId: number;
			valueTitle: string;
			value: number;
		}>;
	}>;
	getTooltip?: (item: { valueId: number; valueTitle: string; value: number }) => string;
}

export class AreaChart1 extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		if (this.props.getTooltip) {
			this.tooltip = d3
				.select("body")
				.append("div")
				.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
				.style("opacity", 0);
		}

		this.createGraph();
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createGraph();
	}

	private createGraph() {
		const { opacity, colors, data, getTooltip } = this.props;
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const margin = { top: 0, right: 0, bottom: 0, left: 0 };
		const width = +svg.attr("width") - margin.left - margin.right;
		const height = +svg.attr("height") - margin.top - margin.bottom;
		const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		var color = d3
			.scaleOrdinal()
			// @ts-ignore
			.domain([0, 1])
			.range(colors.map(c => `${c}${opacity}`));

		const xDomainValues = data[0].values.map(x => ({
			id: x.valueId,
			name: x.valueTitle,
		}));

		var x = d3.scaleLinear().range([0, width]),
			y = d3.scaleLinear().range([height, 0]),
			z = color;

		var area = d3
			.area()
			.curve(d3.curveLinear)
			// @ts-ignore
			.x(d => x(d.valueId))
			.y0(y(0))
			// @ts-ignore
			.y1(d => y(d.value));

		// @ts-ignore
		x.domain([d3.min(xDomainValues, d => d.id), d3.max(xDomainValues, d => d.id)]);
		//x.domain(d3.extent(data, function(d) { return d.name; }));

		// @ts-ignore
		y.domain([0, d3.max(data, c => d3.max(c.values, d => d.value))]);

		z.domain(data.map(c => c.id));

		const xAxis = g
			.append("g")
			.attr("class", "x-axis")
			.attr("transform", "translate(0," + height + ")")
			.call(
				d3
					.axisBottom(x)
					.ticks(xDomainValues.length)
					.tickSize(-height)
					// @ts-ignore
					.tickFormat(x => xDomainValues.find(d => d.id === x)!.name),
			);

		const yAxis = g
			.append("g")
			.attr("class", "y-axis")
			.call(d3.axisLeft(y).tickSize(-width));

		xAxis.selectAll("text").attr("dy", 20);
		yAxis.selectAll("text").attr("dx", -10);

		const source = g
			.selectAll(".area")
			.data(data)
			.enter()
			.append("g")
			.attr("class", d => `area ${d.id}`);

		source
			.append("path")
			// @ts-ignore
			.attr("d", d => area(d.values))
			// @ts-ignore
			.style("fill", d => z(d.id));

		data.forEach((dataItem, index) => {
			source
				.append("path")
				.data([dataItem.values])
				.attr("class", "edge-line")
				.attr("fill", "none")
				.attr("stroke", colors[index])
				.attr("stroke-width", 2)
				// @ts-ignore
				.attr(
					"d",
					// @ts-ignore
					d3
						.line()
						// @ts-ignore
						.x(d => x(d.valueId))
						// @ts-ignore
						.y(d => y(d.value)),
				);
		});

		data.forEach((dataItem, index) => {
			const dots = source
				.selectAll(".dot")
				.data(dataItem.values)
				.enter()
				.append("circle")
				.attr("r", 3)
				.attr("cx", d => x(d.valueId))
				.attr("cy", d => y(d.value))
				.attr("fill", function() {
					// @ts-ignore
					return colors[index];
				});

			if (this.tooltip && getTooltip) {
				dots.on("mouseover", (e: any) => {
					this.tooltip
						.transition()
						.duration(200)
						.style("opacity", 1);
					this.tooltip
						.html(getTooltip(e))
						.style("left", d3.event.pageX + "px")
						.style("top", d3.event.pageY - 28 + "px");
				}).on("mouseout", () => {
					this.tooltip
						.transition()
						.duration(500)
						.style("opacity", 0);
				});
			}
		});
	}

	public render() {
		return (
			<svg
				ref={this.svgRef}
				width={1000}
				height={500}
				className={cn("chart chart--area", this.props.className)}
			></svg>
		);
	}
}

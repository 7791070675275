import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../types";
import React from "react";

const data_table = {
	headers: [
		"Предмет",
		<div>
			4 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			5 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			6 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			7 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			8 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			9 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			10 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
		<div>
			11 класс
			<br />
			<span className="hint">Макс./Реком.</span>
		</div>,
	],
	rows: [
		["Английский язык", [0, 0], [75, 35], [75, 40], [96, 55], [96, 60], [60, 40], [60, 40], [60, 40]],
		["Астрономия", [0, 0], [32, 16], [40, 18], [40, 18], [48, 20], [48, 20], [48, 24], [48, 26]],
		["Биология", [0, 0], [0, 0], [60, 42], [50, 35], [50, 35], [70, 49], [100, 72], [100, 72]],
		["География", [0, 0], [100, 50], [100, 50], [200, 80], [200, 80], [200, 80], [200, 80], [200, 80]],
		["Информатика", [0, 0], [25, 12], [25, 12], [40, 15], [40, 20], [500, 275], [500, 300], [500, 350]],
		["Искусство (МХК)", [0, 0], [0, 0], [0, 0], [60, 25], [60, 25], [60, 30], [60, 30], [60, 30]],
		["Испанский язык", [0, 0], [55, 30], [55, 30], [55, 30], [55, 30], [55, 30], [55, 30], [55, 30]],
		["История", [0, 0], [45, 15], [70, 25], [75, 30], [90, 35], [115, 40], [120, 40], [130, 50]],
		["Итальянский язык", [0, 0], [50, 25], [50, 25], [55, 27], [55, 27], [55, 27], [55, 27], [55, 27]],
		["Китайский язык", [0, 0], [45, 25], [45, 25], [45, 25], [45, 25], [55, 30], [55, 30], [55, 30]],
		["Литература", [0, 0], [75, 30], [75, 30], [75, 30], [75, 30], [75, 35], [75, 35], [75, 35]],
		["Математика", [20, 110], [20, 11], [20, 11], [20, 11], [24, 11], [24, 11], [24, 11], [24, 11]],
		["Немецкий язык", [0, 0], [55, 29], [55, 29], [104, 53], [104, 53], [120, 61], [120, 61], [120, 61]],
		["ОБЖ", [0, 0], [200, 100], [200, 100], [200, 100], [200, 100], [200, 100], [200, 100], [200, 100]],
		["Обществознание", [0, 0], [35, 10], [40, 15], [75, 20], [80, 25], [110, 35], [110, 40], [110, 40]],
		["Право", [0, 0], [37, 14], [37, 14], [78, 30], [78, 34], [85, 38], [105, 45], [105, 52]],
		["Русский язык", [62, 25], [43, 20], [50, 22], [54, 23], [50, 22], [56, 25], [64, 27], [62, 25]],
		["Технология (культура дома)", [0, 0], [55, 28], [60, 31], [65, 33], [65, 33], [75, 38], [75, 38], [75, 38]],
		[
			"Технология (робототехника)",
			[0, 0],
			[150, 80],
			[150, 80],
			[150, 80],
			[150, 80],
			[150, 80],
			[150, 80],
			[150, 80],
		],
		[
			"Технология (техника и техническое творчество)",
			[0, 0],
			[50, 26],
			[50, 26],
			[55, 28],
			[55, 28],
			[55, 28],
			[55, 28],
			[55, 28],
		],
		["Физика", [0, 0], [0, 0], [0, 0], [30, 10], [40, 15], [50, 20], [50, 20], [50, 20]],
		["Физическая культура", [0, 0], [100, 65], [100, 65], [100, 65], [100, 65], [100, 65], [100, 65], [100, 65]],
		["Французский язык", [0, 0], [101, 50], [101, 50], [108, 58], [108, 58], [110, 52], [110, 52], [110, 52]],
		["Химия", [0, 0], [0, 0], [0, 0], [50, 20], [50, 20], [50, 25], [50, 25], [50, 25]],
		["Экология", [0, 0], [28, 17], [28, 17], [34, 20], [34, 20], [41, 24], [48, 27], [48, 27]],
		["Экономика", [0, 0], [0, 0], [0, 0], [50, 10], [50, 10], [50, 10], [50, 10], [50, 10]],
	],
};

export function BoundaryScore() {
	return (
		<>
			<table className="points-table">
				<tbody>
					<tr>
						{data_table.headers.map((x, index) => (
							<th key={`b${index}`}>{x}</th>
						))}
					</tr>
					{data_table.rows.map((row, index) => (
						<tr key={`a${index}`}>
							{row.map((x, index) => (
								<td key={`d${index}`}>{index === 0 ? x : (x as number[]).map(y => (y === 0 ? "-" : y)).join("/")}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>

			<div className="recommendations">
				<FileDownloadLink
					url="#"
					text="Рекомендации по определению победителей и призеров"
					type={FileType.Pdf}
				/>
			</div>
		</>
	);
}

import { DonutChart } from "@components/figures/donut-chart";
import React from "react";
import { steps } from "../../../../data";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function generateData() {
	let lastValue = 1000000;

	const res = [...steps]
		.sort((a, b) => b.id - a.id)
		.map((x, i) => {
			const base = lastValue / (i + 1);

			const value = base + base * Math.random();
			lastValue = value / 2;

			return {
				name: `${x.name} ${x.id === 1 ? " (Ожидаемое количество участников)" : ""}`,
				id: x.id,
				value,
			};
		});

	const total = res.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
	return res.map(x => ({
		...x,
		ratio: (x.value / total) * 100,
	}));
}

export function TotalActualParticipants() {
	const [data, setData] = React.useState(generateData());
	const totalActualParticipants = data.reduce((agg, item) => agg + item.value, 0);

	function handleFilterChange() {
		setData(generateData());
	}

	return (
		<>
			<FilterToolbarSet onChange={handleFilterChange} filterSet={FilterSet.Set1} />
			<div className="participants-actual">
				<div className="participants-actual__column">
					<DonutChart
						data={data}
						colors={["#0f6fb8", "#6ecacd", "#e89818", "#bca93f"]}
						tooltipClassName="participants-actual-chart-tooltip"
					/>
					<div className="participants-actual-summary">
						<div className="participants-actual-summary__num">{totalActualParticipants.toFixed(0)}</div>
						Фактическое
						<br /> число участий
					</div>
				</div>
				<div className="participants-actual__column">
					<div className="legend-label">
						<div className="legend-label__dot legend-label__dot--1" />
						Школьный этап
					</div>
					<div className="legend-label">
						<div className="legend-label__dot legend-label__dot--2" />
						Муниципальный этап
					</div>
					<div className="legend-label">
						<div className="legend-label__dot legend-label__dot--3" />
						Региональный этап
					</div>
					<div className="legend-label">
						<div className="legend-label__dot legend-label__dot--4" />
						Заключительный этап <br /> (Ожидаемое количество участников)
					</div>

					<div className="participants-actual__description">
						По состоянию на <strong>15.02.2020</strong> во Всероссийской олимпиаде школьников приняли
						участие
						<strong>7 614 454</strong> человек.
						<br />С учетом количества предметов, по которым каждый обучающийся принимал участие, фактически
						было зарегистрировано
						<strong>19 168 936</strong> участников.
					</div>
				</div>
			</div>
		</>
	);
}


const femaleSurname = [
	"Ковалёва",
	"Ильина",
	"Гусева",
	"Титова",
	"Кузьмина",
	"Кудрявцева",
	"Баранова",
	"Куликова",
	"Алексеева",
	"Степанова",
	"Яковалева",
	"Сорокина",
	"Сергеева",
	"Романова",
	"Захарова",
	"Борисова",
	"Королева",
	"Герасимова",
	"Пономарева",
	"Григорьева",
	"Лазарева",
	"Медведева",
	"Ершова",
	"Никитина",
	"Соболева",
	"Рябова",
	"Полякова",
	"Цветкова",
	"Данилова",
	"Жукова",
	"Фролова",
	"Журавлева",
	"Николаева",
	"Путина",
	"Молчанова",
	"Крылова",
	"Максимова",
	"Сидорова",
	"Осипова",
	"Белоусова",
	"Федотова",
	"Дорофеева",
	"Егорова",
	"Панина",
	"Матвеева",
	"Боброва",
	"Дмитриева",
	"Калинина",
	"Анисимова",
	"Петухова",
	"Пугачева",
	"Антонова",
	"Тимофеева",
	"Никифорова",
	"Веселова",
	"Филиппова",
	"Романова",
	"Маркова",
	"Большакова",
	"Суханова",
	"Миронова",
	"Александрова",
	"Коновалова",
	"Шестакова",
	"Казакова",
	"Ефимова",
	"Денисова",
	"Громова",
	"Фомина",
	"Андреева",
	"Давыдова",
	"Мельникова",
	"Щербакова",
	"Блинова",
	"Колесникова",
	"Иванова",
	"Смирнова",
	"Кузнецова",
	"Попова",
	"Соколова",
	"Лебедева",
	"Козлова",
	"Новикова",
	"Морозова",
	"Петрова",
	"Волкова",
	"Соловаьева",
	"Васильева",
	"Зайцева",
	"Павлова",
	"Семенова",
	"Голубева",
	"Виноградова",
	"Богданова",
	"Воробьева",
	"Федорова",
	"Михайлова",
	"Беляева",
	"Тарасова",
	"Белова",
	"Комарова",
	"Орлова",
	"Киселева",
	"Макарова",
	"Андреева",
];
const maleSurname = [
	"Аврорин",
	"Аверин",
	"Агатов",
	"Адмиралов",
	"Александров",
	"Амурский",
	"Ангельский",
	"Анненский",
	"Апраскин",
	"Арбатов",
	"Аренский",
	"Арский",
	"Аристов",
	"Арбенин",
	"Арский",
	"Астров",
	"Ахматов",
	"Афинский",
	"Багиров",
	"Барон",
	"Бакурин",
	"Бартелеманов",
	"Балановский",
	"Белинский",
	"Белоградский",
	"Бельский",
	"Березов",
	"Беркутов",
	"Бестужев",
	"Богословский",
	"Богатырев",
	"Болконский",
	"Борисоглебский",
	"Булгаков",
	"Вавилов",
	"Ваганков",
	"Васнецов",
	"Валевский",
	"Варламов",
	"Варшавский",
	"Вельтистов",
	"Величанский",
	"Вепрев",
	"Вердеревский",
	"Верещагин",
	"Венидиктов",
	"Вертинский",
	"Верховский",
	"Ветров",
	"Вертоградский",
	"Виноградов",
	"Византийский",
	"Виленский",
	"Владимирский",
	"Воскресенский",
	"Воскобойников",
	"Воронов",
	"Вревский",
	"Всеволожский",
	"Вьюгин",
	"Гаранин",
	"Гедианов",
	"Гиляровский",
	"Глаголев",
	"Глинский",
	"Глебов",
	"Глинка",
	"Гмарь",
	"Гончаров",
	"Градов",
	"Гранин",
	"Граф",
	"Грацианский",
	"Грацианов",
	"Гронский",
	"Данилевский",
	"Данилов",
	"Долинин",
	"Доманский",
	"Донской",
	"Дмитриев",
	"Езерский",
	"Жемчугов",
	"Златогорский",
	"Златопольский",
	"Златоумов",
	"Илларионов",
	"Книжник",
	"Ковалевский",
	"Король",
	"Крымский",
	"Ладомирский",
	"Лазарев",
	"Ланской",
	"Ларин",
	"Лебединский",
	"Левин Литвинов",
	"Лозинский",
	"Льдов",
	"Малиновский",
	"Медведев",
	"Метельский",
	"Мещерский",
	"Михайловский",
	"Новгородский",
	"Новодворский",
	"Облонский",
	"Оболенский",
	"Огинский",
	"Огнецвет",
	"Одоевский",
	"Озеров",
	"Олейник",
	"Орлан",
	"Орловский",
	"Островский",
	"Парижский",
	"Печерский",
	"Покровский",
	"Полонский",
	"Преображенский",
	"Радов",
	"Райский",
	"Ржевский",
	"Родионов",
	"Розин",
	"Романовский",
	"Савранский",
	"Свиридов",
	"Северский",
	"Северянин",
	"Сибирский",
	"Славинский",
	"Смирнов",
	"Соболев",
	"Соколовский",
	"Соллертинский",
	"Спасский",
	"Сперанский",
	"Сталь",
	"Таран",
	"Тарасов",
	"Троицкий",
	"Трубецкой",
	"Уваров",
	"Уманский",
	"Филатов",
	"Фирсов",
	"Флоринский",
	"Хмелевский",
	"Чернышевский",
	"Чесменский",
	"Шахов",
	"Шереметев",
	"Шестинский",
	"Эллинский",
	"Эльбрусский",
	"Эмин",
	"Юлианский",
	"Юматов",
	"Юрьев",
	"Ягужинский",
	"Ямпольский",
	"Яров",
	"Яропольский",
];

const femaleName = [
	"Анастасия",
	"Анна",
	"Мария",
	"Елена	",
	"Дарья",
	"Алина",
	"Ирина",
	"Екатерина",
	"Арина",
	"Полина",
	"Ольга",
	"Юлия",
	"Татьяна",
	"Наталья",
	"Виктория",
	"Елизавета",
	"Ксения",
	"Милана",
	"Вероника",
	"Алиса",
	"Валерия",
	"Александра",
	"Ульяна",
	"Кристина",
	"София",
];
const maleName = [
	"Александр",
	"Дмитрий",
	"Максим",
	"Сергей",
	"Андрей",
	"Алексей",
	"Артём",
	"Илья",
	"Кирилл",
	"Михаил",
	"Никита",
	"Матвей",
	"Роман",
	"Егор",
	"Арсений",
	"Иван",
	"Денис",
	"Евгений",
	"Даниил",
	"Тимофей",
	"Владислав",
	"Игорь",
	"Владимир",
	"Павел",
	"Руслан",
];

const maleFatherName = [
	"Александрович",
	"Алексеевич",
	"Анатольевич",
	"Андреевич",
	"Антонович",
	"Аркадьевич",
	"Арсеньевич",
	"Артемович",
	"Афанасьевич",
	"Богданович",
	"Борисович",
	"Вадимович",
	"Валентинович",
	"Валериевич",
	"Васильевич",
	"Викторович",
	"Витальевич",
	"Владимирович",
	"Всеволодович",
	"Вячеславович",
	"Гаврилович",
	"Геннадиевич",
	"Георгиевич",
	"Глебович",
	"Григорьеви",
	"Давыдович",
	"Данилович",
	"Денисович",
	"Дмитриевич",
	"Евгеньевич",
	"Егорович",
	"Емельянович",
	"Ефимович",
	"Иванович",
	"Игоревич",
	"Ильич",
	"Иосифович",
	"Кириллович",
	"Константинович",
	"Леонидович",
	"Львович",
	"Макарович",
	"Максимович",
	"Маркович",
	"Матвеевич",
	"Митрофанович",
	"Михайлович",
	"Назарович",
	"Наумович",
	"Николаевич",
	"Олегович",
	"Павлович",
	"Петрович",
	"Платонович",
	"Робертович",
	"Родионович",
	"Романович",
	"Савельевич",
	"Семенович",
	"Сергеевич",
	"Станиславович",
	"Степанович",
	"Тарасович",
	"Тимофеевич",
	"Тихонович",
	"Федорович",
	"Феликсович",
	"Филиппович",
	"Эдуардович",
	"Юрьевич",
	"Яковлевич",
	"Ярославович",
];
const femaleFatherName = [
	"Александровна",
	"Алексеевна",
	"Анатольевна",
	"Андреевна",
	"Антоновна",
	"Аркадьевна",
	"Арсеньевна",
	"Афанасьевна",
	"Богдановна",
	"Борисовна",
	"Валентиновна",
	"Валериевна",
	"Васильевна",
	"Викторовна",
	"Владимировна",
	"Владиславовна",
	"Вячеславовна",
	"Геннадиевна",
	"Георгиевна",
	"Григорьевна",
	"Даниловна",
	"Дмитриевна",
	"Евгеньевна",
	"Егоровна",
	"Ефимовна",
	"Ивановна",
	"Игоревна",
	"Ильинична",
	"Иосифовна",
	"Кирилловна",
	"Константиновна",
	"Леонидовна",
	"Львовна",
	"Максимовна",
	"Матвеевна",
	"Михайловна",
	"Николаевна",
	"Олеговна",
	"Павловна",
	"Петровна",
	"Платоновна",
	"Робертовна",
	"Романовна",
	"Семеновна",
	"Сергеевна",
	"Станиславовна",
	"Степановна",
	"Тарасовна",
	"Тимофеевна",
	"Федоровна",
	"Фелuксовна",
	"Филипповна",
	"Эдуардовна",
	"Юрьевна",
	"Яковлевна",
	"Ярославовна",
];

function generatePerson() {
	const male = Math.round(Math.random()) === 1;

	if (male) {
		return {
			name: maleName[Math.floor(Math.random() * (maleName.length - 1))],
			surname: maleSurname[Math.floor(Math.random() * (maleSurname.length - 1))],
			fatherName: maleFatherName[Math.floor(Math.random() * (maleFatherName.length - 1))],
			male: true,
		};
	}

	return {
		name: femaleName[Math.floor(Math.random() * (femaleName.length - 1))],
		surname: femaleSurname[Math.floor(Math.random() * (femaleSurname.length - 1))],
		fatherName: femaleFatherName[Math.floor(Math.random() * (femaleFatherName.length - 1))],
		male: false,
	};
}

const countries = [
	"https://www.countryflags.io/ua/flat/24.png",
	"https://www.countryflags.io/by/flat/24.png",
	"https://www.countryflags.io/kz/flat/24.png",
	"https://www.countryflags.io/ru/flat/24.png",
];

const schoolType = ["Школа", "Лицей"];
const resultTypes = ["Победитель", "Призёр", "Участник"];

export function generateData(schoolClass: number, resultType: number, size: number = 15) {
	const data: any[] = [];

	for (let j = 0; j < size; j++) {
		const person = generatePerson();
		const cl = schoolClass === 0 ? 4 + Math.floor(Math.random() * 7) : schoolClass;
		const rz = resultType === 0 ? resultTypes[Math.round(Math.random() * 2)] : resultTypes[resultType - 1];

		const countryRandom = Math.floor(Math.random() * 10);
		const country = countries[Math.min(3, countryRandom)];

		const appelationRandom = Math.random();
		const appelation =
			Math.floor(appelationRandom >= 0.3 && appelationRandom < 0.5 ? appelationRandom * 10 : 0) *
			(Math.random() > 0.5 ? 1 : -1);
		const score = 70 + Math.floor(Math.random() * 30);

		const item = {
			...person,
			id: 10001 + j,
			class: cl,
			classRange: cl >= 9 ? "9-11" : cl >= 6 ? "6-8" : "4-5",
			school: `${schoolType[Math.round(Math.random())]} №${Math.floor(1 + Math.random() * 100)}`,
			score,
			appelation,
			resultScore: score + appelation,
			result: rz,
			reference: 300000 + Math.floor(100000 * Math.random()),
			disabled: Math.random() * 10 > 7,
			hasCoach: Math.random() * 10 < 2,
			country,
		};

		data.push(item);
	}

	return data;
}
import React from "react";
import * as d3 from "d3";
import cn from "classnames";

export class HorizontalStackedChart extends React.Component<{
	data: any;
	className?: string;
	tooltipClassName?: string;
}> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		this.tooltip = d3
			.select("body")
			.append("div")
			.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
			.style("opacity", 0);

		this.createGraph(this.props.data);
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createGraph(this.props.data);
	}

	private createGraph(data: any) {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const margin = { top: 20, right: 20, bottom: 30, left: 40 };
		const width = +svg.attr("width") - margin.left - margin.right;
		const height = +svg.attr("height") - margin.top - margin.bottom;
		const g = svg.append("g").attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		const y = d3
			.scaleBand() // x = d3.scaleBand()
			.rangeRound([0, height]) // .rangeRound([0, width])
			.paddingInner(0.05)
			.align(0.1);

		const x = d3
			.scaleLinear() // y = d3.scaleLinear()
			.rangeRound([0, width]); // .rangeRound([height, 0]);

		const z = d3.scaleOrdinal().range(["#bcaf45", "#6ccacd", "#0a71b7"]);

		var keys = ["gold", "silver", "bronze"];

		// @ts-ignore
		y.domain(data.map(d => d.name));
		x.domain([
			0,
			// @ts-ignore
			d3.max(data, d => d.total),
		]).nice(); // y.domain...
		z.domain(keys);

		g.append("g")
			.attr("class", "y-axis")
			.attr("transform", "translate(0,0)") //  .attr("transform", "translate(0," + height + ")")
			.call(
				d3
					.axisLeft(y)
					.tickSize(-width)
					.tickFormat(d => d),
			); //   .call(d3.axisBottom(x));

		g.append("g")
			.attr("class", "x-axis")
			.attr("transform", "translate(0," + 0 + ")") // New line
			.call(
				d3
					.axisTop(x)
					.ticks(null, "s")
					.tickSize(-height),
			);

		const rects = g
			.append("g")
			.selectAll("g")
			.data(d3.stack().keys(keys)(data))
			.enter()
			.append("g")
			// @ts-ignore
			.attr("fill", d => z(d.key))
			.selectAll("rect")
			// @ts-ignore
			.data(d => d)
			.enter()
			.append("rect")
			// @ts-ignore
			.attr("y", d => y(d.data.name) + 5) //.attr("x", function(d) { return x(d.data.State); })
			// @ts-ignore
			.attr("x", d => x(d[0])) //.attr("y", function(d) { return y(d[1]); })
			// @ts-ignore
			.attr("width", d => x(d[1]) - x(d[0])) //.attr("height", function(d) { return y(d[0]) - y(d[1]); })
			.attr("height", y.bandwidth() - 10); //.attr("width", x.bandwidth());

		if (this.tooltip) {
			rects
				.on("mouseover", (e: any) => {
					this.tooltip
						.transition()
						.duration(200)
						.style("opacity", 1);
					this.tooltip
						.html(
							`<div class="chart-tooltip__header">${e.data.name}</div>
							<strong>${e.data.bronze.toFixed(0)} чел.</strong> участников<br/>
							<strong>${e.data.silver.toFixed(0)} чел.</strong> призёров<br/>
							<strong>${e.data.gold.toFixed(0)} чел.</strong> победителей
						`,
						)
						.style("left", d3.event.pageX + "px")
						.style("top", d3.event.pageY - 28 + "px");
				})
				.on("mouseout", () => {
					this.tooltip
						.transition()
						.duration(500)
						.style("opacity", 0);
				});
		}

		svg.selectAll(".y-axis .tick line").attr("transform", `translate(1, ${y.bandwidth() / 2})`);

		svg.selectAll(".x-axis .tick text").attr("dy", `-10`);
		svg.selectAll(".y-axis .tick text").attr("dx", `-10`);

		svg.append("text")
			.attr("class", "legend")
			.attr("x", 37)
			.attr("y", -20)
			.text(() => "(чел.)");
	}

	public render() {
		return (
			<svg ref={this.svgRef} width={510} height={540} className={this.props.className}>
				<g className="slices" />
			</svg>
		);
	}
}

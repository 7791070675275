export const steps = [
	{
		id: 4, // Заключительный
		schoolClasses: [
			{
				id: 9,
				exercises: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
				solutions: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
			},
			{
				id: 10,
				exercises: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
				solutions: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
			},
			{
				id: 11,
				exercises: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
				solutions: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
			},
		],
	},
	{
		id: 3, // Региональный
		schoolClasses: [
			{
				id: 9,
				exercises: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
				solutions: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
			},
			{
				id: 10,
				exercises: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
				solutions: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
			},
			{
				id: 11,
				exercises: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
				solutions: {
					files: [
						{
							id: 1,
							name: "",
							link: "",
						},
					],
					archiveLink: "",
				},
			},
		],
	},
];

export const fileList = [
	{
		schoolSubjectId: 1,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "88-tasks-engl-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-engl-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-tasks-engl-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-engl-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "88-ans-engl-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-engl-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-ans-engl-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-engl-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "88-tasks-engl-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-engl-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-tasks-engl-9-11-ustn-final-18-9.pdf  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-engl-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "88-ans-engl-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-engl-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-ans-engl-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-engl-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "88-tasks-engl-9-11-pism-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-engl-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-tasks-engl-9-11-ustn-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-engl-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "88-ans-engl-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-engl-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-ans-engl-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-engl-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-engl-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-engl-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-engl-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-engl-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-engl-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-engl-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-engl-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-engl-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-engl-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-engl-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-engl-9-11-pism-reg-18-9.mp3 ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-engl-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-engl-9-11-pism-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-engl-9-11-ustn-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-engl-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-engl-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-engl-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-engl-9-11-ustn-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-engl-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 2,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "88-tasks-astr-9-bltest-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-9-bltest-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-tasks-astr-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-9-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "88-tasks-astr-9-teor-final-18-9.pdf   ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "88-ans-astr-9-bltest-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-astr-9-bltest-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-ans-astr-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-astr-9-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "88-ans-astr-9-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-astr-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "88-tasks-astr-10-bltest-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-10-bltest-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-tasks-astr-10-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-10-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "88-tasks-astr-10-teor-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-10-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "88-ans-astr-11-bltest-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-11-bltest-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-ans-astr-11-prak-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-11-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "88-ans-astr-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "88-tasks-astr-11-bltest-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-11-bltest-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-tasks-astr-11-prak-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-11-teor-final-18-9.pdf",
								},
								{
									id: 3,
									name: "88-tasks-astr-11-teor-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/88-tasks-astr-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "88-ans-astr-11-bltest-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-astr-11-bltest-final-18-9.pdf",
								},
								{
									id: 2,
									name: "88-ans-astr-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-astr-11-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "88-ans-astr-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/88-ans-astr-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-astr-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-astr-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-astr-9-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-astr-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-astr-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-astr-10-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-astr-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-astr-11-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-astr-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-astr-11-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-astr-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%90%D1%81%D1%82%D1%80%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 3,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "77-tasks-biol-9-prak-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/77-tasks-biol-9-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "77-tasks-biol-9-teor-final-18-9.pdf  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/77-tasks-biol-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "77-ans-biol-9-teor-final-18-9.pdf  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/77-ans-biol-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "77-tasks-biol-10-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/77-tasks-biol-10-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "77-tasks-biol-10-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/77-tasks-biol-10-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "77-ans-biol-10-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/77-ans-biol-10-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "77-tasks-biol-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/77-tasks-biol-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "77-tasks-biol-11-teor-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/77-tasks-biol-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "77-ans-biol-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/77-ans-biol-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 10,
						exercises: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-biol-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-biol-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-biol-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-biol-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-biol-9-prak-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-biol-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-biol-9-teor_pa-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-biol-9-teor_pa-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-biol-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-biol-10-11-teor-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-biol-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-biol-11-prak-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-biol-10-11-teor_pa-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-biol-10-11-teor_pa-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-biol-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-biol-11-prak-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%91%D0%B8%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 4,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "82-tasks-geog-9-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "82-tasks-geog-9-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "82-tasks-geog-9-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "82-tasks-geog-9-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "82-tasks-geog-9-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "82-tasks-geog-9-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "82-tasks-geog-9-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "82-tasks-geog-9-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "82-tasks-geog-9-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/82-tasks-geog-9-11-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-geog-9-11-part3-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-geog-9-11-part3-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-geog-9-part1_2-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-geog-9-part1_2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-geog-9-11-part3-reg-18-9.pdf   ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-geog-9-11-part3-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-geog-9-part1_2-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-geog-9-part1_2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-geog-10-11-part1_2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-geog-10-11-part1_2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-geog-9-11-part3-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-geog-9-11-part3-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-geog-10-11-part1_2-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-geog-10-11-part1_2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-geog-9-11-part3-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-geog-9-11-part3-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-geog-10-11-part1_2-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-geog-10-11-part1_2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-geog-9-11-part3-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-geog-9-11-part3-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-geog-10-11-part1_2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-geog-10-11-part1_2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-geog-9-11-part3-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-geog-9-11-part3-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%93%D0%B5%D0%BE%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 5,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "73-tasks-iikt-9-11-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/73-tasks-iikt-9-11-final-18-9.pdf",
								},
								{
									id: 2,
									name: "ru-olymp-roi-2019-archive.zip",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/ru-olymp-roi-2019-archive.zip",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "73-ans-iikt-9-11-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/73-ans-iikt-9-11-final-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-iikt-9-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "73-tasks-iikt-9-11-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/73-tasks-iikt-9-11-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "73-ans-iikt-9-11-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/73-ans-iikt-9-11-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "73-tasks-iikt-9-11-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/73-tasks-iikt-9-11-final-18-9.pdf",
								},
								{
									id: 2,
									name: "ru-olymp-roi-2019-archive.zip",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/ru-olymp-roi-2019-archive.zip",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "73-ans-iikt-9-11-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/73-ans-iikt-9-11-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "ru-olymp-regional-2019-archive.zip ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/ru-olymp-regional-2019-archive.zip",
								},
								{
									id: 2,
									name: "task-iikt-9-11-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/task-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-iikt-9-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "ru-olymp-regional-2019-archive.zip  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/ru-olymp-regional-2019-archive.zip",
								},
								{
									id: 2,
									name: "task-iikt-9-11-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/task-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-iikt-9-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "ru-olymp-regional-2019-archive.zip  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/ru-olymp-regional-2019-archive.zip",
								},
								{
									id: 2,
									name: "task-iikt-9-11-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/task-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-iikt-9-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-iikt-9-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D0%BD%D1%84%D0%BE%D1%80%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 6,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "93-tasks-amxk-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/93-tasks-amxk-9-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "93-tasks-amxk-9-tur2-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/93-tasks-amxk-9-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "video-amxk-9-final-18-9.mp4",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/video-amxk-9-final-18-9.mp4",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "93-ans-amxk-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/93-ans-amxk-9-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "93-tasks-amxk-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/93-tasks-amxk-10-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "93-tasks-amxk-10-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/93-tasks-amxk-10-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "video-amxk-10-final-18-9.mp4 ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/video-amxk-10-final-18-9.mp4",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "93-ans-amxk-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/93-ans-amxk-10-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "93-ans-amxk-10-tur2-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/93-ans-amxk-10-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "93-tasks-amxk-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/93-tasks-amxk-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "93-tasks-amxk-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/93-tasks-amxk-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "video-amxk-11-final-18-9.mp4",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/video-amxk-11-final-18-9.mp4",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "93-ans-amxk-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/93-ans-amxk-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "93-ans-amxk-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/93-ans-amxk-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%A5%D0%9A%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-amxk-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-amxk-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-amxk-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-amxk-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-amxk-10-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-amxk-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-amxk-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-amxk-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-amxk-11-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-amxk-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-amxk-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-amxk-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BA%D1%83%D1%81%D1%81%D1%82%D0%B2%D0%BE%20%28%D0%9C%D0%9A%D0%A5%29/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 7,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "86-tasks-span-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "86-ans-span-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-span-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-ans-span-9-11-script-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-span-9-11-script-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "86-tasks-span-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "86-ans-span-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-span-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-ans-span-9-11-script-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-span-9-11-script-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "86-tasks-span-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "86-ans-span-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-span-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-ans-span-9-11-script-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-span-9-11-script-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-span-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-span-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "skript-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/skript-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-span-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-span-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-span-9-11-ustn-reg-18-9.pdf  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-span-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-span-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-span-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "skript-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/skript-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-span-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-span-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-span-9-11-ustn-reg-18-9.pdf  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-span-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-span-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-span-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "skript-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/skript-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-span-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-span-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-span-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-span-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-span-9-11-ustn-reg-18-9.pdf  ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-span-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D0%BF%D0%B0%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 8,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "84-tasks-hist-9-11-tur1-esse-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-9-11-tur1-esse-final-18-9.pdf",
								},
								{
									id: 2,
									name: "84-tasks-hist-9-tur1-proect-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-9-tur1-proect-final-18-9.pdf",
								},
								{
									id: 3,
									name: "84-tasks-hist-9-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-9-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "84-ans-hist-9-tur1-proect-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-9-tur1-proect-final-18-9.pdf",
								},
								{
									id: 2,
									name: "84-ans-hist-9-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-9-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "84-tasks-hist-10-11-tur1-proect-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-10-11-tur1-proect-final-18-9.pdf",
								},
								{
									id: 2,
									name: "84-tasks-hist-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-10-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "84-tasks-hist-9-11-tur1-esse-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-9-11-tur1-esse-final-18-9.pdf",
								},
								{
									id: 4,
									name: "video-hist-10-11-tur2-final-18-9.mp4",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/video-hist-10-11-tur2-final-18-9.mp4",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "84-ans-hist-10-11-tur1-proect-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-10-11-tur1-proect-final-18-9.pdf",
								},
								{
									id: 2,
									name: "84-ans-hist-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-10-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "84-tasks-hist-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-10-11-tur2-final-18-9.pdf",
								},
								{
									id: 2,
									name: "84-tasks-hist-9-11-tur1-esse-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/84-tasks-hist-9-11-tur1-esse-final-18-9.pdf",
								},
								{
									id: 3,
									name: "video-hist-10-11-tur2-final-18-9.mp4",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/video-hist-10-11-tur2-final-18-9.mp4",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "84-ans-hist-10-11-tur1-proect-final-18-9 (1).pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-10-11-tur1-proect-final-18-9%20%281%29.pdf",
								},
								{
									id: 2,
									name: "84-ans-hist-10-11-tur1-proect-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-10-11-tur1-proect-final-18-9.pdf",
								},
								{
									id: 3,
									name: "84-ans-hist-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/84-ans-hist-10-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-hist-9-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-hist-9-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-hist-9-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-hist-9-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-hist-9-tur1-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-hist-9-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-hist-9-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-hist-9-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-hist-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-hist-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-hist-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-hist-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-hist-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-hist-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-hist-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-hist-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-hist-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-hist-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-hist-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-hist-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-hist-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-hist-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-hist-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-hist-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%81%D1%82%D0%BE%D1%80%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 9,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "5302-tasks-ital-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5302-tasks-ital-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5302-tasks-ital-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5302-tasks-ital-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "5302-ans-ital-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5302-ans-ital-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5302-ans-ital-9-11-script-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5302-ans-ital-9-11-script-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "5302-tasks-ital-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5302-tasks-ital-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5302-tasks-ital-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5302-tasks-ital-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "5302-ans-ital-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5302-ans-ital-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5302-ans-ital-9-11-script-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5302-ans-ital-9-11-script-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "5302-tasks-ital-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5302-tasks-ital-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5302-tasks-ital-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5302-tasks-ital-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "5302-ans-ital-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5302-ans-ital-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5302-ans-ital-9-11-script-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5302-ans-ital-9-11-script-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-ital-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-ital-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-ital-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ital-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-ital-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ital-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-ital-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-ital-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-ital-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ital-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-ital-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ital-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-ital-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-ital-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-ital-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ital-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-ital-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ital-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-ital-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ital-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%98%D1%82%D0%B0%D0%BB%D1%8C%D1%8F%D0%BD%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 10,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "5303-tasks-chin-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5303-tasks-chin-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5303-tasks-chin-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5303-tasks-chin-9-11-ustn-final-18-9.pdf",
								},
								{
									id: 3,
									name: "89-tasks-chin-9-11-audio-final-18-9.MP3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-chin-9-11-audio-final-18-9.MP3",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "5303-ans-chin-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5303-ans-chin-9-11-pism-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "5303-tasks-chin-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5303-tasks-chin-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5303-tasks-chin-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5303-tasks-chin-9-11-ustn-final-18-9.pdf",
								},
								{
									id: 3,
									name: "89-tasks-chin-9-11-audio-final-18-9.MP3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-chin-9-11-audio-final-18-9.MP3",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "5303-ans-chin-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5303-ans-chin-9-11-pism-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "5303-tasks-chin-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5303-tasks-chin-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "5303-tasks-chin-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/5303-tasks-chin-9-11-ustn-final-18-9.pdf",
								},
								{
									id: 3,
									name: "89-tasks-chin-9-11-audio-final-18-9.MP3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-chin-9-11-audio-final-18-9.MP3",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "5303-ans-chin-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/5303-ans-chin-9-11-pism-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-chin-9-11-pism-reg-18-9.MP3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-chin-9-11-pism-reg-18-9.MP3",
								},
								{
									id: 2,
									name: "script-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-chin-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chin-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-chin-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chin-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-chin-9-11-pism-reg-18-9.MP3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-chin-9-11-pism-reg-18-9.MP3",
								},
								{
									id: 2,
									name: "script-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-chin-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chin-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-chin-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chin-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-chin-9-11-pism-reg-18-9.MP3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-chin-9-11-pism-reg-18-9.MP3",
								},
								{
									id: 2,
									name: "script-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-chin-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chin-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-chin-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chin-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-chin-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chin-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9A%D0%B8%D1%82%D0%B0%D0%B9%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 11,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "83-tasks-litr-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/83-tasks-litr-10-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "83-tasks-litr-10-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/83-tasks-litr-10-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "83-tasks-litr-10-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/83-tasks-litr-10-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "83-ans-litr-9-11-tur1-krit-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/83-ans-litr-9-11-tur1-krit-final-18-9.pdf",
								},
								{
									id: 2,
									name: "83-ans-litr-9-11-tur2-krit-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/83-ans-litr-9-11-tur2-krit-final-18-9.pdf",
								},
								{
									id: 3,
									name: "83-ans-litr-9-11-tur3-krit-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/83-ans-litr-9-11-tur3-krit-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "83-tasks-litr-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/83-tasks-litr-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "83-tasks-litr-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/83-tasks-litr-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "83-tasks-litr-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/83-tasks-litr-11-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "83-ans-litr-9-11-tur1-krit-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/83-ans-litr-9-11-tur1-krit-final-18-9.pdf",
								},
								{
									id: 2,
									name: "83-ans-litr-9-11-tur2-krit-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/83-ans-litr-9-11-tur2-krit-final-18-9.pdf",
								},
								{
									id: 3,
									name: "83-ans-litr-9-11-tur3-krit-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/83-ans-litr-9-11-tur3-krit-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-litr-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-litr-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-litr-9-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-litr-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-litr-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-litr-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-litr-10-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-litr-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-litr-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-litr-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-litr-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-litr-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9B%D0%B8%D1%82%D0%B5%D1%80%D0%B0%D1%82%D1%83%D1%80%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 12,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "72-tasks-math-10-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/72-tasks-math-10-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "72-ans-math-10-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/72-ans-math-10-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "72-tasks-math-11-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/72-tasks-math-11-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "72-ans-math-11-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/72-ans-math-11-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-math-10-day1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-math-10-day1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-math-10-day2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-math-10-day2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-math-10-day1-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-math-10-day1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-math-10-day2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-math-10-day2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-math-11-day1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-math-11-day1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-math-11-day2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-math-11-day2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-math-11-day1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-math-11-day1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-math-11-day2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-math-11-day2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9C%D0%B0%D1%82%D0%B5%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 13,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "98-tasks-germ-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/98-tasks-germ-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "98-tasks-germ-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/98-tasks-germ-9-11-ustn-final-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-germ-9-11-audio-final-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-audio-final-18-9.mp3",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "98-ans-germ-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/98-ans-germ-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "98-ans-germ-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/98-ans-germ-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "98-tasks-germ-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/98-tasks-germ-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "98-tasks-germ-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/98-tasks-germ-9-11-ustn-final-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-germ-9-11-audio-final-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-audio-final-18-9.mp3",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "98-ans-germ-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/98-ans-germ-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "98-ans-germ-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/98-ans-germ-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "98-tasks-germ-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/98-tasks-germ-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "98-tasks-germ-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/98-tasks-germ-9-11-ustn-final-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-germ-9-11-audio-final-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-audio-final-18-9.mp3",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "98-ans-germ-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/98-ans-germ-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "98-ans-germ-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/98-ans-germ-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-germ-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-germ-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-germ-9-11-ustn-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-germ-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-germ-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-germ-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-germ-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-germ-9-11-ustn-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-germ-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-germ-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-germ-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-germ-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-germ-9-11-ustn-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-germ-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-germ-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-germ-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-germ-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-germ-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9D%D0%B5%D0%BC%D0%B5%D1%86%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 14,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "91-tasks-bsvf-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/91-tasks-bsvf-9-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "91-tasks-bsvf-9-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/91-tasks-bsvf-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "91-ans-bsvf-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/91-ans-bsvf-9-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "91-ans-bsvf-9-teor-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/91-ans-bsvf-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "91-tasks-bsvf-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/91-tasks-bsvf-10-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "91-tasks-bsvf-10-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/91-tasks-bsvf-10-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "91-ans-bsvf-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/91-ans-bsvf-10-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "91-ans-bsvf-10-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/91-ans-bsvf-10-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "91-tasks-bsvf-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/91-tasks-bsvf-10-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "91-tasks-bsvf-10-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/91-tasks-bsvf-10-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "91-ans-bsvf-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/91-ans-bsvf-10-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "91-ans-bsvf-10-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/91-ans-bsvf-10-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-bsvf-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-bsvf-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-bsvf-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-bsvf-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-bsvf-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-bsvf-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-bsvf-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-bsvf-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-bsvf-10-11-prak-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-bsvf-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-bsvf-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-bsvf-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-bsvf-10-11-prak-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-bsvf-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-bsvf-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-bsvf-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-bsvf-10-11-prak-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-bsvf-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-bsvf-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-bsvf-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-bsvf-10-11-prak-reg-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-bsvf-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-bsvf-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-bsvf-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%91%D0%96/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 15,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "85-tasks-soci-9-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-11-tur2-final-18-9.pdf",
								},
								{
									id: 2,
									name: "85-tasks-soci-9-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-11-tur3-final-18-9.pdf",
								},
								{
									id: 3,
									name: "85-tasks-soci-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "85-tasks-soci-10-tur1-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-10-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "85-tasks-soci-9-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "85-tasks-soci-9-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-11-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "85-tasks-soci-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "85-tasks-soci-9-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-11-tur2-final-18-9.pdf",
								},
								{
									id: 3,
									name: "85-tasks-soci-9-11-tur3-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/85-tasks-soci-9-11-tur3-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-soci-9-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-soci-9-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-soci-9-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-soci-9-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-soci-9-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-soci-10-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-soci-10-tur2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-soci-9-11-tur1-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-soci-10-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-soci-10-tur2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-soci-9-11-tur1-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-soci-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-soci-11-tur2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-soci-9-11-tur1-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-soci-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-soci-11-tur2-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-soci-9-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-soci-9-11-tur1-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9E%D0%B1%D1%89%D0%B5%D1%81%D1%82%D0%B2%D0%BE%D0%B7%D0%BD%D0%B0%D0%BD%D0%B8%D0%B5/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 16,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "87-tasks-law-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/87-tasks-law-9-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "87-ans-law-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/87-ans-law-9-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "87-tasks-law-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/87-tasks-law-10-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "87-ans-law-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/87-ans-law-10-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "87-tasks-law-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/87-tasks-law-11-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "87-ans-law-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/87-ans-law-11-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-law-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-law-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-law-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-law-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-law-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-law-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-law-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-law-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-law-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-law-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-law-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-law-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%9F%D1%80%D0%B0%D0%B2%D0%BE/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 17,
		year: 2018,
		steps: [
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-russ-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-russ-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-russ-9-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-russ-9-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-russ-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-russ-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-russ-10-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-russ-10-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-russ-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-russ-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-russ-11-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-russ-11-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 18,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "92-tasks-tech-9-prak-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-9-prak-kd-final-18-9.pdf",
								},
								{
									id: 2,
									name: "92-tasks-tech-9-prak-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-9-prak-titt-final-18-9.pdf",
								},
								{
									id: 3,
									name: "92-tasks-tech-9-teor-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-9-teor-kd-final-18-9.pdf",
								},
								{
									id: 4,
									name: "92-tasks-tech-9-teor-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-9-teor-titt-final-18-9.pdf",
								},
								{
									id: 5,
									name: "92-tasks-tech3D-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech3D-9-prak-final-18-9.pdf",
								},
								{
									id: 6,
									name: "92-tasks-techrobo-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-techrobo-9-prak-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "92-ans-tech-9-prak-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-9-prak-kd-final-18-9.pdf",
								},
								{
									id: 2,
									name: "92-ans-tech-9-prak-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-9-prak-titt-final-18-9.pdf",
								},
								{
									id: 3,
									name: "92-ans-tech-9-teor-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-9-teor-kd-final-18-9.pdf",
								},
								{
									id: 4,
									name: "92-ans-tech-9-teor-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-9-teor-titt-final-18-9.pdf",
								},
								{
									id: 5,
									name: "92-ans-tech3D-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech3D-9-prak-final-18-9.pdf",
								},
								{
									id: 6,
									name: "92-ans-techrobo-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-techrobo-9-prak-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "92-tasks-tech-10-11-prak-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-prak-kd-final-18-9.pdf",
								},
								{
									id: 2,
									name: "92-tasks-tech-10-11-prak-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-prak-titt-final-18-9.pdf",
								},
								{
									id: 3,
									name: "92-tasks-tech-10-11-teor-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-teor-kd-final-18-9.pdf",
								},
								{
									id: 4,
									name: "92-tasks-tech-10-11-teor-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-teor-titt-final-18-9.pdf",
								},
								{
									id: 5,
									name: "92-tasks-tech3D-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech3D-10-11-prak-final-18-9.pdf",
								},
								{
									id: 6,
									name: "92-tasks-techrobo-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-techrobo-10-11-prak-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "92-ans-tech-10-11-prak-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-prak-kd-final-18-9.pdf",
								},
								{
									id: 2,
									name: "92-ans-tech-10-11-prak-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-prak-titt-final-18-9.pdf",
								},
								{
									id: 3,
									name: "92-ans-tech-10-11-teor-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-teor-kd-final-18-9.pdf",
								},
								{
									id: 4,
									name: "92-ans-tech-10-11-teor-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-teor-titt-final-18-9.pdf",
								},
								{
									id: 5,
									name: "92-ans-tech3D-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech3D-10-11-prak-final-18-9.pdf",
								},
								{
									id: 6,
									name: "92-ans-techrobo-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-techrobo-10-11-prak-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "92-tasks-tech-10-11-prak-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-prak-kd-final-18-9.pdf",
								},
								{
									id: 2,
									name: "92-tasks-tech-10-11-prak-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-prak-titt-final-18-9.pdf",
								},
								{
									id: 3,
									name: "92-tasks-tech-10-11-teor-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-teor-kd-final-18-9.pdf",
								},
								{
									id: 4,
									name: "92-tasks-tech-10-11-teor-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech-10-11-teor-titt-final-18-9.pdf",
								},
								{
									id: 5,
									name: "92-tasks-tech3D-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-tech3D-10-11-prak-final-18-9.pdf",
								},
								{
									id: 6,
									name: "92-tasks-techrobo-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/92-tasks-techrobo-10-11-prak-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "92-ans-tech-10-11-prak-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-prak-kd-final-18-9.pdf",
								},
								{
									id: 2,
									name: "92-ans-tech-10-11-prak-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-prak-titt-final-18-9.pdf",
								},
								{
									id: 3,
									name: "92-ans-tech-10-11-teor-kd-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-teor-kd-final-18-9.pdf",
								},
								{
									id: 4,
									name: "92-ans-tech-10-11-teor-titt-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech-10-11-teor-titt-final-18-9.pdf",
								},
								{
									id: 5,
									name: "92-ans-tech3D-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-tech3D-10-11-prak-final-18-9.pdf",
								},
								{
									id: 6,
									name: "92-ans-techrobo-10-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/92-ans-techrobo-10-11-prak-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-techhome-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techhome-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-techhome-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techhome-9-teor-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-techtech-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techtech-9-prak-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-techtech-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techtech-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-techhome-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techhome-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-techhome-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techhome-9-teor-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "ans-techtech-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techtech-9-prak-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "ans-techtech-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techtech-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-techhome-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techhome-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-techhome-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techhome-10-11-teor-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-techtech-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techtech-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-techtech-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techtech-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink: "",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-techhome-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techhome-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-techhome-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techhome-10-11-teor-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "ans-techtech-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techtech-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "ans-techtech-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techtech-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-techhome-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techhome-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-techhome-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techhome-10-11-teor-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-techtech-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techtech-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-techtech-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-techtech-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink: "",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-techhome-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techhome-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-techhome-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techhome-10-11-teor-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "ans-techtech-10-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techtech-10-11-prak-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "ans-techtech-10-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-techtech-10-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A2%D0%B5%D1%85%D0%BD%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 19,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "74-tasks-phys-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/74-tasks-phys-9-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "74-tasks-phys-9-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/74-tasks-phys-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "74-ans-phys-9-prak-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/74-ans-phys-9-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "74-ans-phys-9-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/74-ans-phys-9-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "74-tasks-phys-10-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/74-tasks-phys-10-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "74-tasks-phys-10-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/74-tasks-phys-10-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "74-ans-phys-10-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/74-ans-phys-10-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "74-ans-phys-10-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/74-ans-phys-10-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "74-tasks-phys-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/74-tasks-phys-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "74-tasks-phys-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/74-tasks-phys-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "74-ans-phys-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/74-ans-phys-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "74-ans-phys-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/74-ans-phys-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-phys-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-phys-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-phys-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-phys-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-phys-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-phys-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-phys-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-phys-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-phys-10-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-phys-10-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-phys-10-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-phys-10-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-phys-10-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-phys-10-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-phys-10-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-phys-10-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-phys-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-phys-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-phys-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-phys-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-phys-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-phys-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-phys-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-phys-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D0%BA%D0%B0/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 20,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "94-tasks-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/94-tasks-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "94-tasks-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/94-tasks-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "94-ans-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/94-ans-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "94-tasks-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/94-tasks-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "94-tasks-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/94-tasks-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "94-ans-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/94-ans-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "94-tasks-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/94-tasks-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "94-tasks-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/94-tasks-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "94-ans-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/94-ans-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-pcul-9-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-pcul-9-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-pcul-9-11-teor-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-pcul-9-11-teor-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D0%B8%D0%B7%D0%B8%D1%87%D0%B5%D1%81%D0%BA%D0%B0%D1%8F%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 21,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "89-tasks-fren-9-11-audio-final-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-audio-final-18-9.mp3",
								},
								{
									id: 2,
									name: "89-tasks-fren-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-pism-final-18-9.pdf",
								},
								{
									id: 3,
									name: "89-tasks-fren-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "89-ans-fren-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/89-ans-fren-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "89-ans-fren-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/89-ans-fren-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "89-tasks-fren-9-11-audio-final-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-audio-final-18-9.mp3",
								},
								{
									id: 2,
									name: "89-tasks-fren-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-pism-final-18-9.pdf",
								},
								{
									id: 3,
									name: "89-tasks-fren-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "89-ans-fren-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/89-ans-fren-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "89-ans-fren-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/89-ans-fren-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "89-tasks-fren-9-11-audio-final-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-audio-final-18-9.mp3",
								},
								{
									id: 2,
									name: "89-tasks-fren-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-pism-final-18-9.pdf",
								},
								{
									id: 3,
									name: "89-tasks-fren-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/89-tasks-fren-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "89-ans-fren-9-11-pism-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/89-ans-fren-9-11-pism-final-18-9.pdf",
								},
								{
									id: 2,
									name: "89-ans-fren-9-11-ustn-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/89-ans-fren-9-11-ustn-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-fren-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-fren-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-fren-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-fren-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-fren-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-fren-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-fren-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-fren-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-fren-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-fren-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-fren-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-fren-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "audio-fren-9-11-pism-reg-18-9.mp3",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/audio-fren-9-11-pism-reg-18-9.mp3",
								},
								{
									id: 2,
									name: "script-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/script-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 3,
									name: "tasks-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 4,
									name: "tasks-fren-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-fren-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-fren-9-11-pism-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-fren-9-11-pism-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-fren-9-11-ustn-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-fren-9-11-ustn-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A4%D1%80%D0%B0%D0%BD%D1%86%D1%83%D0%B7%D1%81%D0%BA%D0%B8%D0%B9%20%D1%8F%D0%B7%D1%8B%D0%BA/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 22,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-chem-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chem-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-chem-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chem-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-chem-9-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chem-9-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-chem-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chem-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "76-tasks-chem-10-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-10-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "76-tasks-chem-10-teor1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-10-teor1-final-18-9.pdf",
								},
								{
									id: 3,
									name: "76-tasks-chem-9-11-teor2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-9-11-teor2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "76-ans-chem-10-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-10-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "76-ans-chem-10-teor1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-10-teor1-final-18-9.pdf",
								},
								{
									id: 3,
									name: "76-ans-chem-9-11-teor2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-9-11-teor2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "76-tasks-chem-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "76-tasks-chem-11-teor1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-11-teor1-final-18-9.pdf",
								},
								{
									id: 3,
									name: "76-tasks-chem-9-11-teor2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-9-11-teor2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "76-ans-chem-11-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-11-prak-final-18-9.pdf",
								},
								{
									id: 2,
									name: "76-ans-chem-11-teor1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-11-teor1-final-18-9.pdf",
								},
								{
									id: 3,
									name: "76-ans-chem-9-11-teor2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-9-11-teor2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "76-tasks-chem-9-11-teor2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-9-11-teor2-final-18-9.pdf",
								},
								{
									id: 2,
									name: "76-tasks-chem-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-9-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "76-tasks-chem-9-teor1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/76-tasks-chem-9-teor1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "76-ans-chem-9-11-teor2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-9-11-teor2-final-18-9.pdf",
								},
								{
									id: 2,
									name: "76-ans-chem-9-prak-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-9-prak-final-18-9.pdf",
								},
								{
									id: 3,
									name: "76-ans-chem-9-teor1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/76-ans-chem-9-teor1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-chem-10-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chem-10-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-chem-10-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chem-10-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-chem-10-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chem-10-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-chem-10-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chem-10-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-chem-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chem-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-chem-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-chem-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-chem-11-prak-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chem-11-prak-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-chem-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-chem-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%A5%D0%B8%D0%BC%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 23,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "78-tasks-ekol-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/78-tasks-ekol-9-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "78-ans-ekol-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/78-ans-ekol-9-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "78-tasks-ekol-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/78-tasks-ekol-10-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "78-ans-ekol-10-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/78-ans-ekol-10-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "78-tasks-ekol-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/78-tasks-ekol-11-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "78-ans-ekol-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/78-ans-ekol-11-tur1-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-ekol-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ekol-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-ekol-9-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ekol-9-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-ekol-10-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ekol-10-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-ekol-10-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ekol-10-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-ekol-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-ekol-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-ekol-11-teor-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-ekol-11-teor-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BB%D0%BE%D0%B3%D0%B8%D1%8F/11%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
	{
		schoolSubjectId: 24,
		year: 2018,
		steps: [
			{
				id: 4, // Заключительный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "86-tasks-econ-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/86-tasks-econ-9-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-tasks-econ-9-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/86-tasks-econ-9-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "86-ans-econ-9-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-econ-9-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-ans-econ-9-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-econ-9-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "86-tasks-econ-10-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/86-tasks-econ-10-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-tasks-econ-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/86-tasks-econ-10-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "86-ans-econ-10-11-tur1-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-econ-10-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-ans-econ-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-econ-10-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "86-tasks-econ-10-11-tur1-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/86-tasks-econ-10-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-tasks-econ-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/86-tasks-econ-10-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "86-ans-econ-10-11-tur1-final-18-9.pdf ",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-econ-10-11-tur1-final-18-9.pdf",
								},
								{
									id: 2,
									name: "86-ans-econ-10-11-tur2-final-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/86-ans-econ-10-11-tur2-final-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%97%D0%B0%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
			{
				id: 3, // Региональный
				schoolClasses: [
					{
						id: 9,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-econ-9-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-econ-9-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-econ-9-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-econ-9-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-econ-9-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-econ-9-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-econ-9-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-econ-9-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/9%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 10,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-econ-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-econ-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-econ-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-econ-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-econ-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-econ-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-econ-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-econ-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
					{
						id: 11,
						exercises: {
							files: [
								{
									id: 1,
									name: "tasks-econ-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-econ-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "tasks-econ-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/tasks-econ-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
						solutions: {
							files: [
								{
									id: 1,
									name: "ans-econ-10-11-tur1-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-econ-10-11-tur1-reg-18-9.pdf",
								},
								{
									id: 2,
									name: "ans-econ-10-11-tur2-reg-18-9.pdf",
									link:
										"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/ans-econ-10-11-tur2-reg-18-9.pdf",
								},
							],
							archiveLink:
								"https://olympic.dev-alex.org/files/%D0%97%D0%B0%D0%B4%D0%B0%D0%BD%D0%B8%D1%8F%20%D0%B8%20%D1%80%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%A0%D0%B5%D0%B3%D0%B8%D0%BE%D0%BD%D0%B0%D0%BB%D1%8C%D0%BD%D1%8B%D0%B9%20%D1%8D%D1%82%D0%B0%D0%BF/%D0%AD%D0%BA%D0%BE%D0%BD%D0%BE%D0%BC%D0%B8%D0%BA%D0%B0/10%20%D0%BA%D0%BB%D0%B0%D1%81%D1%81/%D0%A0%D0%B5%D1%88%D0%B5%D0%BD%D0%B8%D1%8F/%D0%B0%D1%80%D1%85%D0%B8%D0%B2.zip",
						},
					},
				],
			},
		],
	},
];

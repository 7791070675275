import React from "react";
import { federalSubjects, schoolSubjects, steps, years } from "../../../data";
import { DistributionDonutChart } from "./distribution-donut-chart";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function generateData() {
	return schoolSubjects.map(x => ({
		name: x.name,
		value: x.id * Math.random(),
	}));
}

export class SlideDistribution extends React.Component {
	public state = {
		data: generateData(),
		filters: {
			federalSubject: 0,
			period: 2018,
			step: 1,
		},
	};

	private updateData = () => {
		this.setState({
			data: generateData(),
		});
	};

	private handleFilterChange = (_filterId: string, _val: number, filters: any) => {
		this.updateData();

		this.setState({
			filters,
		});
	};

	public render() {
		const { data, filters } = this.state;
		const { federalSubject, step, period } = filters;

		data.sort((a, b) => -a.value + b.value);

		return (
			<div className="slide slide--gray">
				<div className="slide__top">04</div>
				<div className="slide-header">Распределение долей предметов</div>
				<div className="slide-filter">
					<FilterToolbarSet filterSet={FilterSet.Set8} onChange={this.handleFilterChange} />
				</div>
				<div className="slide-distribution-infographics">
					<div className="slide-distribution-infographics__chart">
						<DistributionDonutChart data={data} />
					</div>
					<div className="slide-distribution-infographics__description">
						<div className="slide-distribution-stats">
							В{" "}
							{federalSubject > 0 ? (
								<>
									<span className="slide-map-stats-strong">
										{" "}
										{federalSubjects.find(s => s.id === federalSubject)!.name}
									</span>{" "}
									на{" "}
								</>
							) : null}
							<span className="slide-map-stats-strong">{steps.find(s => s.id === step)!.name}</span> этапе
							Всероссийской олимпиады школьников{" "}
							<span className="slide-map-stats-strong">{years.find(y => y.id === period)!.name}</span> гг.
							наибольшее количество школьников соревновались по следующим предметам:
							<div className="slide-distribution-stats__row">
								<div className="slide-distribution-stats__header">{data[0].name}</div>
								<span className="slide-map-stats-strong">30000</span> человек, из них{" "}
								<span className="slide-map-stats-strong">90</span> призеров,{" "}
								<span className="slide-map-stats-strong">45</span> победителей
							</div>
							<div className="slide-distribution-stats__row">
								<div className="slide-distribution-stats__header">{data[1].name}</div>
								<span className="slide-map-stats-strong">27000</span> человек, из них{" "}
								<span className="slide-map-stats-strong">78</span> призеров,{" "}
								<span className="slide-map-stats-strong">38</span> победителей
							</div>
							<div className="slide-distribution-stats__row">
								<div className="slide-distribution-stats__header">{data[2].name}</div>
								<span className="slide-map-stats-strong">24000</span> человек, из них{" "}
								<span className="slide-map-stats-strong">76</span> призеров,{" "}
								<span className="slide-map-stats-strong">28</span> победителей
							</div>
						</div>
					</div>
				</div>
				<div className="slide__action">
					{/*<button className="button button--primary">Узнать больше</button>*/}
				</div>
			</div>
		);
	}
}

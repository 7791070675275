import React from "react";
import { BubbleChart } from "@components/figures/bubble-chart";
import { years } from "../../../../data";

const federalSubjects = [
	{ id: 10, name: "г. Москва" },
	{ id: 9, name: "г. Санкт-Петербург" },
	{ id: 8, name: "Республика Татарстан" },
	{ id: 7, name: "Московская область" },
	{ id: 6, name: "Челябинская область" },
	{ id: 5, name: "Новосибирская область" },
	{ id: 4, name: "Удмуртская республика" },
	{ id: 3, name: "Нижегородская область" },
	{ id: 2, name: "Кировская область" },
	{ id: 1, name: "Республика Мордовия" },
];

const data = federalSubjects.map(x => ({
	id: x.id,
	name: x.name,
	values: years.map(y => ({
		id: y.id,
		name: y.name,
		subj: x.name,
		value: Math.random() * 1000,
	})),
}));

function getTooltip(item: any) {
	return `<div>
			<div class="chart-tooltip__header">${item.subj} ${item.name}</div>
			<div><strong>${item.value.toFixed(0)} чел.</strong> победителей</div>
			<div><strong>${(20 + 60 * Math.random()).toFixed(0)} %</strong> доля от общего количества участников команды</div>
		</div>`;
}

export function Slide5() {
	return <BubbleChart className="chart--bubble-dark" data={data} getTooltip={getTooltip} />;
}

import React from "react";
import { Select2Dropdown } from "@components/select2-dropdown";
import { years } from "../../../../data";

enum FilterType {
	Year = 1,
	Period = 2,
}

export function AnalyticsFilter({
	filterType,
	year,
	fromYear,
	toYear,
	onYearChange,
	onFromYearChange,
	onToYearChange,
	onFilterTypeChange,
}: {
	filterType: FilterType;
	year: number;
	fromYear: number;
	toYear: number;
	onYearChange: (year: number) => void;
	onFromYearChange: (year: number) => void;
	onToYearChange: (year: number) => void;
	onFilterTypeChange: (filterType: FilterType) => void;
}) {
	return (
		<div className="analytics-filter">
			<div className="analytics-filter__types">
				Фильтр
				<label>
					<input
						type="checkbox"
						onChange={() => onFilterTypeChange(FilterType.Year)}
						checked={filterType === FilterType.Year}
					/>
					<span>Год</span>
				</label>
				<label>
					<input
						type="checkbox"
						onChange={() => onFilterTypeChange(FilterType.Period)}
						checked={filterType === FilterType.Period}
					/>{" "}
					<span>Период</span>
				</label>
			</div>
			{filterType === FilterType.Year && (
				<div>
					<Select2Dropdown
						onChange={onYearChange}
						selection={year}
						items={years}
						className="slide-filter__year"
					/>
				</div>
			)}
			{filterType === FilterType.Period && (
				<div>
					<span className="select-label">с</span>
					<Select2Dropdown
						onChange={onFromYearChange}
						selection={fromYear}
						items={years}
						className="slide-filter__year"
					/>

					<span className="select-label">по</span>
					<Select2Dropdown
						onChange={onToYearChange}
						selection={toYear}
						items={years}
						className="slide-filter__year"
					/>
				</div>
			)}
		</div>
	);
}

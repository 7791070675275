import React from "react";
import moment from "moment";
// @ts-ignore
import Calendar from "react-calendar";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";
import { Arrow } from "@icons/arrow";

moment.locale("ru");

const descriptionTypes = [
	"теоретический тур по ",
	"проектный тур по ",
	"публикация предварительных результатов по ",
	"прием заявок на показ работ по ",
	"показ работ, прием и рассмотрение апелляций по ",
	"публикация окончательных результатов по ",
];

const subjectTypes = [
	"английскому языку",
	"астрономии",
	"биологии",
	"географии",
	"информатики",
	"искусству (МХК)",
	"испанскому языку",
	"истории",
	"итальянскому языку",
	"китайскому языку",
	"литературе",
	"математике",
	"немецкому языку",
	"ОБЖ",
	"обществознанию",
	"праву",
	"русскому языку",
	"технологии",
	"физике",
	"физической культуре",
	"французскому языку",
	"химии",
	"экологии",
	"экономике",
];

function getMonthData() {
	const usedMonthDays = new Map<number, any[]>();

	for (let i = 0; i < 10; i++) {
		const day = Math.floor(Math.random() * 30);

		if (!usedMonthDays.has(day)) {
			const descriptions: any[] = [];

			usedMonthDays.set(day, descriptions);

			for (let j = 0; j < 6; j++) {
				const subjId = Math.floor(Math.random() * 23);

				const subjData = {
					subjId: subjId + 1,
					description: descriptionTypes[Math.floor(Math.random() * 6)] + subjectTypes[subjId],
				};

				descriptions.push(subjData);
			}
		}
	}

	return usedMonthDays;
}

function getYearData() {
	const yearData = new Map<number, any>();

	for (let i = 0; i < 12; i++) {
		yearData.set(i, getMonthData());
	}

	return yearData;
}

function MonthCalendar({
	data,
	date,
	onChange,
	filters,
}: {
	data: any;
	date: Date;
	onChange(date: Date | Date[]): void;
	filters: any;
}) {
	return (
		<div className="calendar">
			<div className="calendar__header">{moment(date).format("MMMM")}</div>
			<Calendar
				onChange={onChange}
				value={date}
				locale="ru-RU"
				tileClassName={({ date, view }: { date: Date; view: any }) => {
					if (view === "month") {
						const monthData = data.get(date.getMonth());

						if (monthData && monthData.has(date.getDate())) {
							const monthDataItems = monthData.get(date.getDate());

							if (
								filters.schoolSubject === 0 ||
								monthDataItems.find((item: any) => item.subjId === filters.schoolSubject)
							) {
								return "selected-day";
							}
						}
					}
					return null;
				}}
				showNavigation={false}
			/>
		</div>
	);
}

function generateData() {
	return getYearData();
}

function ScheduleDayDetails({ date, data, filters }: { date: Date; data: any; filters: any }) {
	const monthData = data.get(date.getMonth());

	if (!monthData || !monthData.has(date.getDate())) {
		return null;
	}

	const dayData = monthData.get(date.getDate());

	return (
		<div className="school-subject-competition-schedule">
			{dayData
				.filter((d: any) => filters.schoolSubject === 0 || d.subjId === filters.schoolSubject)
				.map((d: any, index: number) => (
					<div className="school-subject-competition-schedule__item" key={`${d.subjId}_${index}`}>
						<span className="school-subject-competition-schedule__date">
							{moment(date).format("DD MMMM")}
						</span>
						{d.description}
					</div>
				))}
		</div>
	);
}

export function CalendarSlide({ categoryId }: { categoryId: number }) {
	const [currentMonth, setCurrentMonth] = React.useState(new Date());
	const [nextMonth, setNextMonth] = React.useState(getNextMonth(currentMonth));
	const [selectedDate, setSelectedDate] = React.useState(new Date());
	const [filters, setFilters] = React.useState<any>({
		period: 2018,
		federalSubject: 0,
		municipality: 0,
		schoolSubject: 0,
	});
	const [data, setData] = React.useState(generateData());

	React.useEffect(() => {
		setData(generateData());
	}, [categoryId]);

	function handleDateChange(date: Date) {
		setSelectedDate(date);
	}

	function handleFilterChange(filterId: string, _value: any, filterItems: any) {
		setFilters(filterItems);

		if (filterId !== "schoolSubject") {
			setData(generateData());
		}
	}

	function getPrevMonth(date: Date): Date {
		return moment(date)
			.subtract(1, "months")
			.startOf("month")
			.toDate();
	}

	function getNextMonth(date: Date): Date {
		return moment(date)
			.add(1, "months")
			.startOf("month")
			.toDate();
	}

	function handleNavLeft() {
		const newDate = getPrevMonth(currentMonth);

		setCurrentMonth(newDate);
		setNextMonth(getNextMonth(newDate));
	}

	function handleNavRight() {
		const newDate = getNextMonth(currentMonth);

		setCurrentMonth(newDate);
		setNextMonth(getNextMonth(newDate));
	}

	return (
		<>
			<div>Идет муниципальный этап Всероссийской олимпиады школьников 2019/20</div>
			<ul>
				<li>Идут тренировочные туры по информатике (ссылка)</li>
				<li>Идет проверка работ муниципального этапа по химии</li>
				<li>Проходит олимпиада по английскому языку</li>
			</ul>

			<FilterToolbarSet filterSet={FilterSet.Set4} onChange={handleFilterChange} />

			<div className="table-slider">
				<div className="table-slider__navigation table-slider__navigation--left" onClick={handleNavLeft}>
					<Arrow className="table-slider__nav-icon table-slider__nav-icon--left" />
				</div>
				<div className="calendar-wrapper">
					<MonthCalendar date={currentMonth} onChange={handleDateChange} data={data} filters={filters} />
					<MonthCalendar date={nextMonth} onChange={handleDateChange} data={data} filters={filters} />
				</div>
				<div className="table-slider__navigation" onClick={handleNavRight}>
					<Arrow className="table-slider__nav-icon table-slider__nav-icon" />
				</div>
			</div>

			<ScheduleDayDetails date={selectedDate} data={data} filters={filters} />
		</>
	);
}

import React from "react";
import "select2";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";
import { SearchPanel } from "@components/search-panel";
import { news } from "../../data";
import { CloseIcon } from "@icons/close-icon";

export function News() {
	const urlParams = new URLSearchParams(window.location.search);
	const myParam = urlParams.get("id");
	const [selectedNewsId, setSelectedNews] = React.useState(myParam ? parseInt(myParam, 10) : null);

	function handleSelectNews(newsId: number) {
		return function() {
			setSelectedNews(newsId);
		};
	}

	function handleDeselect() {
		setSelectedNews(null);
	}

	const selectedNews = news.find(x => x.id === selectedNewsId);

	return (
		<>
			<div className="news-toolbar">
				<FilterToolbarSet filterSet={FilterSet.Set3} />
				<SearchPanel />
			</div>

			{selectedNews != null ? (
				<div className="news-item-selected" key={selectedNews.id}>
					<span onClick={handleDeselect}>
						<CloseIcon className="news-item-selected-close-icon" />
					</span>
					<div className="news-item__date">{selectedNews.date}</div>
					<div className="news-item__header news-item__header--selected">{selectedNews.name}</div>
					<div
						className="news-item-selected__content"
						dangerouslySetInnerHTML={{ __html: selectedNews.html }}
					/>
				</div>
			) : (
				news.map(x => (
					<div className="news-item" key={x.id}>
						<div className="news-item__date">{x.date}</div>
						<div className="news-item__header" onClick={handleSelectNews(x.id)}>
							{x.name}
						</div>
						<div className="news-item__content">
							<div className="news-item__teaser">{x.teaser}</div>
							<div className="news-item__link">
								<a className="button button--primary" onClick={handleSelectNews(x.id)}>
									Читать
								</a>
							</div>
						</div>
					</div>
				))
			)}
		</>
	);
}

import React from "react";
import { CurrentAnalyticsInformation } from "./current";
import { AnalyticsFilter } from "./shared/analytics-filter";
import { PastAnalyticsInformation } from "./past";
import { PeriodAnalyticsInformation } from "./period";
import { AnalyticsAnnotation } from "./shared/analytics-annotation";

export function AnalyticsInformation() {
	const [filter, setFilter] = React.useState(1);
	const [year, setYear] = React.useState(2019);
	const [fromYear, setFromYear] = React.useState(2017);
	const [toYear, setToYear] = React.useState(2018);
	const currentYear = 2019;

	function handleFilterTypeChange(filterType: number) {
		setFilter(filterType);
	}

	function handleYearChange(year: number) {
		setYear(year);
	}

	function handleFromYearChange(year: number) {
		setFromYear(year);
	}

	function handleToYearChange(year: number) {
		setToYear(year);
	}

	function getContent() {
		if (filter === 1) {
			return year === currentYear ? <CurrentAnalyticsInformation /> : <PastAnalyticsInformation />;
		}

		return <PeriodAnalyticsInformation />;
	}

	return (
		<>
			<AnalyticsFilter
				year={year}
				fromYear={fromYear}
				toYear={toYear}
				filterType={filter}
				onFilterTypeChange={handleFilterTypeChange}
				onFromYearChange={handleFromYearChange}
				onToYearChange={handleToYearChange}
				onYearChange={handleYearChange}
			/>

			<AnalyticsAnnotation years={filter === 1 ? [year] : [fromYear, toYear]} />

			{getContent()}
		</>
	);
}

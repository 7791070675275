export class LoginService {
	public static isLoggedIn() {
		const user = localStorage.getItem("user");

		return user != null;
	}

	public static getUser() {
		return localStorage.getItem("user");
	}

	public static login(email: string, password: string) {
		if (email === "admin" && password === "123") {
			localStorage.setItem("user", email);
			return true;
		}

		return false;
	}

	public static logout() {
		localStorage.clear();
	}
}

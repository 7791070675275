import React from "react";
import { DonutChart } from "@components/figures/donut-chart";
import { steps } from "../../../../data";
import { FilterToolbar } from "@components/filter-toolbar";

const dd = [{ id: 3, name: "Участники" }, { id: 2, name: "Призеры" }, { id: 1, name: "Победители" }];

function generateData() {
	let lastValue = 1000000;

	const res = dd.map((x, i) => {
		const base = lastValue / (i + 1);

		const value = base + base * Math.random();
		lastValue = value / 2;

		return {
			name: `${x.name} ВСОШ`,
			id: x.id,
			value,
		};
	});

	const total = res.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
	return res.map(x => ({
		...x,
		ratio: (x.value / total) * 100,
	}));
}

function generateData1() {
	let lastValue = 1000000;

	const res = dd.map((x, i) => {
		const base = lastValue / (i + 1);

		const value = base + base * Math.random();
		lastValue = value / 2;

		return {
			name: `${x.name} ВСОШ с ОВЗ`,
			id: x.id,
			value,
		};
	});

	const total = res.reduce((accumulator, currentValue) => accumulator + currentValue.value, 0);
	return res.map(x => ({
		...x,
		ratio: (x.value / total) * 100,
	}));
}

const filters = [
	{
		id: "step",
		name: "Этап олимпиады",
		items: [{ id: 0, name: "Этап олимпиады" }].concat(steps),
		value: 0,
	},
];

export function Slide4_1() {
	const [data1, setData1] = React.useState(generateData());
	const [data2, setData2] = React.useState(generateData1());
	const [data3, setData3] = React.useState(generateData());
	const [data4, setData4] = React.useState(generateData1());

	function handleFilterChange() {
		setData1(generateData());
		setData2(generateData1());
		setData3(generateData());
		setData4(generateData1());
	}

	return (
		<div className="analytics-period__slide-4 analytics-period__slide-4-1">
			<FilterToolbar filters={filters} onChange={handleFilterChange} />

			<div className="slide-4-rows">
				<div className="slide-4-row">
					<div className="slide-4-row__year">2016/2017</div>
					<div className="slide-4-row__chart">
						<div className="slide-4-chart-wrapper">
							<DonutChart
								className="slide-4-donut-chart"
								data={data1}
								colors={["#0f6fb8", "#6ecacd", "#bca93f"]}
								width={250}
								height={250}
							/>
							<div className="slide-4-row__chart-label">
								Участники
								<br />
								ВСОШ
							</div>
						</div>
						<div className="slide-4-chart-wrapper">
							<DonutChart
								className="slide-4-donut-chart"
								data={data2}
								colors={["#0f6fb8", "#6ecacd", "#bca93f"]}
								width={250}
								height={250}
							/>
							<div className="slide-4-row__chart-label">
								Участники
								<br />
								ВСОШ с ОВЗ
							</div>
						</div>
					</div>
				</div>
				<div className="slide-4-row">
					<div className="slide-4-row__year">2017/2018</div>
					<div className="slide-4-row__chart">
						<div className="slide-4-chart-wrapper">
							<DonutChart
								className="slide-4-donut-chart"
								data={data3}
								colors={["#0f6fb8", "#6ecacd", "#bca93f"]}
								width={250}
								height={250}
							/>
							<div className="slide-4-row__chart-label">
								Участники
								<br />
								ВСОШ
							</div>
						</div>
						<div className="slide-4-chart-wrapper">
							<DonutChart
								className="slide-4-donut-chart"
								data={data4}
								colors={["#0f6fb8", "#6ecacd", "#bca93f"]}
								width={250}
								height={250}
							/>
							<div className="slide-4-row__chart-label">
								Участники
								<br />
								ВСОШ с ОВЗ
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="horizontal-legends">
				<div className="horizontal-legends__label legend-label">
					<div className="legend-label__dot legend-label__dot--1" />
					<div>Победители</div>
				</div>
				<div className="horizontal-legends__label legend-label">
					<div className="legend-label__dot legend-label__dot--2" />
					<div>Призеры</div>
				</div>
				<div className="horizontal-legends__label legend-label">
					<div className="legend-label__dot legend-label__dot--3" />
					<div>Участники</div>
				</div>
			</div>
		</div>
	);
}

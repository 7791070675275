import React from "react";

export function StatInfoAllRegions({ year }: { year: string }) {
	return (
		<div className="slide-map-stats">
			<div className="slide-map-stats__header">
				Количество участников, призеров и победителей Всероссийской олимпиады школьников{" "}
				<span className="slide-map-stats-strong">{year}</span> гг
			</div>
			<div className="slide-map-stats__steps">
				<div className="slide-map-stats-step">
					<div className="slide-map-stats-step__header">Заключительный этап</div>
					<div className="slide-map-stats-step__value">участники ____ чел.</div>
					<div className="slide-map-stats-step__value">призеры ____ чел.</div>
					<div className="slide-map-stats-step__value">победители _____ чел.</div>
				</div>
				<div className="slide-map-stats-step">
					<div className="slide-map-stats-step__header">Региональный этап</div>
					<div className="slide-map-stats-step__value">участники ____ чел.</div>
					<div className="slide-map-stats-step__value">призеры ____ чел.</div>
					<div className="slide-map-stats-step__value">победители _____ чел.</div>
				</div>
				<div className="slide-map-stats-step">
					<div className="slide-map-stats-step__header">Муниципальный этап</div>
					<div className="slide-map-stats-step__value">участники ____ чел.</div>
					<div className="slide-map-stats-step__value">призеры ____ чел.</div>
					<div className="slide-map-stats-step__value">победители _____ чел.</div>
				</div>
				<div className="slide-map-stats-step">
					<div className="slide-map-stats-step__header">Школьный этап</div>
					<div className="slide-map-stats-step__value">участники ____ чел.</div>
					<div className="slide-map-stats-step__value">призеры ____ чел.</div>
					<div className="slide-map-stats-step__value">победители _____ чел.</div>
				</div>
			</div>
		</div>
	);
}

import React from "react";
import { AlphabetFilter } from "../../olympiads/shared/alphabet-filter";
import { SearchPanel } from "@components/search-panel";
import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../types";
import { PagerSize } from "@components/pager-size";
import { years } from "../../../data";
import cn from "classnames";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";
import { generateData } from "./generate-participants-data";

function generateInternationalData(resultType: number) {
	return [
		{
			id: 1,
			name: "Международная математическая олимпиада (IMO)",
			items: generateData(0, resultType, 3 + Math.floor(Math.random() * 3)),
		},
		{
			id: 2,
			name: "Международная биологическая олимпиада (IBO)",
			items: generateData(0, resultType, 3 + Math.floor(Math.random() * 3)),
		},
		// {
		// 	id: 3,
		// 	name: "Международная естественно-научная олимпиада (IJSO)",
		// },
	];
}

function getRowClassName(dataItem: any) {
	if (dataItem.result === "Призёр") {
		return cn("analytics-participants-table__row", "analytics-participants-table__row--prizer");
	}

	if (dataItem.result === "Победитель") {
		return cn("analytics-participants-table__row", "analytics-participants-table__row--winner");
	}

	return cn("analytics-participants-table__row", "analytics-participants-table__row--participant");
}

function Table({ data }: { data: any }) {
	return (
		<table className="analytics-participants-table">
			<tbody>
				<tr>
					<th>Олимпиада</th>
					<th>ФИО</th>
					<th>Регион</th>
					<th>
						Муниципальное <br /> образование
					</th>
					<th>
						Населенный
						<br /> пункт
					</th>
					<th>
						Учебное
						<br />
						заведение
					</th>
					<th>Результат</th>
				</tr>
				{data.map((root: any) =>
					root.items.map((d: any, index: number) => (
						<tr key={d.id} className={getRowClassName(d)}>
							{/*{index === 0 && <td rowSpan={root.items.length}>{root.name}</td>}*/}
							{index === 0 && <td>{root.name}</td>}
							{index > 0 && index < root.items.length - 1 && <td></td>}
							{index === root.items.length - 1 && <td></td>}
							<td>
								{d.surname}
								<br />
								{d.name} {d.fatherName}
							</td>
							<td>Москва</td>
							<td>Филевский парк</td>
							<td>Москва</td>
							<td>{d.school}</td>
							<td>{d.result}</td>
						</tr>
					)),
				)}

				<tr>
					<td>Международная естественно-научная олимпиада (IJSO)</td>
					<td colSpan={6} align="center">
						Олимпиада еще не проводилась
					</td>
				</tr>
			</tbody>
		</table>
	);
}

export function InternationalCompetitions() {
	const [filters, setFilter] = React.useState({
		period: 2018,
		result: 0,
	});

	const [data, setData] = React.useState(generateInternationalData(0));

	function handleFilter(_id: any, _value: any, filters: any) {
		setFilter(filters);

		setData(generateInternationalData(filters.result));
	}

	return (
		<>
			<div className="filter">
				<FilterToolbarSet filterSet={FilterSet.Set11} onChange={handleFilter} />
			</div>

			<div className="slide slide--indigo">
				<div className="analytics-participants-search">
					<AlphabetFilter />
					<SearchPanel />
				</div>

				<div className="analytics-participants-data">
					<div className="analytics-participants-data__annotation">
						<div className="analytics-participants-data__annotation-file">
							<FileDownloadLink type={FileType.Xls} text="Скачать таблицу" />
						</div>
						<p>
							Участники международных олимпиад{" "}
							<strong>{years.find(y => y.id === filters.period)!.name}</strong> года.
						</p>
					</div>
					<PagerSize />
					<Table data={data} />
					{/*<Pager />*/}
				</div>
			</div>
		</>
	);
}

import React from "react";
import cn from "classnames";

export class CategoriesPane extends React.Component<{
	items: Array<{ id: number; name: React.ReactNode }>;
	onSelect: (id: number) => void;
	selected: number;
}> {
	private handleSelect = (categoryId: number) => () => {
		this.props.onSelect(categoryId);
	};

	public render() {
		const { items, selected } = this.props;

		return (
			<div className="categories-pane-container">
				<div className="categories-pane">
					{items.map(x => (
						<div
							key={x.id}
							className={cn("categories-pane__item", {
								"categories-pane__item--selected": x.id === selected,
							})}
							onClick={this.handleSelect(x.id)}
						>
							<div className="categories-pane__content">{x.name}</div>
						</div>
					))}
				</div>
			</div>
		);
	}
}

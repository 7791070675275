import React from "react";

export function DisabledIcon({ className }: { className?: string }) {
	return (
		<svg version="1.1" x="0px" y="0px" viewBox="0 0 337.5 337.5" className={className}>
			<g>
				<path
					d="M29.2,337.5h279.1c16.1,0,29.2-13.1,29.2-29.2V29.2c0-16.1-13.1-29.2-29.2-29.2H29.2C13.1,0,0,13.1,0,29.2
		v279.1C0,324.4,13.1,337.5,29.2,337.5z"
				/>
				<g>
					<path
						d="M157.5,257.6c-28.8,0-52.1-23.3-52.1-52.1c0-4.2,0.5-8.3,1.5-12.3c3-12.4,10.4-23,20.5-30.2v-16.3
			c-17.8,9.2-30.9,26.2-34.7,46.5c-0.7,4-1.2,8.1-1.2,12.2c0,36.5,29.6,66,66,66c29.8,0,55-19.8,63.2-47L209.6,206
			C209.3,234.6,186.1,257.6,157.5,257.6z M148.6,101.1c9.7,0,17.6-7.9,17.6-17.6c0-9.7-7.9-17.6-17.6-17.6
			c-9.7,0-17.6,7.9-17.6,17.6C131.1,93.2,138.9,101.1,148.6,101.1z M270.6,215.1c-2.4-3.6-7.2-4.6-10.8-2.2l-10.7,6.4l-25.3-42.1
			c-0.3-0.6-0.6-1.2-1-1.7c-1.4-1.9-3.4-3.5-5.6-4.3c-1.3-0.5-2.7-0.8-4.2-0.8h-48v-12.6h37.4c4.3,0,7.8-3.5,7.8-7.8
			c0-4.3-3.5-7.8-7.8-7.8h-37.4v-19.8c0-7.8-6.3-14-14-14h-2.7c-7.8,0-14,6.3-14,14v58.2c0,3.4,1.2,6.5,3.2,8.9
			c2.6,3.1,6.5,5.1,10.9,5.1h2.7c0,0,0,0,0.1,0c0,0,0,0,0.1,0h60.4l28.9,48.1l9.8-5.9l18.2-10.9
			C271.9,223.6,272.9,218.7,270.6,215.1z"
					/>
				</g>
			</g>
		</svg>
	);
}

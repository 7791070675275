import React from "react";
import cn from "classnames";
import "select2";
import { Select2Dropdown } from "@components/select2-dropdown";

interface Filter {
	id: string;
	name?: string;
	items: any[];
	value: any;
}

interface Props {
	filters: Filter[];
	onChange?: (filterId: string, value: any, filters: any) => void;
	className?: string;
}

export class FilterToolbar extends React.Component<Props, any> {
	public state = Object.assign({}, ...this.props.filters.map(f => ({ [f.id]: f.value })));

	private handleFilterChange = (filterId: string) => {
		return (val: number) => {
			this.setState({
				[filterId]: val,
			});

			if (this.props.onChange) {
				this.props.onChange(filterId, val, { ...this.state });
			}
		};
	};

	public render() {
		const { filters, className } = this.props;

		return (
			<div className={cn("filter-toolbar", className)}>
				<div className="filter-toolbar__label">Фильтр</div>
				<div className="filter-toolbar__filters">
					{filters.map(f => (
						<Select2Dropdown
							key={f.id}
							onChange={this.handleFilterChange(f.id)}
							selection={this.state[f.id]}
							items={f.items}
							className={`filter-toolbar__item filter-toolbar__item-${f.id}`}
						/>
					))}
				</div>
			</div>
		);
	}
}

import React from "react";
import * as d3 from "d3";
import cn from "classnames";

interface DataItem {
	id: number;
	name: string;
	values: Array<{
		id: number;
		name: string;
		value: number;
	}>;
}

interface Props {
	data: DataItem[];
	className?: string;
	tooltipClassName?: string;
	getTooltip?: (item: DataItem) => string;
}

export class BubbleChart extends React.Component<Props> {
	private svgRef = React.createRef<SVGSVGElement>();
	private tooltip: any | null = null;

	public componentDidMount() {
		if (this.props.getTooltip) {
			this.tooltip = d3
				.select("body")
				.append("div")
				.attr("class", cn("chart-tooltip", this.props.tooltipClassName))
				.style("opacity", 0);

			// @ts-ignore
		}

		this.createChart(this.props.data);
	}

	public componentWillUnmount() {
		if (this.tooltip) {
			this.tooltip.remove();
		}
	}

	public componentDidUpdate() {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		svg.selectAll("*").remove();

		this.createChart(this.props.data);
	}

	private createChart(data: DataItem[]) {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const width = 550;
		const height = 550;
		const tooltip = this.tooltip;

		const x = d3
			.scaleLinear()
			// @ts-ignore
			.domain([d3.min(data[0].values, y => y.id), d3.max(data[0].values, y => y.id)])
			.range([0, width]);

		svg.append("g")
			.attr("class", "x-axis")
			.attr("transform", "translate(0," + height + ")")
			.call(
				d3
					.axisBottom(x)
					.ticks(data[0].values.length)
					.tickSize(0)
					.tickFormat(x => {
						const item = data[0].values.find(y => y.id === x)!;
						return item ? item.name : "";
					}),
			);

		const y = d3
			.scaleLinear()
			// @ts-ignore
			.domain([d3.min(data, y => y.id), d3.max(data, y => y.id)])
			.range([height, 0]);

		svg.append("g")
			.attr("class", "y-axis")
			.call(
				d3
					.axisLeft(y)
					.ticks(data.length)
					.tickSize(0)
					.tickFormat(x => {
						const item = data.find(y => y.id === x)!;
						return item ? item.name : "";
					}),
			);

		var z = d3
			.scaleLinear()
			.domain([
				// @ts-ignore
				d3.min(data, d => d3.min(d.values, d1 => d1.value)),
				// @ts-ignore
				d3.max(data, d => d3.max(d.values, d1 => d1.value)),
			])
			.range([1, 25]);

		const g = svg.append("g");

		const colors = ["#ed4d25", "#6ecacd", "#f8a320", "#0f6fb8", "#fed13b"].reverse();

		data.forEach(r => {
			const g1 = g.selectAll("dot").append("g");

			// @ts-ignore
			const dots = g1
				.data(r.values)
				.enter()
				.append("circle")
				// @ts-ignore
				.attr("cx", d => x(d.id))
				// @ts-ignore
				.attr("cy", () => y(r.id))
				// @ts-ignore
				.attr("r", d => z(d.value))
				// @ts-ignore
				.style("fill", (d, index) => colors[index]);

			// @ts-ignore
			const texts = g1
				.data(r.values)
				.enter()
				.append("text")
				// @ts-ignore
				.attr("x", d => x(d.id))
				// @ts-ignore
				.attr("y", () => y(r.id))
				.attr("text-anchor", "middle")
				.attr("alignment-baseline", "central")
				.attr("font-size", "10px")
				.attr("fill", "#fff")
				// @ts-ignore
				.text(d => (d.value < 430 ? "" : d.value.toFixed(0)));

			if (tooltip) {
				dots.on("mouseover", (e: any) => {
					tooltip
						.transition()
						.duration(200)
						.style("opacity", 1);
					tooltip
						.html(this.props.getTooltip!(e))
						.style("left", d3.event.pageX + "px")
						.style("top", d3.event.pageY - 28 + "px");
				}).on("mouseout", () => {
					tooltip
						.transition()
						.duration(500)
						.style("opacity", 0);
				});

				texts
					.on("mouseover", (e: any) => {
						tooltip
							.transition()
							.duration(200)
							.style("opacity", 1);
						tooltip
							.html(this.props.getTooltip!(e))
							.style("left", d3.event.pageX + "px")
							.style("top", d3.event.pageY - 28 + "px");
					})
					.on("mouseout", () => {
						tooltip
							.transition()
							.duration(500)
							.style("opacity", 0);
					});
			}
		});

		svg.selectAll(".y-axis .tick text").attr("dx", `-55`);
		svg.selectAll(".x-axis .tick text").attr("dy", `55`);
	}

	public render() {
		return (
			<svg
				style={{ overflow: "visible" }}
				ref={this.svgRef}
				width={550}
				height={550}
				className={cn("chart chart--bubble", this.props.className)}
			/>
		);
	}
}

import React from "react";
import { AreaChart } from "@components/figures/area-chart";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";
import { schoolSubjects } from "../../../../data";

const map = new Map<number, string>(schoolSubjects.map(x => [x.id, x.name]));

function generateData1() {
	return schoolSubjects.map(x => ({
		subjId: x.id,
		value: 2000 * Math.random(),
	}));
}

const d1 = generateData1();

function generateData() {
	return [
		{
			id: 0,
			values: d1.map((x, index) => ({
				subjId: x.subjId,
				value: Math.max(x.value + 200 * Math.random() * (index % 2 > 0 ? 1 : -1), 50),
				subjName: map.get(x.subjId),
			})),
		},
		{
			id: 1,
			values: d1,
		},
	];
}

export function ForecastParticipants() {
	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	function getTooltip(e: any) {
		return `<div class="chart-tooltip__header">${map.get(e.subjId)}</div>
							<div class="tooltip-row">
								<strong>${data[0].values
									.find(x => x.subjId === e.subjId)!
									.value.toFixed(0)} чел.</strong> <div>Прогноз числа участников</div>
							</div>
							<div class="tooltip-row">
								<strong>${e.value.toFixed(0)} чел.</strong> <div>Участники заключительного этапа за предыдущий год</div>
							</div>
						`;
	}

	return (
		<>
			<FilterToolbarSet onChange={handleFilterChange} filterSet={FilterSet.Set1} />
			<div className="participants-forecast">
				<AreaChart
					className="participants-forecast__chart"
					tooltipClassName="participants-forecast-chart-tooltip"
					data={data}
					getTooltip={getTooltip}
				/>

				<div className="participants-forecast__legends">
					<div className="legend-label">
						<div className="legend-label__dot legend-label__dot--1" />
						<div>
							Заключительный
							<br />
							этап за предыдущий год
						</div>
					</div>
					<div className="legend-label">
						<div className="legend-label__dot legend-label__dot--2" />
						<span>
							Прогноз по
							<br />
							заключительному этапу
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

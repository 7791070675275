import React from "react";
import { Pager } from "@components/pager";

export function WebResources() {
	return (
		<>
			<div className="slide slide--dark">
				<div className="slide__top">01</div>
				<div className="slide-header">Основные ресурсы</div>
				<div className="web-resources web-resources--light">
					<div className="web-resources__navigation">
						<a href="#" className="web-resources__navigation-item">
							Федеральные сайты
						</a>
						<a href="#" className="web-resources__navigation-item">
							Региональные сайты
						</a>
						<a href="#" className="web-resources__navigation-item">
							Предметные сайты
						</a>
						<a href="#" className="web-resources__navigation-item">
							Порталы по олимпиадам
						</a>
						<a href="#" className="web-resources__navigation-item">
							Международные олимпиады
						</a>
					</div>
					<div className="web-resources__page">
						<a href="#" className="web-resources__link">
							Министерство просвещения Российской Федерации
						</a>
						<a href="#" className="web-resources__link">
							Методический сайт всероссийской олимпиады школьников
						</a>
						<a href="#" className="web-resources__link">
							Олимпиады для школьников
						</a>
						<a href="#" className="web-resources__link">
							Министерство просвещения Российской Федерации
						</a>
						<a href="#" className="web-resources__link">
							Методический сайт всероссийской олимпиады школьников
						</a>
						<a href="#" className="web-resources__link">
							Олимпиады для школьников
						</a>
						<a href="#" className="web-resources__link">
							Олимпиады для школьников
						</a>
						<a href="#" className="web-resources__link">
							Министерство просвещения Российской Федерации
						</a>
						<a href="#" className="web-resources__link">
							Методический сайт всероссийской олимпиады школьников
						</a>
					</div>
					<div className="web-resources__pager">
						<Pager />
					</div>
				</div>
			</div>
			<div className="slide">
				<div className="slide__top">02</div>
				<div className="slide-header">Подготовка к олимпиаде</div>
				<div className="web-resources">
					<div className="web-resources__navigation">
						<a href="#" className="web-resources__navigation-item">
							Международные олимпиады
						</a>
						<a href="#" className="web-resources__navigation-item">
							Курсы для учителей
						</a>
						<a href="#" className="web-resources__navigation-item">
							Курсы для учеников
						</a>
					</div>
					<div className="web-resources__page">
						<a href="#" className="web-resources__link">
							Центр педагогического мастерства
						</a>
						<a href="#" className="web-resources__link">
							Центр образования «Коалиция»
						</a>
						<a href="#" className="web-resources__link">
							Книги и ресурсы для подготовки
						</a>
						<a href="#" className="web-resources__link">
							Сайт подготовки национальных команд Российской Федерации к Международной олимпиаде по физике
							IPhO и Международной естественнонаучной олимпиаде юниоров IJSO
						</a>
						<a href="#" className="web-resources__link">
							Центр педагогического мастерства
						</a>
						<a href="#" className="web-resources__link">
							Центр образования «Коалиция»
						</a>
						<a href="#" className="web-resources__link">
							Книги и ресурсы для подготовки
						</a>
						<a href="#" className="web-resources__link">
							Сайт подготовки национальных команд Российской Федерации к Международной олимпиаде по физике
							IPhO и Международной естественнонаучной олимпиаде юниоров IJSO
						</a>
						<a href="#" className="web-resources__link">
							Книги и ресурсы для подготовки
						</a>
					</div>
					<div className="web-resources__pager">
						<Pager />
					</div>
				</div>
			</div>
		</>
	);
}

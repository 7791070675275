import React from "react";
import { Search } from "@icons/search";

export function SearchPanel({ onChange, value = "" }: { onChange?(value: string): void; value?: string }) {
	function handleChange(e: React.FormEvent<HTMLInputElement>) {
		if (onChange) {
			onChange(e.currentTarget.value);
		}
	}

	return (
		<div className="search-panel">
			<input
				type="text"
				className="search-panel__input"
				placeholder="Поиск"
				value={value}
				onChange={handleChange}
			/>
			<Search className="search-panel__icon" />
		</div>
	);
}

import React from "react";
import cn from "classnames";
import { BarChart } from "@components/figures/bar-chart";
import { schoolSubjects, steps } from "../../../../data";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function generateData() {
	return schoolSubjects.map(x => ({
		name: x.name,
		value: 2000 * Math.random(),
	}));
}

export function ActualParticipants() {
	const [data, setData] = React.useState(generateData());
	const [activeStep, setActiveStep] = React.useState(1);

	function handleFilterChange() {
		setData(generateData());
	}

	function handleSetActiveStep(stepId: number) {
		return function() {
			setActiveStep(stepId);
			setData(generateData());
		};
	}

	return (
		<>
			<FilterToolbarSet onChange={handleFilterChange} filterSet={FilterSet.Set1} />

			<div className="participants-count">
				<div className="participants-count__button-bar">
					{steps.map(x => (
						<div
							key={x.id}
							className={cn("participants-count-step-button", {
								"participants-count-step-button--active": x.id === activeStep,
							})}
							onClick={handleSetActiveStep(x.id)}
						>
							{x.name}
						</div>
					))}
				</div>
				<div>
					<BarChart data={data} className="participants-count__chart" />
				</div>
			</div>
		</>
	);
}

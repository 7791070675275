import React from "react";
import axios from "axios";
import { Select2Dropdown } from "@components/select2-dropdown";
import { steps } from "../../data";
import cn from "classnames";
import { action, observable } from "mobx";
import { observer } from "mobx-react-lite";

class ParticipantFormInfo {
	@observable private fieldMap = new Map<string, string | number>();

	constructor(step: number) {
		this.fieldMap.set("step_type", step);
	}

	@action
	public updateParticipant(fieldName: string, fieldValue: string) {
		this.fieldMap.set(fieldName, fieldValue);
	}

	public getField(fieldName: string) {
		return this.fieldMap.get(fieldName) || "";
	}

	public getValues() {
		return Array.from(this.fieldMap);
	}
	public submit() {
		const result = Object.assign({}, ...this.getValues().map(x => ({ [x[0]]: x[1] })));

		return axios.post("/api/participants", result);
	}
}

const fields = [
	["field1", "Имя"],
	["field2", "Фамилия"],
	["field3", "Пол"],
	["field4", "Наличие ОВЗ"],
	["field5", "Расшифровка ОВЗ (в случае наличия)"],
	["field6", "Гражданство"],
	["field7", "Набранный бал"],
	["field8", "Результат"],
	["field9", "Номер работы"],
	["field10", "Аппеляция. Дата проведения"],
	["field11", "Аппеляция. № протокола"],
	["field12", "Аппеляция. Решение"],
	["field13", "Аппеляция. Количество начисленных баллов"],
	["field14", "Нарушение порядка. Вид нарушения"],
	["field15", "Нарушение порядка. Дата акта об удалении"],
	["field16", "Нарушение порядка. № акта об удалении"],
	// ["field17", ""],
	// ["field18", ""],
	// ["field19", ""],
	// ["field20", ""],
	// ["field21", ""],
	// ["field22", ""],
	// ["field23", ""],
];

const ParticipantForm = observer(function({ step, onSave }: { step: number, onSave(): void }) {
	const form = React.useRef(new ParticipantFormInfo(step));

	async function  handleSave() {
		console.log(form.current.getValues());
		await form.current.submit();
		onSave();
	}

	function handleChange(fieldName: string) {
		return function(e: React.FormEvent<HTMLInputElement>) {
			form.current.updateParticipant(fieldName, e.currentTarget.value);
		};
	}

	return (
		<div>
			{fields.map(([fieldName, placeholder]) => (
				<input
					key={fieldName}
					type="text"
					placeholder={placeholder}
					value={form.current.getField(fieldName)}
					onChange={handleChange(fieldName)}
				/>
			))}
			<button onClick={handleSave}>Сохранить участника</button>
		</div>
	);
});

export function InputPage() {
	const [step, setStep] = React.useState(0);
	const [participantActive, setParticipantActive] = React.useState(false);
	const [participants, setParticipants] = React.useState<any[]>([]);

	function fetchParticipants() {
		axios.get(`/api/participants?stepId=${step}`).then(result => {
			setParticipants(result.data);
		});
	}

	React.useEffect(() => {
		fetchParticipants();
	}, [step]);


	function handleStepChange(stepId: number) {
		setStep(stepId);
	}

	function turnOnParticipantActive() {
		setParticipantActive(true);
	}

	function handleParticipantSave() {
		setParticipantActive(false);
		fetchParticipants();
	}

	return (
		<div className="slide">
			<div className={cn("filter-toolbar")}>
				<div className="filter-toolbar__label">Этап олимпиады</div>
				<div className="filter-toolbar__filters">
					<Select2Dropdown
						onChange={handleStepChange}
						selection={step}
						items={[{ id: 0, name: "Не выбрано" }].concat(steps)}
						className={`filter-toolbar__item filter-toolbar__item-step`}
					/>
				</div>
			</div>

			{step > 0 && (
				<>
					{/*<input type="text" placeholder="Название олимпиады" />*/}

					<div className="slide-header" style={{ marginBottom: 15 }}>
						Участники
					</div>
					{!participantActive && (
						<>
							{participants.map((x) => (
								<div key={x.id} style={{ margin: "15px 0" }}>
									{x.field2} {x.field1}
								</div>
							))}
							<button onClick={turnOnParticipantActive} style={{ marginTop: 25 }}>
								Добавить участника
							</button>
						</>
					)}

					{participantActive && <ParticipantForm onSave={handleParticipantSave} step={step} />}
				</>
			)}
		</div>
	);
}

import React from "react";
import cn from "classnames";

export function DonutPercentage({
	percentage,
	className,
	color,
	children,
}: {
	percentage: number;
	className: string;
	color: string;
	children: React.ReactNode;
}) {
	return (
		<div className={cn("donut-percentage-container", className)}>
			<svg viewBox="0 0 42 42" className="donut-percentage">
				<circle
					className="donut-ring"
					cx="21"
					cy="21"
					r="15.91549430918952"
					fill="transparent"
					stroke="#476479"
					strokeWidth="0.5"
				/>
				<circle
					className="donut-segment"
					cx="21"
					cy="21"
					r="15.91549430918952"
					fill="transparent"
					stroke={color}
					strokeWidth="1.5"
					strokeDasharray={`${percentage} ${100 - percentage}`}
					strokeDashoffset="0"
				/>
			</svg>
			{children}
		</div>
	);
}

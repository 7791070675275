import React from "react";
import {
	federalDistricts,
	federalSubjects,
	internationalCompetitions,
	schoolClasses,
	schoolSubjects,
	steps,
	years,
} from "../../data";
import { FilterToolbar } from "@components/filter-toolbar";

const filterSet1 = [
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
];

const filterSet2 = [
	{
		id: "step",
		name: "Этап олимпиады",
		items: [{ id: 0, name: "Этап олимпиады" }].concat(steps),
		value: 0,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
];

const filterSet3 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
];

const filterSet4 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
	{
		id: "schoolSubject",
		name: "Предмет",
		items: [{ id: 0, name: "Предмет" }].concat(schoolSubjects),
		value: 0,
	},
];

const filterSet5 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "internationalCompetitions",
		name: "Название олимпиады",
		items: [{ id: 0, name: "Название олимпиады" }].concat(
			internationalCompetitions.map((x, index) => ({ id: index + 1, name: `${x.name} (${x.id})` })),
		),
		value: "none",
	},
	{
		id: "result",
		name: "Результат",
		items: [{ id: 0, name: "Результат" }],
		value: 0,
	},
];

const filterSet6 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
	{
		id: "schoolSubject",
		name: "Предмет",
		items: [{ id: 0, name: "Предмет" }].concat(schoolSubjects),
		value: 0,
	},
	{
		id: "schoolClass",
		name: "Класс",
		items: [{ id: 0, name: "Класс" }].concat(schoolClasses.map(s => ({ id: s, name: s.toString() }))),
		value: 0,
	},
	{
		id: "result",
		name: "Результат",
		items: [
			{ id: 0, name: "Результат" },
			{ id: 1, name: "Победитель" },
			{ id: 2, name: "Призер" },
			{ id: 3, name: "Участник" },
		],
		value: 0,
	},
];

const filterSet7 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
];

const filterSet8 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "step",
		name: "Этап олимпиады",
		items: steps,
		value: 1,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
];

const filterSet9 = [
	{
		id: "step",
		name: "Этап олимпиады",
		items: [{ id: 0, name: "Этап олимпиады" }].concat(steps),
		value: 0,
	},
	{
		id: "federalDistrict",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federalSubject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "result",
		name: "Результат",
		items: [
			{ id: 0, name: "Результат" },
			{ id: 1, name: "Победитель" },
			{ id: 2, name: "Призер" },
			{ id: 3, name: "Участник" },
		],
		value: 0,
	},
];

const filterSet10 = [
	{
		id: "period",
		name: "Период",
		items: [{ id: 2018, name: "2018/19" }],
		value: 2018,
	},
	{
		id: "schoolSubject",
		name: "Предмет",
		items: [{ id: 0, name: "Предмет" }].concat(schoolSubjects),
		value: 0,
	},
	{
		id: "schoolClass",
		name: "Класс",
		items: schoolClasses.filter(s => s >= 9).map(s => ({ id: s, name: `${s} класс` })),
		value: 11,
	},
	{
		id: "step",
		name: "Этап олимпиады",
		items: steps.filter(s => s.id === 3 || s.id === 4),
		value: 3,
	},
];

const filterSet11 = [
	{
		id: "period",
		name: "Период",
		items: years,
		value: 2018,
	},
	{
		id: "result",
		name: "Результат",
		items: [
			{ id: 0, name: "Результат" },
			{ id: 1, name: "Победитель" },
			{ id: 2, name: "Призер" },
			{ id: 3, name: "Участник" },
		],
		value: 0,
	},
];

export enum FilterSet {
	Set1 = 1,
	Set2 = 2,
	Set3 = 3,
	Set4 = 4,
	Set5 = 5,
	Set6 = 6,
	Set7 = 7,
	Set8 = 8,
	Set9 = 9,
	Set10 = 10,
	Set11 = 11,
}

const filtersBySetName = new Map([
	[FilterSet.Set1, filterSet1],
	[FilterSet.Set2, filterSet2],
	[FilterSet.Set3, filterSet3],
	[FilterSet.Set4, filterSet4],
	[FilterSet.Set5, filterSet5],
	[FilterSet.Set6, filterSet6],
	[FilterSet.Set7, filterSet7],
	[FilterSet.Set8, filterSet8],
	[FilterSet.Set9, filterSet9],
	[FilterSet.Set10, filterSet10],
	[FilterSet.Set11, filterSet11],
]);

export function FilterToolbarSet({
	onChange,
	filterSet,
	className,
}: {
	onChange?(filterId: string, val: number, filters: any): void;
	filterSet: FilterSet;
	className?: string;
}) {
	return <FilterToolbar filters={filtersBySetName.get(filterSet)!} onChange={onChange} className={className} />;
}

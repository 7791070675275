import React from "react";
import { news } from "../../data";

export function News() {
	return (
		<div className="news-content-home">
			{news.map(x => (
				<div className="news-item" key={x.id}>
					<div className="news-item__date">{x.date}</div>
					<a className="news-item__header" href={`/news?id=${x.id}`}>{x.name}</a>
					<div className="news-item__content">
						<div className="news-item__teaser">{x.teaser}</div>
						<div className="news-item__link">
							<a className="button button--primary" href={`/news?id=${x.id}`}>
								Читать
							</a>
						</div>
					</div>
				</div>
			))}
		</div>
	);
}

import React from "react";
import cn from "classnames";
import { federalSubjects, years } from "../../../data";
import { DocListArrow } from "@icons/doc-list-arrow";
import { FileDownloadLink } from "@components/file-link";
import { FileType } from "../../../types";
import { AlphabetFilter } from "../../olympiads/shared/alphabet-filter";

interface DataItem {
	id: number;
	name: string;
	children?: DataItem[];
	alphabetFilter?: boolean;
}

const rootData: DataItem[] = [
	{
		id: 1,
		name: "Количественные данные об участниках школьного и муниципального этапов",

		children: [
			{ id: 1001, name: "Форма 1" },
			{ id: 1002, name: "Форма 2" },
			{
				id: 1003,
				name: "Форма 3",
				alphabetFilter: true,
				children: federalSubjects.map(x => ({ id: 10000 + x.id, name: x.name })),
			},
		],
	},
	{
		id: 2,
		name: "Количественные данные об обучающихся из 4-х классов в школьном этапе",
		children: [
			{ id: 1001, name: "Форма 1" },
			{ id: 1002, name: "Форма 2" },
			{
				id: 1003,
				name: "Форма 3",
				alphabetFilter: true,
				children: federalSubjects.map(x => ({ id: 10000 + x.id, name: x.name })),
			},
		],
	},
	{
		id: 3,
		name: "Количественные данные об участниках регионального этапа",
	},
	{
		id: 4,
		name: "Количественные данные об участниках, выполнявших на региональном этапе задания для старших классов",
	},
	{
		id: 5,
		name: "Статистические данные по заключительному этапу",
		children: [
			{ id: 1001, name: "Форма 1" },
			{ id: 1002, name: "Форма 2" },
			{
				id: 1003,
				name: "Форма 3",
				alphabetFilter: true,
				children: federalSubjects.map(x => ({ id: 10000 + x.id, name: x.name })),
			},
		],
	},
];

function AnalyticsSummaryItem({ data, root = false }: { data: DataItem; root?: boolean }) {
	const [expanded, setExpanded] = React.useState(false);

	function toggleExpanded() {
		setExpanded(!expanded);
	}

	return data.children ? (
		<>
			<div
				className={cn("analytics-summary-item__category", {
					"analytics-summary-item__category--expanded": expanded,
				})}
				onClick={toggleExpanded}
			>
				<DocListArrow
					className={cn("analytics-summary-item__category-icon", {
						"analytics-summary-item__category-icon--expanded": expanded,
					})}
				/>
				{data.name}
			</div>
			{expanded && (
				<div
					className={cn("analytics-summary-item__category-list", {
						"analytics-summary-item__category-list--root": root,
					})}
				>
					{data.alphabetFilter && <AlphabetFilter />}
					{!data.alphabetFilter && data.children.map(d => <AnalyticsSummaryItem key={`f${d.id}`} data={d} />)}
					{data.alphabetFilter && data.children && (
						<div className="analytics-summary-item__alphabet-files">
							{[0, 1, 2, 3]
								.map((i: number) => data.children!.slice(i * 21, i * 21 + 21))
								.map((arr, index) => (
									<div key={index}>
										{arr.map(d => (
											<FileDownloadLink key={d.id} type={FileType.Xls} text={d.name} />
										))}
									</div>
								))}
						</div>
					)}
				</div>
			)}
		</>
	) : (
		<div className="analytics-summary-item__file">
			<FileDownloadLink type={FileType.Xls} text={data.name} />
		</div>
	);
}

function AnalyticsSummaryYear({ year, className }: { year: { id: number; name: string }; className?: string }) {
	const [expanded, setExpanded] = React.useState(false);

	function handleExpanded() {
		setExpanded(!expanded);
	}

	return (
		<>
			<div className={cn("analytics-summary-year", className)} onClick={handleExpanded}>
				<span style={{ minWidth: 100 }}>{year.name}</span>

				<div
					className={cn("analytics-summary-year__button", {
						"analytics-summary-year__button--expanded": expanded,
					})}
				/>
			</div>
			{expanded && (
				<div className="analytics-summary-year__content">
					{rootData.map(x => (
						<AnalyticsSummaryItem data={x} key={x.id} root />
					))}
				</div>
			)}
		</>
	);
}

export function AnalyticsSummary() {
	return (
		<>
			<FileDownloadLink type={FileType.Xls} text="Сформировать отчет" url="/store/report.xls" />
			{years.map((x, index) => (
				<AnalyticsSummaryYear
					key={x.id}
					year={x}
					className={cn({ "analytics-summary-year--even": index % 2 === 1 })}
				/>
			))}
		</>
	);
}

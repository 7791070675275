import React from "react";
import * as d3 from "d3";
import cn from "classnames";
import { internationalCompetitions } from "../../../../data";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

const results = [
	{
		id: "IPhO",
		gold: 3,
		silver: 5,
		bronze: 7,
		total: 15,
	},
	{
		id: "IBO",
		gold: 1,
		silver: 2,
		bronze: 4,
		total: 7,
	},
	{
		id: "IMO",
		gold: 5,
		silver: 2,
		bronze: 1,
		total: 8,
	},
	{
		id: "IChO",
		gold: 4,
		silver: 6,
		bronze: 2,
		total: 12,
	},
	{
		id: "iGeo",
		gold: 3,
		silver: 5,
		bronze: 2,
		total: 10,
	},
	{
		id: "IOAA",
		gold: 3,
		silver: 7,
		bronze: 3,
		total: 13,
	},
	{
		id: "IOI",
		gold: 5,
		silver: 2,
		bronze: 1,
		total: 8,
	},
	{
		id: "IJSO",
		gold: 5,
		silver: 2,
		bronze: 3,
		total: 10,
	},
];

const resultsByRegion = [
	{
		region: "Вологодская область",
		gold: 1,
		silver: 0,
		bronze: 0,
		total: 1,
	},
	{
		region: "Кировская область",
		gold: 0,
		silver: 1,
		bronze: 0,
		total: 1,
	},
	{
		region: "Москва",
		gold: 13,
		silver: 10,
		bronze: 1,
		total: 24,
	},
	{
		region: "Московская область",
		gold: 1,
		silver: 2,
		bronze: 0,
		total: 3,
	},
	{
		region: "Новосибирская область",
		gold: 1,
		silver: 0,
		bronze: 0,
		total: 1,
	},
	{
		region: "Пермский край",
		gold: 0,
		silver: 0,
		bronze: 1,
		total: 1,
	},
	{
		region: "Республика Мордовия",
		gold: 0,
		silver: 1,
		bronze: 0,
		total: 1,
	},
	{
		region: "Республика Татарстан",
		gold: 1,
		silver: 1,
		bronze: 0,
		total: 2,
	},
	{
		region: "Санкт-Петербург",
		gold: 5,
		silver: 2,
		bronze: 0,
		total: 7,
	},
	{
		region: "Челябинская область",
		gold: 1,
		silver: 0,
		bronze: 1,
		total: 2,
	},
];

function DonutPercentage({
	percentage,
	className,
	children,
}: {
	percentage: number;
	className: string;
	children: React.ReactNode;
}) {
	return (
		<div className={cn("donut-percentage-container", className)}>
			<svg viewBox="0 0 42 42" className="donut-percentage">
				<circle
					className="donut-ring"
					cx="21"
					cy="21"
					r="15.91549430918952"
					fill="transparent"
					stroke="#476479"
					strokeWidth="0.5"
				/>
				<circle
					className="donut-segment"
					cx="21"
					cy="21"
					r="15.91549430918952"
					fill="transparent"
					stroke="#ce4b99"
					strokeWidth="1.5"
					strokeDasharray={`${percentage} ${100 - percentage}`}
					strokeDashoffset="0"
				/>
			</svg>
			{children}
		</div>
	);
}

export class Winners extends React.Component {
	private svgRef = React.createRef<SVGSVGElement>();

	public componentDidMount() {
		this.createChart();
	}

	public createChart = () => {
		const node = this.svgRef.current;
		const svg = d3.select(node);

		const keys = ["bronze", "silver", "gold"];

		const margin = { top: 0, left: 0, bottom: 0, right: 0 };
		const width = +svg.attr("width") - margin.left - margin.right;
		const height = +svg.attr("height") - margin.top - margin.bottom;

		const x = d3
			.scaleBand()
			.range([margin.left, width - margin.right])
			.padding(0.1);

		const y = d3.scaleLinear().rangeRound([height - margin.bottom, margin.top]);

		var z = d3
			.scaleOrdinal()
			.range(["#cc773d", "#bbbbbb", "#c0ac44"])
			.domain(keys);

		update(0);

		function update(speed: number) {
			var data = results;

			// data.forEach(function(d) {
			// 	d.total = d3.sum(keys, k => +d[k]);
			// 	return d;
			// });

			// @ts-ignore
			y.domain([0, d3.max(data, d => d3.sum(keys, k => d[k] as number))!]).nice();

			x.domain(data.map(d => d.id));

			// @ts-ignore
			var group = svg.selectAll("g.layer").data(d3.stack().keys(keys)(data), d => d.key);

			group.exit().remove();

			group
				.enter()
				.append("g")
				.classed("layer", true)
				// @ts-ignore
				.attr("fill", d => z(d.key));

			var bars = svg
				.selectAll("g.layer")
				.selectAll("rect")
				// @ts-ignore
				.data(d => d, e => e.data.id);

			bars.exit().remove();

			var div = d3
				.select("body")
				.append("div")
				.attr("class", "distribution-chart-tooltip")
				.style("opacity", 0);

			bars.enter()
				.append("rect")
				.attr("width", x.bandwidth() - 10)
				.merge(bars)
				.transition()
				.duration(speed)
				.attr("x", (d: any) => x(d.data.id)!)
				.attr("y", (d: any) => y(d[1]))
				.attr("height", (d: any) => y(d[0]) - y(d[1]));

			svg.selectAll("rect")
				.on("mouseover", (e: any) => {
					div.transition()
						.duration(200)
						.style("opacity", 1);
					div.html(
						`<div class="distribution-chart-tooltip__header">${e.data.id} - ${
							internationalCompetitions.find(x => x.id === e.data.id)!.name
						}</div>
							<div class="distribution-chart-tooltip__person-stats">
								<span class="slide-map-stats-strong">${e.data.gold}</span> золото<br/>
								<span class="slide-map-stats-strong">${e.data.silver}</span> серебро<br/>
								<span class="slide-map-stats-strong">${e.data.bronze}</span> бронза
							</div>
							<div>
								Всего <span class="slide-map-stats-strong">${e.data.total} шт.</span>
							</div>
						`,
					)
						.style("left", d3.event.pageX + "px")
						.style("top", d3.event.pageY - 28 + "px");
				})
				.on("mouseout", () => {
					div.transition()
						.duration(500)
						.style("opacity", 0);
				});

			// @ts-ignore
			const yAxis = svg
				.append("g")
				.attr("transform", `translate(${margin.left},0)`)
				.attr("class", "y-axis");

			svg.selectAll(".y-axis")
				.transition()
				.duration(speed)
				// @ts-ignore
				.call(
					d3
						.axisLeft(y)
						.tickSize(-width)
						//@ts-ignore
						.tickFormat(d => d),
				);

			// @ts-ignore
			const xAxis = svg
				.append("g")
				.attr("transform", `translate(0,${height - margin.bottom})`)
				.attr("class", "x-axis");

			svg.selectAll(".x-axis")
				.transition()
				.duration(speed)
				// @ts-ignore
				//.call(d3.axisBottom(x).tickSizeOuter(0));
				.call(
					// @ts-ignore
					d3.axisBottom(x).tickSize(-height),
				);

			svg.selectAll(".x-axis .tick line").attr("transform", `translate(${x.bandwidth() / 2}, 0)`);

			svg.selectAll(".x-axis text").attr("transform", "translate(0,15)");

			svg.selectAll(".y-axis text").attr("transform", "translate(-10,0)");
		}
	};

	public render() {
		const total = results.reduce((accumulator: number, v) => accumulator + v.total, 0);
		const goldTotal = results.reduce((accumulator: number, v) => accumulator + v.gold, 0);
		const silverTotal = results.reduce((accumulator: number, v) => accumulator + v.silver, 0);
		const bronzeTotal = results.reduce((accumulator: number, v) => accumulator + v.bronze, 0);

		return (
			<>
				<FilterToolbarSet filterSet={FilterSet.Set5} />
				<div className="winners-barchart-container">
					<div className="winners-barchart-container__y-label">
						Количество <br /> медалей (шт.)
					</div>
					<svg ref={this.svgRef} width={900} height={500} className="winners-barchart" />
				</div>

				<div className="donut-rings">
					<div className="donut-rings__total">
						<span className="donut-rings__total-num">{total}</span>
						победителя
					</div>
					<div className="donut-rings__items">
						<DonutPercentage
							percentage={(goldTotal / total) * 100}
							className="donut-percentage-container--gold"
						>
							<div className="donut-percentage__content">
								{goldTotal}
								<br />
								золотых
								<br /> медалей
							</div>
						</DonutPercentage>
						<DonutPercentage
							percentage={(silverTotal / total) * 100}
							className="donut-percentage-container--silver"
						>
							<div className="donut-percentage__content">
								{silverTotal}
								<br />
								серебряных
								<br /> медалей
							</div>
						</DonutPercentage>
						<DonutPercentage
							percentage={(bronzeTotal / total) * 100}
							className="donut-percentage-container--bronze"
						>
							<div className="donut-percentage__content">
								{bronzeTotal}
								<br />
								бронзовых
								<br /> медалей
							</div>
						</DonutPercentage>
					</div>
				</div>

				<div className="winners-overview">
					В <strong>2017/18</strong> году наибольшее количество медалей было завоевано на:
					<ul>
						<li>
							Международной физической олимпиаде школьников (IPhO) - <strong>15 медалей</strong>{" "}
						</li>
						<li>
							Международной олимпиаде по астрономии и астрофизике (IOAA) - <strong>13 медалей</strong>{" "}
						</li>
						<li>
							Международной химической олимпиаде (IChO) - <strong>15 медалей</strong>{" "}
						</li>
					</ul>
				</div>

				<div className="region-winners-content">
					<div className="region-winners">
						<div className="region-winners__header">Победители представляли следующие регионы</div>
						{resultsByRegion.map(x => (
							<div className="region-winners__item" key={x.region}>
								<div className="region-winners-item">
									<div className="region-winners-item__title">{x.region}</div>
									<div className="region-winners-item__gap" />
									<div className="region-winners-item__value">{x.total}</div>
								</div>
								<div className="region-winners__medals">
									{x.gold > 0 && <div className="medal medal--gold">{x.gold}</div>}
									{x.silver > 0 && <div className="medal medal--silver">{x.silver}</div>}
									{x.bronze > 0 && <div className="medal medal--bronze">{x.bronze}</div>}
								</div>
							</div>
						))}
					</div>
					<div className="region-winners-legend">
						<div className="medal-legend medal-legend--gold" /> Золото
						<div className="medal-legend medal-legend--silver" /> Серебро
						<div className="medal-legend medal-legend--bronze" /> Бронза
					</div>
				</div>
			</>
		);
	}
}

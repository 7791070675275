import { AreaChart1 } from "@components/figures/area-chart1";
import React from "react";
import { federalDistricts, federalSubjects, steps, years } from "../../../../data";
import cn from "classnames";
import { FilterToolbar } from "@components/filter-toolbar";

const buttons = steps
	.sort((a, b) => -a.id + b.id)
	.map(x => ({ ...x, name: `${x.name} этап` }))
	.concat([{ id: 5, name: "Общее количество" }]);

const colorsMap: Map<number, string> = new Map([
	[4, "#0a71b7"],
	[3, "#6acacd"],
	[2, "#f9a31a"],
	[1, "#ef4d23"],
	[5, "#ffffff"],
]);
const opacity = "33";

function generateData() {
	const data = steps.map(x => ({
		id: x.id,
		values: years.map(year => ({
			valueId: year.id,
			valueTitle: year.name,
			value: x.id * 10000 + 5000 * Math.random(),
		})),
	}));

	data.push({
		id: 5,
		values: years.map(year => ({
			valueId: year.id,
			valueTitle: year.name,
			value: data.reduce(
				(aggregate, current) => aggregate + current.values.find(x => x.valueId === year.id)!.value,
				0,
			),
		})),
	});

	return data;
}

const filters = [
	{
		id: "federal-district",
		name: "Федеральный округ",
		items: [{ id: 0, name: "Федеральный округ" }].concat(federalDistricts),
		value: 0,
	},
	{
		id: "federal-subject",
		name: "Регион",
		items: [{ id: 0, name: "Регион" }].concat(federalSubjects),
		value: 0,
	},
	{
		id: "municipality",
		name: "Муниципальное образование",
		items: [{ id: 0, name: "Муниципальное образование" }],
		value: 0,
	},
];

function getTooltip(item: { valueId: number; valueTitle: string; value: number }) {
	return `
		<div class="chart-tooltip__header">${item.valueTitle}</div>
		<strong>${item.value.toFixed(0)}</strong> количество участников<br/>
		<strong>${(Math.random() * 10).toFixed(2)} %</strong> процент прироста
	`;
}

export function Slide1() {
	const [selection, setSelected] = React.useState(new Set([4]));
	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	function toggleSelection(id: number) {
		return function() {
			if (selection.has(id)) {
				if (selection.size > 1) {
					setSelected(new Set(Array.from(selection).filter(x => x !== id)));
				}
			} else {
				setSelected(new Set(Array.from(selection).concat([id])));
			}
		};
	}

	const passData = data.filter(d => selection.has(d.id));

	return (
		<div className="analytics-period__slide-1">
			<FilterToolbar filters={filters} onChange={handleFilterChange} />
			<div className="slide-1-chart">
				<div className="slide-1-chart__bar">
					{buttons.map(x => (
						<div
							key={x.id}
							onClick={toggleSelection(x.id)}
							className={cn("slide-1-chart__bar-button", `slide-1-chart__bar-button--${x.id}`, {
								[`slide-1-chart__bar-button--${x.id}-selected`]: selection.has(x.id),
							})}
						>
							{x.name}
						</div>
					))}
				</div>
				<AreaChart1
					data={passData}
					colors={passData.map(x => colorsMap.get(x.id)!)}
					opacity={opacity}
					tooltipClassName="participants-forecast-chart-tooltip"
					getTooltip={getTooltip}
				/>
			</div>
		</div>
	);
}

import React from "react";
import { DispersionChart } from "@components/figures/dispersion-chart";
import { schoolSubjects } from "../../../../data";
import { FilterSet, FilterToolbarSet } from "@components/filters/filter-toolbar-set";

function generateData() {
	return schoolSubjects.map(x => ({
		id: x.id,
		name: x.name,
		value: Math.random() * 12,
	}));
}

export function ParticipantsDispersion() {
	const [data, setData] = React.useState(generateData());

	function handleFilterChange() {
		setData(generateData());
	}

	return (
		<>
			<FilterToolbarSet onChange={handleFilterChange} filterSet={FilterSet.Set1} />
			<div className="participants-dispersion">
				<DispersionChart data={data} tooltipClassName="dispersion-chart-tooltip" />
			</div>
		</>
	);
}

import React from "react";

export function Pager() {
	return (
		<div className="pager">
			<div className="pager__button pager__button--active">1</div>
			<div className="pager__button">2</div>
			<div className="pager__button">3</div>
		</div>
	)
}
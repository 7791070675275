import React from "react";

export function Schedule() {
	return (
		<table className="international-schedule">
			<tbody>
				<tr>
					<th>Название олимпиады</th>
					<th>Даты проведения</th>
					<th>Место проведения</th>
					<th>Сайт</th>
				</tr>
				<tr>
					<td>Международная физическая олимпиада школьников (IPhO)</td>
					<td>7-15 июля</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/il/flat/32.png" />
							Тель-Авив, Израиль
						</div>
					</td>
					<td>
						<a href="http://ipho-new.org">ipho-new.org</a>
					</td>
				</tr>
				<tr>
					<td>Международная математическая олимпиада (IMO)</td>
					<td>10-22 июля</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/gb/flat/32.png" />
							Бат, Великобритания
						</div>
					</td>
					<td>
						<a href="http://imo2019.uk">imo2019.uk</a>
					</td>
				</tr>
				<tr>
					<td>Международная биологическая олимпиада (IBO)</td>
					<td>14-21 июля</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/hu/flat/32.png" />
							Сегед, Венгрия
						</div>
					</td>
					<td>
						<a href="http://ibo2019.org">ibo2019.org</a>
					</td>
				</tr>
				<tr>
					<td>Международная химическая олимпиада (IChO)</td>
					<td>21-30 июля</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/fr/flat/32.png" />
							Париж, Франция
						</div>
					</td>
					<td>
						<a href="http://icho2019.paris">icho2019.paris</a>
					</td>
				</tr>
				<tr>
					<td>Международная географическая олимпиада (iGeo)</td>
					<td>30 июля - 5 августа</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/ch/flat/32.png" />
							Гонконг, Китай
						</div>
					</td>
					<td>
						<a href="http://eduhk.hk/igeo2019">eduhk.hk/igeo2019</a>
					</td>
				</tr>
				<tr>
					<td>Международная олимпиада по астрономии и астрофизике (IOAA)</td>
					<td>2-10 августа</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/hu/flat/32.png" />
							Хевиз и Кестхей, Венгрия
						</div>
					</td>
					<td>
						<a href="http://ioaa2019.hu">ioaa2019.hu</a>
					</td>
				</tr>
				<tr>
					<td>Международная олимпиада по информатике (IOI)</td>
					<td>4-11 августа</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/az/flat/32.png" />
							Баку, Азербайджан
						</div>
					</td>
					<td>
						<a href="http://ioi2019.az">ioi2019.az</a>
					</td>
				</tr>
				<tr>
					<td>Международная естественно-научная олимпиада (IJSO)</td>
					<td>3-12 декабря</td>
					<td>
						<div className="international-schedule__place">
							<img src="https://www.countryflags.io/qa/flat/32.png" />
							Доха, Катар
						</div>
					</td>
					<td>
						<a href="http://ijso2019.edu.gov.qa">ijso2019.edu.gov.qa</a>
					</td>
				</tr>
			</tbody>
		</table>
	);
}

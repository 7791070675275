import React from "react";
import { render } from "react-dom";
import { Home } from "./pages/home";
import { News } from "./pages/news";
import { Exercises } from "./pages/exercises";
import { Documents } from "./pages/olympiads/documents";
import { Current } from "./pages/olympiads/current";
import { Past } from "./pages/olympiads/past";
import { AnalyticsInformation } from "./pages/analytics/information";
import { Faq } from "./pages/information/faq";
import { AnalyticsSummary } from "./pages/analytics/summary";
import { Contacts } from "./pages/information/contacts";
import { WebResources } from "./pages/information/web-resources";
import { Search } from "./pages/search";
import { AnalyticsParticipants } from "./pages/analytics/participants";
import { Login } from "./pages/login";
import { LoginPanel } from "@components/login-panel";
import { LoginService } from "./services/login-service";
import { InputPage } from "./pages/input/input-page";

const apps: Array<[string, React.ComponentClass | React.FunctionComponent]> = [
	["#interactive-slides", Home],
	[".news-content", News],
	[".exercises-content", Exercises],
	[".documents-content", Documents],
	[".olympiad-current-content", Current],
	[".olympiad-past-content", Past],
	[".analytics-information-content", AnalyticsInformation],
	[".analytics-summary-content", AnalyticsSummary],
	[".analytics-participants-content", AnalyticsParticipants],
	[".information-faq-content", Faq],
	[".information-contacts-content", Contacts],
	[".information-web-content", WebResources],
	[".search-content", Search],
	[".login-page__content", Login],
	[".login-panel", LoginPanel],
	[".input-data-page__content", InputPage],
];

const authorizeRequired = ["/analytics/information", "/analytics/summary", "/analytics/participants"];

if (!LoginService.isLoggedIn()) {
	if (authorizeRequired.includes(document.location.pathname)) {
		// @ts-ignore
		document.location = "/login";
	}
} else {
	document.getElementById("menu-item-analytics")!.style.display = "inline-block";
	document.getElementById("menu-item-input")!.style.display = "inline-block";
}

apps.forEach(([selector, Component]) => {
	if (document.querySelector(selector)) {
		render(<Component />, document.querySelector(selector));
	}
});
